import { ref } from 'vue'
import { dataMeasure, MeasureProps } from '@/views/Program/PMT/measure'
import { AutoTableColProps, AutoTableCellProps, APIDataProps } from '@/types'
import { guid } from '@/utils';
import { message } from "ant-design-vue"
import { useStore } from 'vuex'
import { OptGroupProps } from 'ant-design-vue/lib/vc-select/OptGroup';
export const useInsetMeasure = (getColums: Function, measureSelectData: MeasureProps[]) => {
    const store = useStore()
    const datas = ref<MeasureProps[]>([])
    datas.value = dataMeasure
    const getHasHL = (precondition: APIDataProps): number => {
        return precondition.tableCol.findIndex((item: AutoTableColProps) => item.id === 'Higher/Lower')
    }
    // 当列选择成H/L时对列头的处理
    const disposalHL = (precondition: APIDataProps) => {
        const indexHL: number = getHasHL(precondition);
        const colHL = precondition.tableCol.find((item: AutoTableColProps) => item.id === 'Higher/Lower');
        if (indexHL !== precondition.tableCol.length - 1 && colHL) {
            precondition.tableCol.splice(indexHL, 1);
            precondition.tableCol.push(colHL);
        }
    }
    const getCells = (id = '0', isEdit: boolean, precondition: APIDataProps) => {
        precondition.tableCell.map((item: AutoTableCellProps) => {
            item[id] = {
                isEdit: isEdit,
                val: ''
            }
            if (item.children && item.children.length > 0) {
                item.children.map((val: AutoTableCellProps) => {
                    val[id] = {
                        isEdit: false,
                        val: ''
                    }
                    return
                })
            }
            return item
        })
    }

    // 
    const getInsetTable = (col: AutoTableColProps, colAdd: AutoTableColProps, precondition: APIDataProps) => {
        precondition.tableCol.splice((precondition.tableCol.findIndex((item: AutoTableColProps) => item.id === col.id)) + 1, 0, colAdd)
        getCells(colAdd.id, true, precondition)
    }
    // 插入二级Measure
    const handleAddMeasureCol = (col: AutoTableColProps, index: number, precondition: APIDataProps) => {
        const idRep = index + 2 === 2 ? `Insurance renewal support amount in the ${index + 2}nd year` : (index + 2 === 3 ? `Insurance renewal support amount in the ${index + 2}rd year` : `Insurance renewal support amount in the ${index + 2}th year`)
        const colAdd = {
            display: idRep,
            group: col.group,
            id: idRep,
            option: [],
            selected: idRep,
            type: "M_DECIMAL"
        }
        getInsetTable(col, colAdd, precondition)
        getColums(precondition, false, {index: 0, width: '240px'})
    }

    // 插入Measure的小图标事件
    const handleInsetMeasure = (precondition: APIDataProps) => {
        const idGet = guid()
        const col = {
            id: 'Measure.Undefined.' + idGet,
            display: '(Select Measure)',
            type: 'EMBED_SELECT',
            group: 'Measure.Undefined.' + idGet,
            selected: '',
            option: dataMeasure
        };
        const indexHL: number = getHasHL(precondition)
        // 先检查tableCol有没有H/L
        // 有就往H/L前插入，没有就直接push
        if (indexHL !== -1) {
            precondition.tableCol.splice(indexHL, 0, col);
        } else {
            precondition.tableCol.push(col);
        }
        getColums(precondition, false, {index: 0, width: '240px'});
        getCells(col.id, dataMeasure[0].isEdit, precondition)
    }
    // 选择Measure处理不同的col
    const measureAddCol = (colMeasure: MeasureProps, colData: AutoTableColProps, precondition: APIDataProps) => {
        const getIndex = precondition.tableCol.findIndex((item: AutoTableColProps) => item.id === colData.id)
        if (colMeasure.children.length > 0) {
            colMeasure.children.forEach((item: MeasureProps) => {
                const col = {
                    id: item.id,
                    display: item.display,
                    type: item.type,
                    group: colData.group,
                    selected: item.id,
                    option: []
                };
                if (getIndex) {
                    precondition.tableCol.splice(getIndex + 1, 0, col);
                }
                getColums(precondition, false, {index: 0, width: '240px'});
                // 二级measure
                getCells(col.id, item.isEdit, precondition)
            })
        }
        getColums(precondition, false, {index: 0, width: '240px'});
    }
    // 删除表头和表格
    const delArr = (arrIndex: number[], arrGroupName: string[], precondition: APIDataProps) => {
        if (arrIndex.length > 0) {
            // 删除表头
            for (let i = 0; i < arrIndex.length; i++) {
                // 当删除一个索引时，数组的索引就会减少1
                precondition.tableCol.splice(arrIndex[i] - i, 1)
            }
        }
        if (arrGroupName.length > 0) {
            for (const key of arrGroupName) {
                precondition.tableCell.forEach((val: AutoTableCellProps) => {
                    delete val[key]
                })
            }
        }
    }
    // 当没有相同组的measure时，当一组Measure有多列时，在切换时，需要删掉除自己以外的列
    const delSameSecGroup = (colData: AutoTableColProps, precondition: APIDataProps) => {
        const arrIndex: number[] = [];
        const arrGroupName: string[] = [];
        precondition.tableCol.forEach((item: AutoTableColProps, index: number) => {
            if (item.group === colData.group) {
                arrGroupName.push(item.id)
            }
            if (item.type !== 'EMBED_SELECT' && item.group === colData.group) {
                arrIndex.push(index)
            }
        })
        delArr(arrIndex, arrGroupName, precondition)
    }
    // 当有相同组的measure时，我们需要把已有相同组的整个measure组删除掉，再插入一个新的Measure组
    const delSameGroup = (measureSelected: string, precondition: APIDataProps) => {
        const arr: number[] = [];
        const arrGroup: string[] = [];
        precondition.tableCol.forEach((item: AutoTableColProps, index: number) => {
            if (item.group === measureSelected) {
                arrGroup.push(item.id)
                arr.push(index)
            }
        })
        delArr(arr, arrGroup, precondition)
    }
    // 选择 Measure的事件,找到当前列，把 group赋给同一组的group
    const handleSelectMeasure = (colData: AutoTableColProps, measureSelected: string, precondition: APIDataProps) => {
        const colMeasure = measureSelectData.find((item: MeasureProps) => measureSelected === item.id)
        // 切换下拉时，要选择当前是否存在相同measure,而且也有当前组也有子级的时候，需要一起清掉
        if (precondition.tableCol.some((item: AutoTableColProps) => item.group === measureSelected)) {
            message.warning(`${measureSelected} is samed`)
            delSameGroup(measureSelected, precondition)
            // 当前组也有子级的时候，需要一起清掉
            // 如果当前组要清掉时，有子级，那么子级也需要清掉，
            if (colMeasure && colMeasure.children.length > 0 || precondition.tableCol.filter((item: AutoTableColProps) => colData.group === item.group).length > 1) {
                delSameSecGroup(colData, precondition)
            }

        } else {
            delSameSecGroup(colData, precondition) 
        }
        if (colMeasure) {
            colData.display = colMeasure.display
            colData.selected = measureSelected
            colData.id = measureSelected
            colData.group = measureSelected
            disposalHL(precondition)
            measureAddCol(colMeasure, colData, precondition)
            // 主Measure
            getCells(colData.group, colMeasure.isEdit, precondition)
        }
    }
    // 找到相同group的列
    const getSameCol = (group: string, precondition: APIDataProps) => {
        return precondition.tableCol.filter((item: AutoTableColProps) => item.group === group && item.id !== group)
    }
    // measure列自动计算,自动计算的列的id就是一组的group,所以就找就是同一个group的列去相加
    // 当前列的输入值+相同group列的值
    const handleChangeAutoCal = (col: AutoTableColProps, row: AutoTableCellProps, precondition: APIDataProps) => {
        if (!col.group) return
        // 找到相同group的列
        const getCols = getSameCol(col.group, precondition)
        let calCol = 0
        if (getCols.length > 0) {
            getCols.forEach((element: AutoTableColProps) => {
                calCol = calCol + Number(row[element.id].val)
            })
        }
        // 主列的值
        row[col.group].val = calCol === 0 ? null : calCol
    }
    const getFlag = (col: OptGroupProps, key: string): boolean => {
        if ((dataMeasure as any).find((items: MeasureProps) => col.id === items.id)) {
            return col.id && (dataMeasure as any).find((items: MeasureProps) => col.id === items.id).type === key
        } else {
            return false
        }
        
        // return true
    }
    return {
        handleChangeAutoCal,
        handleSelectMeasure,
        handleInsetMeasure,
        handleAddMeasureCol,
        getFlag
    }
}