
import { defineComponent } from 'vue';
import BasicInfo from './Eligibility/BasicInfo.vue';
import Period from './Eligibility/Period.vue';
import BudgetPeriod from './Eligibility/BudgetPeriod.vue';
import Dealer from './Eligibility/Dealer.vue';
import Vehicle from './Eligibility/Vehicle.vue';
import LocalReg from './Eligibility/LocalReg.vue';
import Quota from './Quota/Index.vue';
import Customer from './Customer/Index.vue';
import OfferGroup from './Overall/Index.vue';
export default defineComponent({
    components: {
        BasicInfo,
        Vehicle,
        Period,
        BudgetPeriod,
        Dealer,
        OfferGroup,
        LocalReg,
        Quota,
        Customer,
    },
});
