
import { defineComponent, ref, onMounted, PropType, h, watch } from 'vue';
import SelectTable from '@/views/Program/components/SelectTable.vue';
import ComSetting from '@/views/Program/components/ComSetting.vue';
import { AutoTableColProps, AutoTableCellProps, RowCellProps, APIDataProps } from '@/types';
import { expandProps } from '@/views/Budget/types';
import { useStore } from 'vuex';
import { message } from 'ant-design-vue';
import { pgmaddColumn, searchRepairCode } from '@/API/program';
import { handleChangePre, getCheckboxProps } from '@/utils';
import { ComSettingProps } from '../types';
import { dataMeasure, MeasureProps, HLOptions } from '../PMT/measure';
import { useInsetMeasure } from '@/hooks/Program/useInsetMeasure';
import { useInsetCol } from '@/hooks/Program/useInsetCol';
import { useEditGroupTitle } from '@/hooks/Program/useEditGroupTitle';
import deepClone from '@/utils/deepClone';
import { useTableOperation } from '@/hooks/Program/useTableOperation';
import { clearCellData } from '@/utils/pgm';
import { useTableAddRow } from '@/hooks/Program/useTableAddRow';
import { useTableDelRowCol } from '@/hooks/Program/useTableDelRowCol';
import { useAllCheck } from '@/hooks/Program/useAllCheck';
import type { ColumnsProps } from '@/types';

export default defineComponent({
  components: {
    SelectTable,
    ComSetting,
    VNodes: (_, { attrs }) => {
      return attrs.vnodes;
    }
  },
  props: {
    incentiveAmount: {
      default: () => {
        return {
          group: [],
          groupId: '',
          groupName: '',
          tableId: '',
          tableName: '',
          tableCol: [],
          tableCell: [],
          tableCellSelect: [],
          hideIncentiveAmount: false,
          communicationSetting: []
        };
      },
      type: Object as PropType<APIDataProps>
    },
    indexIncentive: {
      default: 0,
      type: Number
    },
    indexGroup: {
      default: 0,
      type: Number
    },
    offer: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const store = useStore();

    // 是否可以编辑incentiveTitle标题编辑标题
    const isEdit = ref(false);
    // incentiveTitle标题编辑标题
    const incentiveTitle = ref<string>('');

    // 下拉里面的全选，取消全选
    const { checked, currentChecked } = useAllCheck();
    // Amount列，
    const measureSelectData = ref<MeasureProps[]>([]);
    measureSelectData.value = dataMeasure;

    // 表格的表头
    const cols = ref<AutoTableColProps[]>([]);
    // 表格的列

    // 在wording中是否隐藏Amount列
    const hideChecked = ref(false);

    // 折叠当时组
    const isFlag = ref(false);
    const checkedShow = ref(false);

    const { selectVisible, handleInsetCol } = useInsetCol();
    const submitAdd = () => {
      pgmaddColumn(store.state.pgmInsetCol).then((res) => {
        const index = Number(sessionStorage.getItem('indexTable'));
        store.state.pgmData.offer[props.offer].incentive[props.indexGroup].groupTable[index] = res;
      });
    };

    // 自定义展开icon图标-----------------------展开折叠部分
    const clickExpand = (props: expandProps, e: Element) => {
      props.onExpand(props.record, e);
    };
    // 自定义展开icon
    const expandIcon = (props: expandProps) => {
      if (props.record.children && props.record.children.length > 0) {
        if (props.expanded) {
          //有数据-展开时候图标
          return h('i', {
            class: 'font_family icon-xiangxia m-l-5 cursor-p',
            onClick: (e: Element) => {
              clickExpand(props, e);
            }
          });
        } else {
          //有数据-未展开时候图标
          return h('i', {
            class: 'font_family icon-xiangyou m-l-5 cursor-p',
            onClick: (e: Element) => {
              clickExpand(props, e);
            }
          });
        }
      } else {
        //无数据-图标
        return h('i', {
          style: { height: '1px', display: 'inline-block' }
        });
      }
    };
    // select获得焦点时,先清空之前下拉框的值，再赋相关的值。col:当前列，cellSelect：当前组
    // 需要判断brand先选择

    // 下拉失去焦点
    const handleBlur = (): void => {
      checked.value = false;
    };

    const handleChangeRT = (rowCol: RowCellProps, col: string) => {
      const { incentiveAmount } = props;
      rowCol.val = rowCol.val === 'false' ? 'true' : 'false';
      if (
        col === 'Customized.retroactiveSet' &&
        incentiveAmount.tableCell.some((item: AutoTableCellProps) => item[col].val === 'false')
      ) {
        checkedShow.value = false;
      }
      if (
        col === 'Customized.retroactiveSet' &&
        incentiveAmount.tableCell.every((item: AutoTableCellProps) => item[col].val === 'true')
      ) {
        checkedShow.value = true;
      }
    };
    const { addNewRow, autoInsetRow } = useTableAddRow();
    const selectClear = (index: number, col: string, row: AutoTableCellProps) => {
      const { incentiveAmount } = props;
      if (row[col].val.length > 0) {
        row[col].isAuto = false;
      }
      if (row.parent === '0') {
        clearCellData(index, incentiveAmount.tableCol, row);
      }
    };

    // 得到所有的baumuster列
    const getBaumusterList = (val: string[], col: string, row: AutoTableCellProps) => {
      const { incentiveAmount } = props;
      const targeArr = incentiveAmount.tableCell.filter((item: AutoTableCellProps) => {
        for (let i = 0; i < val.length; i++) {
          if (item[col].val.indexOf(val[i]) !== -1 && item.id !== row.id) {
            return val[i];
          }
        }
      });
      return targeArr;
    };

    const openBranch = (index: number, col: string, row: AutoTableCellProps) => {
      const { incentiveAmount } = props;
      // 1。判断是否存在
      const targeArr = getBaumusterList(row[col].val, col, row);
      if (targeArr.length > 0) {
        message.warning(targeArr[0][col].val[0] + '已经存在');
        return;
      }
      // 2。拆分行
      const cloneArr = deepClone(row);
      if (row[col].val.length > 1) {
        // 拆成的行，要从第二行开始
        for (let i = 1; i < row[col].val.length; i++) {
          cloneArr.id = Math.random().toString(16).substring(2, 15);
          cloneArr.key = cloneArr.id;
          cloneArr[col].val = [row[col].val[i]];
          incentiveAmount.tableCell.splice(
            incentiveAmount.tableCell.findIndex((item: AutoTableCellProps) => item.id === row.id),
            0,
            cloneArr
          );
        }
        // 勾选的数组，第一行，就是第一个
        row[col].val = [row[col].val[0]];
      }
    };
    // 变成不可编辑
    // 2.take父级的值到子级
    const takeChildrenCol = (col: string, row: AutoTableCellProps) => {
      // 如果当前行是父级，1.下面的.children里面的当前列都需要改成不可编辑,2.并且子级全部和父级一样
      const fatherKeys = Object.keys(row);
      if (row.parent === '0' && row.children) {
        row.children.forEach((item: AutoTableCellProps) => {
          for (const i in item) {
            // 如果是对象的才能更新
            if (!fatherKeys.includes(i)) continue;
            if (item[i].constructor === Object && i !== 'ACT_0') {
              console.log(item[i], row[i], row, i);
              item[i].val = row[i]?.val || [];
              // exclude有值不能编辑，否值能编辑
              if (row[i].val.length > 0) {
                item[i].isEdit = false;
              } else {
                if (i.startsWith('Vehicle') || i.startsWith('Catalog')) {
                  item[i].isEdit = true;
                } else {
                  item[i].isEdit = false;
                }
              }
            }
          }
        });
      }
    };

    // 选择下拉后的值的改变:1.需要清空后面的值，2.如果是最后一行，里面有一个值改变了都需加一行
    const handleChangeSelect = (index: number, col: string, row: AutoTableCellProps) => {
      selectClear(index, col, row);
      takeChildrenCol(col, row);
      //3.
      autoInsetRow(props.incentiveAmount.tableCell, props.incentiveAmount.tableCol, row);
    };

    // 表格里列的多选
    const handleAllCheck = (index: number, col: string, row: AutoTableCellProps) => {
      const { incentiveAmount } = props;
      checked.value = !checked.value;
      if (checked.value) {
        row[col].val = incentiveAmount.tableCellSelect;
        selectClear(index, col, row);
      } else {
        row[col].val = [];
      }
      autoInsetRow(props.incentiveAmount.tableCell, props.incentiveAmount.tableCol, row);
    };

    const comVisible = ref(false);
    const comData = ref<ComSettingProps[]>([]);
    // 设置
    const handleComSetting = (incentive: APIDataProps) => {
      comVisible.value = true;
      comData.value = incentive.communicationSetting ? incentive.communicationSetting : [];
    };

    const { getColums, fetching, handleFocus, columns, showDelArr, showMeasureArr, showMeasureSecArr } =
      useTableOperation();

    // 删除列
    // 删除表格的行列
    const { selectedRowKeys, onSelectChange, deleteRow, handleDelCol } = useTableDelRowCol();

    // 显示隐藏Hide Incentive Amount
    const handleChangeHide = () => {
      const { incentiveAmount } = props;
      incentiveAmount.hideIncentiveAmount = hideChecked.value;
    };

    const handleFocusCode = (id: string, row: AutoTableCellProps, autoInsetRow?: Function) => {
      const { incentiveAmount } = props;
      // 1。加载loading
      fetching.value = true;
      incentiveAmount.tableCellSelect = [];
      const params = {
        incentiveTable: incentiveAmount.tableCell,
        precondition: store.state.pgmData.offer[props.offer].precondition,
        target: id
      };
      searchRepairCode(params).then((res) => {
        fetching.value = false;
        incentiveAmount.tableCellSelect = res;
      });
      if (autoInsetRow) autoInsetRow(incentiveAmount.tableCell, incentiveAmount.tableCol, row);
    };

    // Measure相关
    const { handleChangeAutoCal, handleSelectMeasure, handleInsetMeasure, handleAddMeasureCol, getFlag } =
      useInsetMeasure(getColums, measureSelectData.value);

    const handleChange = (row: AutoTableCellProps) => {
      autoInsetRow(props.incentiveAmount.tableCell, props.incentiveAmount.tableCol, row);
    };
    const handleChangeCheck = (val: any) => {
      const { incentiveAmount } = props;
      checkedShow.value = !checkedShow.value;
      incentiveAmount.tableCell.forEach((element: AutoTableCellProps) => {
        element[val].val = JSON.stringify(checkedShow.value);
      });
    };

    const handleChangeMeasureColTitle = (measureSec: ColumnsProps & { error: boolean }) => {
      if (!measureSec.slots?.col?.display) {
        measureSec.error = true;
        return;
      }
      const titles = showMeasureSecArr.value.reduce((res, item) => {
        if (item.dataIndex !== measureSec.dataIndex && item.slots?.col?.display) {
          res.add(item.slots.col.display);
        }
        return res;
      }, new Set<string>());
      if (titles.has(measureSec.slots.col.display)) {
        measureSec.error = true;
        message.error('value repeated');
      } else {
        measureSec.error = false;
      }
    };

    const init = () => {
      const { incentiveAmount } = props;
      // 表头
      cols.value = incentiveAmount.tableCol;
      hideChecked.value = incentiveAmount.hideIncentiveAmount ? incentiveAmount.hideIncentiveAmount : false;
      incentiveTitle.value = incentiveAmount.tableName ? incentiveAmount.tableName : '';
      getColums(incentiveAmount, false, { index: 0, width: '240px' });
      incentiveAmount.tableCell.push(addNewRow(incentiveAmount.tableCol, true));
    };
    const valateGroupTitle = () => {
      const { incentiveAmount } = props;
      isEdit.value = !isEdit.value;
      incentiveAmount.tableName = incentiveTitle.value;
    };
    watch(
      () => props.incentiveAmount.tableCol,
      (newCol, oldCol) => {
        const { incentiveAmount } = props;
        cols.value = props.incentiveAmount.tableCol;
        getColums(props.incentiveAmount, false, { index: 0, width: '240px' });
        if (incentiveAmount.tableCell.length > 0 && incentiveAmount.tableCol.length > 0) {
          const tablecell = incentiveAmount.tableCell[incentiveAmount.tableCell.length - 1];
          let flag = true;
          // debugger
          for (const key in tablecell) {
            if (key !== 'id' && flag) {
              if (!tablecell[key].val || tablecell[key].val.length !== 0) {
                flag = false;
              }
            } else {
              break;
            }
          }
          // if (newCol.length < oldCol.length) {
          //     flag = true
          // }
          if (!flag) incentiveAmount.tableCell.push(addNewRow(incentiveAmount.tableCol, true));
        }
        // incentiveAmount.tableCell.push(addNewRow(incentiveAmount.tableCol, true))
      }
    );
    onMounted(() => {
      init();
    });

    return {
      isEdit,
      incentiveTitle,
      valateGroupTitle,
      selectVisible,

      columns,
      measureSelectData,
      selectedRowKeys,
      onSelectChange,
      deleteRow,

      handleInsetCol,
      isFlag,
      getCheckboxProps,
      handleSelectMeasure,

      checked,
      handleFocus,
      handleChangeSelect,
      handleAllCheck,
      handleBlur,
      expandIcon,
      hideChecked,

      handleDelCol,
      showDelArr,
      showMeasureArr,
      showMeasureSecArr,

      fetching,
      handleChangePre,
      handleComSetting,
      comData,
      comVisible,
      handleChangeRT,
      handleChangeHide,

      handleInsetMeasure,
      handleChangeAutoCal,
      handleAddMeasureCol,
      handleChangeMeasureColTitle,
      submitAdd,

      HLOptions,
      handleChange,
      autoInsetRow,
      handleFocusCode,
      getFlag,
      handleChangeCheck,
      checkedShow
    };
  }
});
