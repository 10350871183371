
import { defineComponent, ref, reactive, UnwrapRef, onMounted, PropType, watch } from 'vue'
import deepClone from '@/utils/deepClone'
import { AutoTableColProps, APIDataProps, AutoTableCellProps, ColumnsProps, OptionProps, RowCellProps } from '@/types'
import { useStore } from 'vuex'
import { removeColumn } from "@/API/program"
import { guid, handleChangePre } from '@/utils/index'
import { searchOptions } from '@/API/program'
import useYearMonthDay from '@/hooks/Program/useYearMonthDay'
import { message } from "ant-design-vue"
import { useTableOperation } from '@/hooks/Program/useTableOperation'
import { useTableDelRowCol } from '@/hooks/Program/useTableDelRowCol'
// 最后一列
interface PreProps {
    groupId: string;
    groupName: string;
    tableCell: AutoTableCellProps[];
    tableCellSelect: OptionProps[];
    tableCol: AutoTableColProps[];
    tableId: string;
    tableName: string;
}
export default defineComponent({
    components: {
        VNodes: (_, { attrs }) => {
            return attrs.vnodes;
        },
    },
    props: {
        precondition: {
            default: () => {
                return {
                    groupId: '',
                    groupName: '',
                    tableId: '',
                    tableName: '',
                    tableCol: [],
                    tableCell: [],
                    tableCellSelect: [],
                }
            },
            type: Object as PropType<APIDataProps>,
        },
        indexPre: {
            default: 0,
            type: Number
        }
    },
    setup (props) {
        const store = useStore()
        // 是否可以编辑
        const isEdit = ref(false)
        
        const fetching = ref<boolean>(false)
        // incentiveTitle标题
        const incentiveTitle = ref('Name')
        incentiveTitle.value = props.precondition.tableName as string;
        // 下拉里面的全选，取消全选
        const checked = ref(false)
        const measure = ref()
        const measureSelectData = ref([])
        if ((props.precondition as PreProps).tableCol.length < 1) return;
        measureSelectData.value = (props.precondition as any).tableCol[(props.precondition as PreProps).tableCol.length - 1].option
        measure.value = (props.precondition as PreProps).tableCol[(props.precondition as PreProps).tableCol.length - 1].selected
        // 表格表头的渲染用格式
        const columns = ref<ColumnsProps[]>([])
        // 显示在表头的的删除按钮
        const showDelArr = ref<ColumnsProps[]>([])
        const { yearList, quarterList, monthList } = useYearMonthDay()
        // 表格数据, 不管数据有没有都需要添加一行空的
        const addNewRow = (textGroup?: string) => {
            const param: AutoTableCellProps = {
                id: guid(),
            };
            const getIndex: number = props.precondition.tableCell.length as number
            (props.precondition as PreProps).tableCol.forEach((item: AutoTableColProps, index: number) => {
                // 这里的id需要和key一致
                // 和表头一致的空数据行
                param[item.id] = { isAuto: false, isEdit: true, val: item.type === 'MULTI_SELECT' ? [] : (item.id === 'Quota.dealerVolumeByProgressiveGroup.quotaGroup' || item.id === 'Quota.vehicleVolumeByProgressiveGroup.quotaGroup' ? getIndex > 25 ? String.fromCharCode(65 + getIndex + 6) : String.fromCharCode(65 + getIndex) : '') }
                if (textGroup && index === 0) {
                    param[item.id] = textGroup
                }
            })
            return param
        }
        // 如果数组只有一行默认数据时，只要Attribute不是Retail date，或是from或是to不为空时，都需插入一行
        // 当数组有2个以后数据，并且，Attribute都没有空才加一行
        // 1。当数组有2个以后数据，并且，Attribute都没有空才加一行
        // 2。不管有没有数据都需要加入一行
        const autoInsetRow = (record?: AutoTableCellProps) => {
            // 当改变一行中的数据，只是它是最后一行，就要触发自动加一行
            if (record) {
                const getIndex = (props.precondition as PreProps).tableCell.findIndex((item: AutoTableCellProps) => item.id === record.id)
                if (getIndex === (props.precondition as PreProps).tableCell.length - 1) {
                    const cloneObj = deepClone(record)
                    cloneObj.id = guid()
                    for (const val of (props.precondition as PreProps).tableCol) {
                        if (val.type === 'MULTI_SELECT' || val.type === 'MULTI_SELECT_MYEX') {
                            cloneObj[val.id].val = []
                        } else {
                            cloneObj[val.id].val = ''
                        }
                        if (val.id === 'Quota.dealerVolumeByProgressiveGroup.quotaGroup' || val.id === 'Quota.vehicleVolumeByProgressiveGroup.quotaGroup') {
                            cloneObj[val.id].val = getIndex + 1 > 25 ? String.fromCharCode(65 + getIndex + 1 + 6) : String.fromCharCode(65 + getIndex + 1)
                        }
                    }
                    (props.precondition as PreProps).tableCell.push(cloneObj)
                    // (props.precondition as any).tableCell.push(addNewRow(record[(props.precondition as any).tableCol[0].id]))
                }
                
            // 不管有没有数据都需要加入一行
            } else {
                if ((props.precondition as PreProps).tableCell.length < 1) {
                    (props.precondition as PreProps).tableCell.push(addNewRow())
                }
            } 
        }
        // 表格里列的多选
        const handleAllCheck = (index: number, col: string, row: AutoTableCellProps) => {
            const { precondition } = props
            checked.value = !checked.value
            if (checked.value) {
                row[col].val = precondition.tableCellSelect
            } else {
                row[col].val = []
            }
            autoInsetRow(row)
        }
         // 下拉失去焦点
        const handleBlur = (): void => {
            checked.value = false
        }
        // 得到colums
        const getColums = () => {
            columns.value = []
            showDelArr.value = []
            // Property 'tableCol' does not exist on type 'never[]':断言成any
            if ((props.precondition as PreProps).tableCol.length < 1) {
                return
            }
            
            (props.precondition as PreProps).tableCol.forEach((item: AutoTableColProps, index: number) => {
                let params: ColumnsProps = {
                    title: item.display,
                    dataIndex: item.id,
                    width: '200px',
                    slots: { customRender: item.id },
                }
                if (index === (props.precondition as PreProps).tableCol.length - 1) {
                    params = {
                        title: item.display,
                        dataIndex: item.id,
                        slots: { customRender: item.id },
                    }
                }
                
                if (item.deletable) {
                    params = {
                        // title: item.display,
                        dataIndex: item.id,
                        width: '200px',
                        // slots里面的title需要唯一，上面模板里的才能渲染不
                        slots: { customRender: item.id, title: 'customDel' + item.id, display: item.display },
                    }
                    showDelArr.value.push(params)
                }
                if (item.id === 'Quota.vehicleVolumeByProgressiveGroup.quotaGroup' || item.id === 'Quota.dealerVolumeByProgressiveGroup.quotaGroup') {
                    params.width = '140px'
                }
                columns.value.push(params)
            
            })  
        }
        
        
        // // 选择了的行
        // const selectedRowKeys = ref<string[]>([])
        // const onSelectChange = (selected: string[]) => {
        //     selectedRowKeys.value = selected
        // }
       
        // 删除列
        const handleDelCol = (item: ColumnsProps, group: APIDataProps) => {
            const params = {
                columnId: item.dataIndex,
                table: group
            }
            removeColumn(params).then(res => {
                store.state.pgmData.quota[props.indexPre] = res
            })
        }
        // 多行删除
        const { deleteRow, selectedRowKeys, onSelectChange } = useTableDelRowCol()
        const isFlag = ref(false)
        const handleSelectMeasure = () => {
            (props.precondition as PreProps).tableCol[(props.precondition as PreProps).tableCol.length - 1].selected = measure.value
        }
        
        // 获取焦点
        // 查询季度
        const findQuar = (): AutoTableColProps => {
            return (props.precondition as any).tableCol.find((item: AutoTableColProps) => item.display === 'Quarter period')
        }
        const getAllCustomer = () => {
            const arr: string[] = [];
            store.state.pgmData.customer.forEach((item: APIDataProps) => {
                    for (const val of item.tableCell) {
                        if (val['Customer.type'].val) {
                            arr.push(val['Customer.type'].val)
                        }
                    }
            })
            return arr
        }
        const handleFocus = (col: AutoTableColProps, row: AutoTableCellProps) => {
            
            autoInsetRow(row)
            // 1。加载loading
            fetching.value = true;
            if (col.id === 'Quota.vehicleVolumeByCustomerSet.customerType') {
                (props.precondition as any).tableCellSelect = getAllCustomer()
            } else {
                if (col.display === 'Month' && (!row[findQuar().id].val)) {
                    (props.precondition as any).tableCellSelect = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
                } else {
                    (props.precondition as any).tableCellSelect = monthList.value[row[findQuar().id].val]
                }
                if (col.display === 'Year') {
                    (props.precondition as any).tableCellSelect = yearList.value
                } else if (col.display === 'Quarter period') {
                    (props.precondition as any).tableCellSelect = quarterList
                }
            }
            
            fetching.value = false;
            autoInsetRow()
        }

        
        const { 
                handleFocus: handleFocusMulti,
                } = useTableOperation()
        watch(() => (props.precondition as PreProps).tableCol, () => {
            getColums()
            autoInsetRow()
        })
        onMounted(() => {
            getColums()
            autoInsetRow()
        })
        
        return {
            isFlag,
            columns,
            measureSelectData,
            measure,
            selectedRowKeys,
            onSelectChange,
            handleSelectMeasure,
            showDelArr,
            handleDelCol,
            deleteRow,
            isEdit,
            incentiveTitle,
            handleFocus,
            fetching,
            handleChangePre,
            autoInsetRow,
            handleFocusMulti,
            handleBlur,
            checked,
            handleAllCheck
        }
    }
})
