
import { defineComponent, onMounted, PropType, watch } from 'vue'
import { APIDataProps, AutoTableCellProps, AutoTableColProps } from '@/types'
import { handleChangePre } from '@/utils/index'

import { HLOptions } from '../PMT/measure'


import { useTableDelRowCol } from '@/hooks/Program/useTableDelRowCol'
import { useTableAddRow } from '@/hooks/Program/useTableAddRow'
import { useTableOperation } from '@/hooks/Program/useTableOperation'
import useEventBus from '@/utils/mitt'
import { useStore } from 'vuex'
import ExpandedList from './ExpandedList.vue'
import { guid } from '@/utils/index'
import deepClone from '@/utils/deepClone'
export default defineComponent({
    components: {
        ExpandedList
    },
    props: {
        precondition: {
            default: () => {
                return {
                    group: [],
                    groupId: '',
                    groupName: '',
                    tableId: '',
                    tableName: '',
                    tableCol: [],
                    tableCell: [],
                    tableCellSelect: []
                }
            },
            type: Object as PropType<APIDataProps>,
        }
    },
    setup (props) {
        const store = useStore()

        const { addNewRow } = useTableAddRow()

        const clearCell = (cloneObj: AutoTableCellProps, precondition: APIDataProps) => {
            precondition.tableCol.forEach((item: AutoTableColProps, index: number) => {
                // 和表头一致的空数据行
                cloneObj[item.id] = { isAuto: false, isEdit: true, val: item.type === 'MULTI_SELECT' || item.type === 'MULTI_SELECT_MYEX' ? [] : '' }
            })
        }
        const autoInsetRow = (precondition: APIDataProps, record?: AutoTableCellProps) => {
            // 当改变一行中的数据，只是它是最后一行，就要触发自动加一行
            if (record) {
                const getIndex = precondition.tableCell.findIndex((item: AutoTableCellProps) => item.id === record.id)
                if (getIndex === precondition.tableCell.length - 1) {
                    const cloneObj = deepClone(record)
                    delete cloneObj.vehicle
                    cloneObj.id = guid()
                    clearCell(cloneObj, precondition)
                    precondition.tableCell.push(cloneObj)
                }

            // 不管有没有数据都需要加入一行
            } else {
                if (precondition.tableCell.length < 1) {
                    precondition.tableCell.push(addNewRow(precondition.tableCol))
                }
            }
        }
        // 改变加入一行
        const handleFocusT = (row: AutoTableCellProps) => {
            autoInsetRow(props.precondition, row)
        }

        // 删除表格的行列
        const { selectedRowKeys, onSelectChange, deleteRow, handleDelCol } = useTableDelRowCol()
        // 表格的操作，生成表头，格子等
        const { 
                getColums,
                fetching,
                columns,
                } = useTableOperation()
        // 给下拉赋值
        const handleFocus = (col: string, record: AutoTableCellProps, precondition: APIDataProps) => {
             (precondition.tableCellSelect as any) = store.state.customerSelected
             autoInsetRow(props.precondition, record)
        }
        
        // 选择之后，需要把二级给清掉，
        const handleChange = (id: string, row: AutoTableCellProps, precondition: APIDataProps) => {
            delete row.vehicle
            
            if (precondition.tableCellSelect && precondition.tableCellSelect.length > 0) {
                for (const key of precondition.tableCellSelect as any) {
                    if (key.name === row['Customer.type'].val) {
                        row['Customer.standardContent'].val = key.description.defaultDescription
                        break
                    }
                }
                for (const key of (precondition.tableCellSelect as any)) {
                    if (key.name === row[id].val && key.requireTradeIn) {
                        row.vehicle = {
                            groupId: key.id,
                            groupName: key.name,
                            group: [],
                            tableId: key.id,
                            tableName: 'Pre-owned vehicle setting',
                            tableCol: store.state.pgmData.eligibility.vehicle[0].tableCol,
                            tableCell: []
                        }
                        if (key.id === 'Customer.tradeInWithinMBBrands')
                        row.vehicle.tableRemark = [{
                            title: 'Pre-owned vehicle hold period',
                            content: 'Owned by the previous owner over 90 days: retail invoice date of new MB vehicle minus registration date of pre-owned MB vehicle.',
                            isEdit: true
                        }]
                        break
                    }
                }
            }
            
            
        }
        // @params, hasVehicle是否有配车， row,是当前customer父级行
        // 根据子表，返回有没有配车来决定显示Customer.type下拉结构里面的description的哪个值
        const updateStandardContent = (hasVehicle: boolean, row: AutoTableCellProps) => {
           const des = store.state.customerSelected.filter((item: any) => item.name === row['Customer.type'].val)[0].description
           if (hasVehicle) {
                row['Customer.standardContent'].val = des.customVehicleDescription
            } else {
                row['Customer.standardContent'].val = des.defaultDescription
            }
        }
        watch(() => props.precondition.tableCol, () => {
            getColums(props.precondition)
            autoInsetRow(props.precondition)
        })

        onMounted(() => {
            getColums(props.precondition)
            // 在第一行加入number
            columns.value.unshift({ title: 'Number', dataIndex: '', key: '', width: '100px',  slots: { customRender: 'numberrow' } })
            autoInsetRow(props.precondition)
        })

        return {
            columns,
            selectedRowKeys,
            onSelectChange,

            handleDelCol,

            deleteRow,
            handleFocusT,
            handleFocus,
            fetching,
            handleChangePre,
            HLOptions,
            handleChange,
            updateStandardContent
        }
    }
})
