<template> 
  <div>
    <div ref="ssDesigner" style="height:500px;width:100%;text-align: left;"></div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref} from "vue";
import "../../node_modules/@grapecity/spread-sheets/styles/gc.spread.sheets.excel2013white.css";
import "../../node_modules/@grapecity/spread-sheets-designer/styles/gc.spread.sheets.designer.min.css";
import "@grapecity/spread-sheets-designer-resources-cn";
import * as DesignerGC from "@grapecity/spread-sheets-designer";
import GC from "@grapecity/spread-sheets";
// import * as ExcelIO from "@grapecity/spread-excelio";
GC.Spread.Sheets.Designer.LicenseKey = 'oasis.mercedes-benz.com.cn|oasis-training.mercedes-benz.com.cn,495829726169355#B0wTK6g7N7lEe0NTezU6Sud7KUx6QEpHNaZEcFdjWE3iYCp6U8REOroEM946KnpmaqdDZ956MJ94cZREb8QlcRN5K4x6c6JXbrVmQ0NWaGN6TTtWTSBlbohDRrd7MIlWa8l6R6cnNMRja9gXYC9kVGpldBF6NqdjRWN6aHFla9IlW8YEZw4GTtpnRw8WcyA7UsxWQ5NFdxUHM4IkZYF4UxVVVN3iVD3CUIl5RFBlTDVEeBx6R6FjZ8h4RCljU44UbzEkQNhDaKZUNxIVNBZldsZlN4llT7kmS7hUO6MXR4sWMS94Tht6coxUa7A5KpZEaP3mQMlkemZXbHNEaURmMklFayR5ULJnZYlWdBNGSiojITJCLiMTQxIUN7QDNiojIIJCLwUTNxgTO5ADN0IicfJye&Qf35VfiklNFdjI0IyQiwiI4EjL6BibvRGZB5icl96ZpNXZE5yUKRWYlJHcTJiOi8kI1tlOiQmcQJCLiITM5AjNwACMyATMxIDMyIiOiQncDJCLi86Yu46bj9ieuVmYtMXZkV6YyVWbucmbp9WahJHdtMXazF6bs86Yu46bj9ieuVmYtMXZkV6YyVWbuMXazF6biojIz5GRiwiI8+Y9sWY9QmZ0Jyp9EWL0Vqo90yY9O6Y9tiL9nSa9SuY9Gea94io9iojIh94QiwiI5UzM9YTM6IzN9IDO5kDNiojIklkI1pjIEJCLi4TP79kThRnewdHbrVlYiRFWQF4bFBFVNlXQ6UjTmlkZ5IFTx4EeWdUNuJnMlVXWhVXOOtib6d4ZoZ6cQdlcKlEaWZXTsZ4LFsHn5';
GC.Spread.Sheets.LicenseKey = 'oasis.mercedes-benz.com.cn|oasis-training.mercedes-benz.com.cn|oasis-uat.mercedes-benz.com.cn|oasis-dev.mercedes-benz.com.cn,858593977834555#B0j3vnNr3SYhRHVj5kbqtkWXRDMW3SNqFFON9GaMJ7YaV7QjVkZENjR7kXZ6RHeBpEN58WT6QUY42WeJ36ZEt4azlnZ9kXR624VtZzcrAlRTJ4LudWSMlDVul5KG3CeNNXOEZVVwInV6I6LUVTO486cwRldIdDepBHNYJ6cDJWbT36L0RXT6BFT7cTWHBHeQNncygXT59UTmh7bLdmNRtiY6RmTHRlciNnZ9ljQGtmSvUXS5J4KyUHMYRUNRRnU5lkYjN5ThJnMYZ5ayN4dMlFVtR6TmJXY5UUUrsyc9M4N9ZjNMplcxllMtRTTxxkeSZTbw3mMn5WW054LRdFelNFZvcHelNkTHFWUTV7VyUjR7hjMOJVaJdXO5plZyI6T5ZDcIVnc6NnZ55kbwFzV9cERiojITJCLiYUMGFTQDZUNiojIIJCLxIDN8ETMyETN0IicfJye&Qf35VfiUURJZlI0IyQiwiI4EjL6BCITpEIkFWZyB7UiojIOJyebpjIkJHUiwiIzATNwkDMgYDM4ATMyAjMiojI4J7QiwiIuNmLt36YuonblJWLzVGZlNmcl5mL6VGZtMXazF6bs86Yu46bj9ieuVmYtMXZkV6YyVWbuQXY55ycpNXYvxibj9SbvNmL09WZi5yclRWZjJXZt9yZulmbpFmc45ycpNXYvxibj9SbvNmL09WZi5yclRWZjJXZt9ycpNXYvJiOiMXbEJCLig1jlzahlDZmpnInmTYtoXpimrLjl1Yjl7KukfKplL9ilb8plTLimLiOiEmTDJCLiUTN5QzM8czN9MTO5gTN8IiOiQWSiwSflNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRR5QoFHTzgDdslEZiB5dDJGarFkR5QmNNFFcvBnYLRkWXBlfzCX';
export default defineComponent({
  name: 'SpreadSheets',
  props: {
  },
  setup(props, {emit}) {
    const ssDesigner = ref(null);
    onMounted(() => {
      const designer = new GC.Spread.Sheets.Designer.Designer(ssDesigner.value);
      emit("designerinitialized", designer);
    });
    return {
      ssDesigner
    };
  }
})
</script>