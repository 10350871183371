
import { AutoTableColProps, APIDataProps, AutoTableCellProps, ColumnsProps, OptionProps, RowCellProps } from '@/types'
import { guid } from '@/utils/index'
import deepClone from '@/utils/deepClone'
// import { useStore } from 'vuex'
export const useTableAddRow = () => {
    // const store = useStore()
    // 表格数据, 不管数据有没有都需要添加一行空的
    const addNewRow = (tableCol: AutoTableColProps[], hasParent?: boolean) => {
        const param: AutoTableCellProps = {
            id: guid(),
        };
        tableCol.forEach((item: AutoTableColProps, index: number) => {
            // 和表头一致的空数据行
            param[item.id] = { isAuto: false, isEdit: true, val: item.type === 'MULTI_SELECT' || item.type === 'MULTI_SELECT_MYEX' ? [] : '' }
            if (hasParent) {
                param.parent = '0';
            }
        })
        return param
    }

    // 如果数组只有一行默认数据时，只要Attribute不是Retail date，或是from或是to不为空时，都需插入一行
    // 当数组有2个以后数据，并且，Attribute都没有空才加一行
    // 1。当数组有2个以后数据，并且，Attribute都没有空才加一行
    // 2。不管有没有数据都需要加入一行
    const autoInsetRow = (tableCell: AutoTableCellProps[], tableCol: AutoTableColProps[], record?: AutoTableCellProps) => {
        // 当改变一行中的数据，只是它是最后一行，就要触发自动加一行
        if (record) {
            const getIndex = tableCell.findIndex((item: AutoTableCellProps) => item.id === record.id)
            if (getIndex === tableCell.length - 1) {
                const cloneObj = deepClone(record)
                cloneObj.id = guid()
                tableCell.push(cloneObj)
            }

        // 不管有没有数据都需要加入一行
        } else {
            tableCell.push(addNewRow(tableCol))
        }
    }
    return {
        addNewRow,
        autoInsetRow
    }
}