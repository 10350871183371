
import { defineComponent, ref, PropType, watch } from 'vue';
import SelectTable from '@/views/Program/components/SelectTable.vue';
import { CategoryProps } from '@/types';
import PreconditionVehicleList from './PreconditionVehicleList.vue';
import { APIDataProps, ColumnsProps, APIDataEditProps, OptionProps } from '@/types';
import { useStore } from 'vuex';
import { getGroup } from '@/utils/index';
import { pgmaddColumn } from '@/API/program';
import { message } from 'ant-design-vue';
import { useInsetCol } from '@/hooks/Program/useInsetCol';
import { guid } from '@/utils';
import deepClone from '@/utils/deepClone';
import { useEditGroupTitle } from '@/hooks/Program/useEditGroupTitle';
// 最后一列
export default defineComponent({
  components: {
    SelectTable,
    PreconditionVehicleList
  },
  props: {
    precondition: {
      type: Array as PropType<APIDataEditProps[]>,
      default: () => []
    },
    offer: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const store = useStore();
    const isFlag = ref(false);
    // 是否可以编辑
    // 表格列的定义
    const columns = ref<ColumnsProps[]>([]);

    const { valateGroupTitle } = useEditGroupTitle();

    // 新增一组:1.首先需要拿到vehicle，有多少组。
    const handleAddGroup = (): void => {
      const { precondition } = props;
      const gid = guid();
      const param: APIDataProps = {
        // 把已有的vehicle拿下来
        group: [...getGroup()],
        groupId: gid,
        groupName: 'G1',
        tableId: gid,
        tableName: '',
        tableCol: [],
        tableCell: [],
        tableCellSelect: []
      };
      precondition.push(param);
    };

    const { selectVisible, handleInsetCol } = useInsetCol();
    const submitAdd = () => {
      pgmaddColumn(store.state.pgmInsetCol).then((res) => {
        const index = Number(sessionStorage.getItem('indexTable'));
        store.state.pgmData.offer[props.offer].precondition[index] = res;
      });
    };
    watch(
      () => store.state.pgmGroup,
      () => {
        const { precondition } = props;
        for (const item of precondition) {
          if (item.group) {
            const tempGroup = [];
            const selectedGroup = item.group.filter((group: any) => group.selected).map((group: any) => group.id);
            for (let i = 0; i < store.state.pgmGroup.length; i++) {
              const obj = store.state.pgmGroup[i];
              const clonedObj = deepClone(obj);
              if (selectedGroup.includes(clonedObj.id)) {
                clonedObj.selected = true;
              }
              tempGroup.push(clonedObj);
            }
            item.group.length = 0;
            item.group.push(...tempGroup);
          }
        }
      }
    );

    const handleDelGroup = (index: number) => {
      const { precondition } = props;
      precondition.splice(index, 1);
    };

    return {
      valateGroupTitle,
      handleInsetCol,
      selectVisible,
      isFlag,
      columns,
      handleAddGroup,
      submitAdd,
      handleDelGroup
    };
  }
});
