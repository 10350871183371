
import { defineComponent, onMounted, ref, PropType } from 'vue'
import SelectTable from '@/views/Program/components/SelectTable.vue'
import RankingList from './RankingList.vue'
import { APIDataProps } from '@/types'
import { useStore } from 'vuex'
import { pgmaddColumn } from "@/API/program"
import { useInsetCol } from '@/hooks/Program/useInsetCol'
import { guid } from '@/utils';
// 最后一列
export default defineComponent({
    components: { 
        SelectTable, 
        RankingList 
    },
    props: {
        ranking: {
            type: Array as PropType<APIDataProps[]>,
            default: () => {
                return []
            }
        },
        offer: {
            type: String,
            default: ''
        }
    },
    setup (props) {
        const store = useStore()
        // 新增一组:1.首先需要拿到vehicle，有多少组。
        const handleAddGroup = (): void => {
            const gid = guid()
            const param: APIDataProps = {
                // 把已有的vehicle拿下来
                group: [],
                groupId: gid,
                groupName: 'Ranking',
                tableId: gid,
                tableName: '',
                tableCol: [],
                tableCell: [],
                tableCellSelect: []
            };
            (props.ranking as any).push(param);
            
        }
        const { selectVisible, handleInsetCol } = useInsetCol()
        const submitAdd = () => {
            pgmaddColumn(store.state.pgmInsetCol).then(res => {
                const index = Number(sessionStorage.getItem('indexTable'))
                store.state.pgmData.offer[props.offer].ranking[index] = res
            })
        }

        const isFlag = ref(false)
        onMounted(() => {
            if (props.ranking.length < 1) {
                handleAddGroup()
            }
            
        })
        return {
            handleInsetCol,
            selectVisible,
            isFlag,
            handleAddGroup,
            submitAdd
        }
    }
})
