
import { defineComponent, ref, onMounted, PropType, watch } from 'vue'
import { APIDataProps, AutoTableCellProps, RowCellProps, ColumnsProps, AutoTableColProps, OptionProps } from '@/types'
import { handleChangePre } from '@/utils/index'



import { useTableDelRowCol } from '@/hooks/Program/useTableDelRowCol'
import { useTableAddRow } from '@/hooks/Program/useTableAddRow'
import { useTableOperation } from '@/hooks/Program/useTableOperation'
import { useStore } from 'vuex'
import useEventbus from "@/hooks/claim/useEventbus"
import { removeColumn, searchRankingCriteria } from '@/API/program'
interface SelectProps {
    [key: string]: string;
}
export default defineComponent({
    props: {
        precondition: {
            default: () => {
                return {
                    group: [],
                    groupId: '',
                    groupName: '',
                    tableId: '',
                    tableName: '',
                    tableCol: [],
                    tableCell: [],
                    tableCellSelect: [],
                }
            },
            type: Object as PropType<APIDataProps>,
        },
        offer: {
            type: String,
            default: ''
        },
        indexPre: {
            type: Number,
            default: 0
        }
    },
    setup (props) {
        const store = useStore()
        const { customEmit } = useEventbus()
        const options = ref<{value: string}[]>()

        const orders = ref()
        orders.value = ['Descending', 'Ascending']
        const { autoInsetRow } = useTableAddRow()

        // 删除表格的行列
        const { selectedRowKeys, onSelectChange, deleteRow } = useTableDelRowCol()
        const handleDelCol = (item: ColumnsProps, group: APIDataProps) => {
            const params = {
                columnId: item.dataIndex,
                table: group
            }
            removeColumn(params).then(res => {
                store.state.pgmData.offer[props.offer].ranking[props.indexPre] = res
            })
        }
        // 表格的操作，生成表头，格子等
        const { 
                getColums,
                fetching,
                // handleFocus,
                columns,
                showDelArr,
                showMeasureArr,
                showMeasureSecArr
                } = useTableOperation()
        const handleFocus = (id: string, row: AutoTableCellProps, precondition: APIDataProps, auto?: boolean) => {
            // 1。加载loading
            fetching.value = true;
            precondition.tableCellSelect = []
            customEmit("updateperiod")
            const params = {
                precondition: store.state.pgmData.offer[props.offer].precondition,
                row: row,
                target: id
            }
            searchRankingCriteria(params).then(res => {
                fetching.value = false;
                (precondition.tableCellSelect as any) = res
                if (auto) {
                    const arr = []
                    for (const item of res) {
                        for (const key in item) {
                            if (key !== id) {
                                arr.push({
                                    value: item[key]
                                })
                                break
                            }
                        }
                    }
                    options.value = arr
                }
            })
        }
        // 当改变时触发
        const handleRankingSource = (value: string, id: string, row: AutoTableCellProps, precondition: APIDataProps) => {
            // handleSearch时失去焦点输入的值就为空，所以需要判断，为空时，不重新赋值
            if (value !== null && value !== '') {
                row[id].val = value
            }
            // 如果输入的值在Ranking Source下拉中找不到，需要把Ranking Value的值清掉
            if ((precondition.tableCellSelect as any).findIndex((item: any) => item[id] === row[id].val) !== -1) {
                if (precondition.tableCellSelect && precondition.tableCellSelect.length > 0) {
                    for (const item of precondition.tableCellSelect as any) {
                        if (row[id].val === item[id]) {
                            // Ranking.Object.1: "222"
                            // Ranking.Object_property.1: "Achievement volume"
                            // 找同一条数据，不是id的key
                            for (const key in item) {
                                if (key !== id) {
                                    row[key].val = item[key]
                                    break
                                }
                            }
                        }
                    }
                }
            } else {
                if (precondition.tableCellSelect && precondition.tableCellSelect.length > 0) {
                    for (const item of precondition.tableCellSelect as any) {
                        for (const key in item) {
                            if (key !== id) {
                                row[key].val = ''
                                break
                            }
                        }
                    }
                }
            }
        }
        // 需要下拉框也可以输入，改造成search
        const handleSearch = (value: string, id: string, row: AutoTableCellProps, precondition: APIDataProps) => {
            handleRankingSource(value, id, row, precondition)
        }
        // Measure相关
        watch(() => props.precondition.tableCol, () => {
            getColums(props.precondition)
            if (props.precondition.tableCell.length < 1) {
                autoInsetRow(props.precondition.tableCell, props.precondition.tableCol)
            }
        })

        onMounted(() => {
            getColums(props.precondition)
            if (props.precondition.tableCell.length < 1) {
                autoInsetRow(props.precondition.tableCell, props.precondition.tableCol)
            }
        })

        return {
            columns,
            selectedRowKeys,
            onSelectChange,

            showDelArr,
            handleDelCol,

            deleteRow,
            handleFocus,
            fetching,
            handleChangePre,
            options,
            orders,
            handleRankingSource,
            handleSearch
        }
    }
})
