
    import { defineComponent, onMounted, ref }  from 'vue'
    import { APIDataProps, APIDataEditProps, AutoTableColProps } from '@/types'
    import CustomerList from './CustomerList.vue'
    // import GC from '@grapecity/spread-sheets'
    import { useStore } from 'vuex'
    import { guid} from '@/utils'
    import { pgmaddColumn } from "@/API/program"
    import { message } from "ant-design-vue"
    import useEventbus from "@/hooks/claim/useEventbus"
    import { useEditGroupTitle } from '@/hooks/Program/useEditGroupTitle'
    export default defineComponent({
        components: {
            CustomerList,
        },
        setup () {
            const store = useStore()
            
            const customerData = ref()
            
            const tableCol = [
                {
                    id: 'Customer.type',
                    display: 'Customer Type',
                    type: 'SINGLE_SELECT',
                },
                {
                    id: 'Customer.standardContent',
                    display: 'Standard Content',
                    type: 'TEXT',
                },
                // {
                //     id: 'Customer.list',
                //     display: 'Customer list',
                //     type: 'MULTI_SELECT',
                // },
                {
                    id: 'Customer.list',
                    display: 'Customer List',
                    type: 'TEXT',
                }
            ]
            // 新增一组:1.首先需要拿到vehicle，有多少组。
            const handleAddGroup = (): void => {
                const param: APIDataProps = {
                    // 把已有的vehicle拿下来
                    group: [],
                    groupId: guid(),
                    groupName: `CU${customerData.value.length + 1}`,
                    tableId: guid(),
                    tableName: '',
                    tableCol: tableCol,
                    tableCell: [],
                    tableCellSelect: []
                }
                // store.state.pgmData.offerType.overall.precondition.push(param)
                customerData.value.push(param)
                // 更新 bu 和 offer的参数
                store.dispatch('updateCustomerSelected')
            }
            const { valateGroupTitle } = useEditGroupTitle()
            const { customOn } = useEventbus()
        
            // 父级监听子级更新数据到vuex
            customOn("updatecustomer", () => {
                store.state.pgmData.customer = customerData.value
            })
            onMounted(() => {
                customerData.value = store.state.pgmData.customer
            })
            return {
                handleAddGroup,
                customerData,
                valateGroupTitle,
            }
        }
    
    })
