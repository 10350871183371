
import { defineComponent, ref, reactive, UnwrapRef, onMounted, PropType, h, watch } from 'vue'
import SelectTable from '@/views/Program/components/SelectTable.vue'
import ComSetting from '@/views/Program/components/ComSetting.vue'
import { AutoTableColProps, AutoTableCellProps, RowCellProps, APIDataProps, OptionProps } from '@/types'
import { expandProps } from "@/views/Budget/types"
import deepClone from '@/utils/deepClone'
import { useStore } from 'vuex'
import { tableHeader } from '@/views/Budget/data'
import { message } from "ant-design-vue"
import { fnChildren } from '@/utils/index'
import { removeColumn, searchCandidate, searchSsscCandidate, searchVehicle } from '@/API/program'
import { ComSettingProps } from "../Program/types";
import { useTableDelRowCol } from '@/hooks/Program/useTableDelRowCol'
import { useTableOperation } from '@/hooks/Program/useTableOperation'
import { dataMeasure, MeasureProps, HLOptions } from '@/views/Program/PMT/measure'
import { guid } from '@/utils';
import { searchOptions } from '@/API/program'
interface ColumnsProps {
    title?: string;
    dataIndex: string;
    width?: string;
    slots?: {};
}
interface PreProps {
    group: OptionProps[];
    groupId: string;
    groupName: string;
    tableCell: AutoTableCellProps[];
    tableCellSelect: OptionProps[];
    tableCol: AutoTableColProps[];
    tableId: string;
    tableName: string;
    communicationSetting?: ComSettingProps[];
    
}
export default defineComponent({
    components: {
        SelectTable,
        ComSetting,
    },
    props: {
        incentiveAmount: {
            default: () => {
                return {
                    group: [],
                    groupId: '',
                    groupName: '',
                    tableId: '',
                    tableName: '',
                    tableCol: [],
                    tableCell: [],
                    tableCellSelect: []
                }
            },
            type: Object as PropType<APIDataProps>,
        },
    },
    setup (props) {
        const store = useStore()
        // 是否可以编辑
        const isEdit = ref(false)
        // incentiveTitle标题
        const incentiveTitle = ref<string>('Name')
        incentiveTitle.value = props.incentiveAmount.tableName as string
        // select table弹窗是否显示
        const selectVisible = ref(false)
        // 下拉里面的全选，取消全选
        const checked = ref(false)
        const measureSelectData = ref<MeasureProps[]>([])
        measureSelectData.value = dataMeasure

        const tableCellSelectData = ref<string[]>([])
        


        const { 
                getColums,
                fetching,
                columns,
                showDelArr,
                showMeasureArr,
                showMeasureSecArr
                } = useTableOperation()
        // 自定义展开icon图标-----------------------展开折叠部分
        const clickExpand = (props: expandProps, e: Element) => {
            props.onExpand(props.record, e)
        }
                // 自定义展开icon
        const expandIcon = (props: expandProps) => {
            if (props.record.children && props.record.children.length > 0 ) {
                if (props.expanded) {
                    //有数据-展开时候图标
                    return h("i", {
                        class: "font_family icon-xiangxia m-l-5 cursor-p",
                        onClick: (e: Element) => {
                            clickExpand(props, e)
                        },
                    });
                } else {
                    //有数据-未展开时候图标
                    return h("i", {
                        class: "font_family icon-xiangyou m-l-5 cursor-p",
                        onClick: (e: Element) => {
                            clickExpand(props, e)
                        },
                    });
                }
            }  else {
                //无数据-图标
                return h("i", {
                    style: { height: '1px', display: 'inline-block'},
                });
            }
        }

        // select获得焦点时,先清空之前下拉框的值，再赋相关的值。col:当前列，cellSelect：当前组
        // 需要判断brand先选择
        const handleFocus = (index: number, colCur: string, row: AutoTableCellProps, type: string) => {
            const { incentiveAmount } = props
            // 1。加载loading
            fetching.value = true;
            // 先清空，下拉框的值，再赋值
            (props.incentiveAmount as any).tableCellSelect = []
            const params = {
                bu: store.state.pgmData.businessUnit.selected,
                row: row,
                target: colCur
            }
            if (type === 'SINGLE_SELECT') {
                searchSsscCandidate(params).then(res => {
                    // 用一个延时给下拉赋值，提示用户正常loading数据
                    setTimeout(() => {
                        // loading消失
                        fetching.value = false;
                        // 下拉赋值
                        (props.incentiveAmount as any).tableCellSelect = res
                    }, 100)
                    
                })
            } else {
                // searchCandidate(params).then(res => {
                //     // 用一个延时给下拉赋值，提示用户正常loading数据
                //     setTimeout(() => {
                //         // loading消失
                //         fetching.value = false;
                //         // 下拉赋值
                //         (props.incentiveAmount as any).tableCellSelect = res
                //         tableCellSelectData.value = res
                //     }, 100)
                    
                // })
                const param = {
                    eligibility: store.state.pgmData.eligibility,
                    groupId: [(props.incentiveAmount as any).groupId],
                    row: row,
                    target: colCur,
                    customer: store.state.pgmData.customer,
                    bu: store.state.pgmData.businessUnit.selected
                }
                searchOptions(param).then(res => {
                    fetching.value = false;
                    setTimeout(() => {
                        // loading消失
                        fetching.value = false;
                        // 下拉赋值
                        (props.incentiveAmount as any).tableCellSelect = res
                        tableCellSelectData.value = res
                    }, 100)
                })
            }
            
        }
        const handleBlur = (): void => {
            checked.value = false
        }
        
        // 得到所有的baumuster列
        const getBaumusterList = (val: string[], col: string, row: AutoTableCellProps) => {
            const targeArr = (props.incentiveAmount as any).tableCell.filter((item: AutoTableCellProps) => {
                for (let i = 0; i < val.length; i++) {
                    if (item[col].val.indexOf(val[i]) !== -1 && item.id !== row.id) {
                        return val[i]
                    }
                }
            })
            return targeArr
        }
        const openBranch = (index: number, col: string, row: AutoTableCellProps, type: string) => {

            const { incentiveAmount } = props
            // 1。判断是否存在
            const targeArr = getBaumusterList(row[col].val, col, row)
            if (targeArr.length > 0) { 
                message.warning(targeArr[0][col].val[0] + '已经存在')
                return
            }
            // 2。拆分行
            const cloneArr = deepClone(row)
            if (row[col].val.length > 1) {
                // 拆成的行，要从第二行开始
                cloneArr.id = guid()
                const arr: string = deepClone(row[col].val)
                cloneArr[col].val = [arr[1]]
                incentiveAmount.tableCell.push(cloneArr)
                // incentiveAmount.tableCell.splice(incentiveAmount.tableCell.length - 1, 0, cloneArr)
                // for (let i = 1; i < row[col].val.length; i++) {
                //     cloneArr.id = guid
                //     cloneArr[col].val = [row[col].val[i]];
                //     incentiveAmount.tableCell.splice(
                //         incentiveAmount.tableCell.findIndex((item: AutoTableCellProps) => item.id === row.id), incentiveAmount.tableCell.length - 1, cloneArr)
                // }
                // // 勾选的数组，第一行，就是第一个
                row[col].val = [arr[0]]
                tableCellSelectData.value = tableCellSelectData.value.filter((item: string) => item !== cloneArr[col].val[0])
            }
        }
        // 选择下拉后的值的改变:1.需要清空后面的值，2.如果是最后一行，里面有一个值改变了都需加一行
        const handleChangeSelect = (index: number, col: string, row: AutoTableCellProps, type: string) => {
            openBranch(index, col, row, type)
            //3.
        }
        // sssc选择自动take
        const handleChangeSelectSSSC = (index: number, col: string, row: AutoTableCellProps, type: string, selected: string) => {
            for (const i of (props.incentiveAmount as any).tableCellSelect) {
                if (i.SSSC_NAME === selected) {
                    for (const s in i) {
                        if (s !== col) {
                            row[s].val = i[s]
                        }
                    }
                }
            }
        }

        const { selectedRowKeys, onSelectChange, deleteRow } = useTableDelRowCol()
        
        

        const handleAllCheck = (index: number, col: string, row: AutoTableCellProps, type: string) => {
            checked.value = !checked.value
            if (checked.value) {
                row[col].val = (props.incentiveAmount as any).tableCellSelect
                openBranch(index, col, row, type)
            } else {
                row[col].val = []
            }
        }
        const getCheckboxProps = (record: AutoTableCellProps) => {
            if (record.parent !== '0') {
                return { disabled: true }
            } else {
                return { disabled: false }
            }
        }


        const comVisible = ref(false)
        const comData = ref<ComSettingProps[]>([])
        // 设置
        const handleComSetting = (incentive: any) => {
            comVisible.value = true
            comData.value = incentive.communicationSetting
        }
        
       
        // 删除列
        const handleDelCol = (item: ColumnsProps, group: APIDataProps) => {
            const params = {
                columnId: item.dataIndex,
                table: group
            }
            removeColumn(params).then(res => {
                group = res
                // store.commit('updatePgmData', res)
            })
        }

        const valateGroupTitle = () => {
            const { incentiveAmount } = props
            isEdit.value = !isEdit.value
            incentiveAmount.tableName = incentiveTitle.value
       }

        watch(() => props.incentiveAmount.tableCol, () => {
            // 表头
            getColums(props.incentiveAmount, false, {index: 0, width: '240px'})
        })
        const init = () => {
            // 表头
            getColums(props.incentiveAmount, false, {index: 0, width: '240px'})
        }
        onMounted(() => {
            init()
        })
        
        return {
            isEdit,
            incentiveTitle,
            selectVisible,
            

            columns,
            selectedRowKeys,
            onSelectChange,
            deleteRow,


            getCheckboxProps,


            checked,
            handleFocus,
            handleChangeSelect,
            handleAllCheck,
            handleBlur,
            expandIcon,
            handleDelCol,
            showDelArr,
            fetching,
            handleChangeSelectSSSC,

            handleComSetting,
            comData,
            comVisible,
            valateGroupTitle,
            tableCellSelectData
        }
    }
})
