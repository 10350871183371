 import { ref } from 'vue'
 import { useStore } from 'vuex'
 import { AutoTableCellProps } from '@/types'
 export const useInsetCol = () => {
    const store = useStore()
    // select table弹窗是否显示
    const selectVisible = ref(false)
    // 插入列，抽屉打开
    const handleInsetCol = (indexs: number, group: AutoTableCellProps[], offer: string, scope: string) => {
        const params = {
            bu: store.state.pgmData.businessUnit.selected,
            eligibility: store.state.pgmData.eligibility,
            programId: store.state.pgmData.programId,
            table: group,
            offerTypePathKey: offer
        }
        selectVisible.value = true
        store.commit('updatePgmInsetCol', params)
        sessionStorage.setItem('indexTable', JSON.stringify(indexs))
        sessionStorage.setItem('scope', scope)
    }
    
    return {
        selectVisible,
        handleInsetCol

    }
 }
 