
import { defineComponent, ref } from 'vue'
export default defineComponent({
    name: '',
    props: {
        visible: {
            type: Boolean,
            default: () => false
        },
        errorMsg: {
            type: String,
        }
    },
    setup(props, {emit}) {
        const onCloseErrorModal = () => {
            emit('on-close-error-modal')
        }
        return {
            onCloseErrorModal
        }

    }
 });
