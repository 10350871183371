
import deepClone from '@/utils/deepClone'
import { defineComponent, ref, reactive, onMounted, toRefs, toRef } from 'vue'
import { OptionProps } from '@/types'
import { MaintainColProps } from '../types'
import { useStore } from 'vuex'
export default defineComponent({
    props: {
        selectvisible:  {
            type: Boolean,
        },
        radilvalue: {
            type: String,
        }

    },
    setup (props, context) {
        const store = useStore()
        const fieldData = ref<OptionProps[]>([])
        const fakerFieldData = ref<OptionProps[]>([])
        // const groupData = ref<MaintainColProps>()
        const groupData = reactive([ 'Prepayment ratio decrease', 'Customer interest rate decrease', 'X Months payment waiver'])
        const selectData = ref()
        const radilValue = ref("")
        const handleCancel = () => {
          context.emit('update:selectvisible', false)
        //   props.radilValue = ''
        }
        const handleSubmit = () => {
            context.emit('update:selectvisible', false)
            context.emit('submitadd', radilValue.value)
            // setTimeout(() => {
                radilValue.value = ''
            // }, 100)
            
        }
        const handleGroup = (item: MaintainColProps) => {
            // if (item.children && item.children.length > 0) fieldData.value = item.children
            // fakerFieldData.value = deepClone(fieldData.value)
        }
        const dbhandleSelect = (e: Event, item: MaintainColProps) => {
            selectData.value = item.id
            handleSubmit()
        }

        // 搜索的value
        const valueSearch = ref('')
        const onSearch = () => {
            fieldData.value = fakerFieldData.value.filter(item => item.display.toUpperCase().indexOf(valueSearch.value.toUpperCase()) > -1)
        }

        
        // 单选的样式
        const radioStyle = reactive({
            display: 'block',
            height: '25px',
            lineHeight: '25px',
        });
        const handleSelect = (e: Event, list: MaintainColProps) => {
            const target = e && (e.target as HTMLElement)
            // 这个下面所有的LI
            const query = document.querySelectorAll<HTMLElement>('.field-data li')
            for (let i = 0; i < query.length; i++) {
                query[i].style.color = ''
            }
            // 当前的li
            if (target) {
                target.style.color = '#00ADEF';
            }
            selectData.value = list.id
        }
      
        return {
            radioStyle,
            handleCancel,
            handleSubmit,
            fieldData,
            fakerFieldData,
            handleGroup,
            groupData,
            dbhandleSelect,
            handleSelect,
            valueSearch,
            onSearch,
            radilValue
            
        }
    }
})
