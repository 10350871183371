
import { defineComponent, ref, PropType, onBeforeUnmount } from 'vue'
import IncentiveUnitList from './IncentiveUnitList.vue'
import { useStore } from 'vuex'
// 最后一列
import { designRefreshIncentive } from "@/API/program"
import { formatRequestData } from '@/utils'
import useEventbus from "@/hooks/claim/useEventbus"
import { IncentiveProps, IncentiveTableProps } from '@/types'
import { guid } from '@/utils'
import SsscList from '@/views/SSSC/SSSCList.vue'
export default defineComponent({
    components: { 
        IncentiveUnitList,
        SsscList
     },
    props: {
        incentiveAmount: {
            type: Array as PropType<IncentiveProps[]>,
            default: () => []
        },
        offer: {
            type: String,
            default: ''
        }
    },
    setup (props) {
        const store = useStore()
        const isFlag = ref(false)
        // take, 刷新数据
        const { customEmit } = useEventbus()
        const formatGroup = () => {
            let i = 1
            for (const key of store.state.pgmData.offer[props.offer].incentive) {
                for (const val of key.groupTable) {
                    val.formulaCode = 'G' + i;
                    i++;
                }
            }
        }
        const handleRefesh = (index: number) => {
            customEmit('updatevehicle')
            const params = {
                bu: store.state.pgmData.businessUnit.selected,
                eligibility: store.state.pgmData.eligibility,
                programId: store.state.pgmData.programId,
                precondition: store.state.pgmData.offer[props.offer].precondition,
                offerTypePathKey: props.offer
            }
             customEmit('updatevehicle')
             designRefreshIncentive(params).then(res => {
                store.state.pgmData.offer[props.offer].incentive[index].groupTable = res
                formatGroup()
            })
            
        }
        const handleAddGroup = (): void => {
            const { incentiveAmount } = props
            const param: IncentiveProps = {
                groupId: guid(),
                groupName: '',
                groupTable: []
            }
                
            incentiveAmount.push(param);
        }

        const handleDelGroup = (index: number) => {
            const { incentiveAmount } = props
            incentiveAmount.splice(index, 1)
        }
        return {
            isFlag,
            handleRefesh,
            handleAddGroup,
            handleDelGroup
        }
    }
})
