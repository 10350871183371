export interface MeasureProps {
    id: string;
    display: string;
    type: string;
    children: MeasureProps[];
    isEdit: boolean;
}
export const dataMeasure: MeasureProps[] = [
    {
        "id": "Amount w/o VAT",
        "display": "Amount w/o VAT",
        "type": "M_DECIMAL",
        "children": [],
        "isEdit": true
    },
    {
        "id": "% of MSRP w/o VAT",
        "display": "% of MSRP w/o VAT",
        "type": "M_PERCENT",
        "children": [],
        "isEdit": true
    },
    {
        "id": "% of Margin base",
        "display": "% of Margin base",
        "type": "M_PERCENT",
        "children": [],
        "isEdit": true
    },
    {
        "id": "% of DPP w/o VAT",
        "display": "% of DPP w/o VAT",
        "type": "M_PERCENT",
        "children": [],
        "isEdit": true
    },
    {
        "id": "% of actual tax",
        "display": "% of actual tax",
        "type": "M_PERCENT",
        "children": [],
        "isEdit": true
    },
    {
        "id": "% MSRP including options w/o VAT",
        "display": "% MSRP including options w/o VAT",
        "type": "M_PERCENT",
        "children": [],
        "isEdit": true
    },
    {
        "id": "% MSRP w/o VAT of option package",
        "display": "% MSRP w/o VAT of option package",
        "type": "M_PERCENT",
        "children": [],
        "isEdit": true
    },
    {
        "id": "Insurance total support amount",
        "display": "Insurance total support amount",
        "type": "M_DECIMAL",
        // 是否自动计算
        // children里面所有的列的和，到父级中，父级不可编辑
        "children": [
            {
                "id": "New Car Insurance support amount in the 1st year",
                "display": "New Car Insurance support amount in the 1st year",
                "type": "M_DECIMAL",
                "isEdit": true,
                children: []
            }
        ],
        "isEdit": false
    },
    {
        "id": "Fixed-content Insurance",
        "display": "Fixed-content Insurance",
        "type": "M_TEXT",
        "children": [],
        "isEdit": true
    },
    {
        "id": "Total payment term days",
        "display": "Total payment term days",
        "type": "M_INTEGER",
        "children": [
            {
                "id": "Extend payment term days_Total payment term days",
                "display": "Extend payment term days",
                "type": "M_INTEGER",
                "isEdit": true,
                children: []
            },
            {
                "id": "Normal payment term days_Total payment term days",
                "display": "Normal payment term days",
                "type": "M_INTEGER",
                "isEdit": true,
                children: []
            }
            
        ],
        isEdit: false
    },
    {
        "id": "Reference value w/o VAT",
        "display": "Reference value w/o VAT",
        "type": "M_DECIMAL",
        "children": [],
        "isEdit": true
    },
    {
        "id": "Reference value w/ VAT",
        "display": "Reference value w/ VAT",
        "type": "M_DECIMAL",
        "children": [],
        "isEdit": true
    },
    {
        "id": "Percentage",
        "display": "Percentage",
        "type": "M_PERCENT",
        "children": [],
        "isEdit": true
    },
    {
        "id": "Score",
        "display": "Score",
        "type": "M_INTEGER",
        "children": [],
        "isEdit": true
    },
    {
        "id": "Days count (Minus normal PMT days)",
        "display": "Days count (Minus normal PMT days)",
        "type": "M_TEXT",
        "children": [],
        "isEdit": true
    },
    {
        "id": "Minimum monthly mileage",
        "display": "Minimum monthly mileage",
        "type": "M_INTEGER",
        "children": [],
        "isEdit": true
    },
    {
        "id": "Maximum support amount",
        "display": "Maximum support amount",
        "type": "M_DECIMAL",
        "children": [],
        "isEdit": true
    },
    {
        "id": "Minimum total mileage",
        "display": "Minimum total mileage",
        "type": "M_INTEGER",
        "children": [],
        "isEdit": true
    },
    {
        "id": "Maximum support Volume",
        "display": "Maximum support Volume",
        "type": "M_INTEGER",
        "children": [],
        isEdit: true
    },
    {
        "id": "Normal payment term days",
        "display": "Normal payment term days",
        "type": "M_INTEGER",
        "children": [],
        isEdit: true
    },

    {
        "id": "Extended payment term days",
        "display": "Extended payment term days",
        "type": "M_TEXT",
        "children": [],
        isEdit: true
    },
    {
        "id": "Higher/Lower",
        "display": "Higher/Lower",
        "type": "M_SINGLE_SELECT",
        "children": [],
        isEdit: true
    }
]
export const HLOptions: MeasureProps[] = [
    {
        "id": 'Higher',
        "display": 'Higher',
        "type": "M_SINGLE_SELECT",
        "isEdit": true,
        children: []
    },
    {
        "id": 'Lower',
        "display": 'Lower',
        "type": "M_SINGLE_SELECT",
        "isEdit": true,
        children: []
    }
]
