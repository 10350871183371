
import { defineComponent } from 'vue';
import IncentiveUnit from './IncentiveUnit.vue';
import PreCondition from './PreCondition.vue';
import Ranking from './Ranking.vue';
import ProgramContent from '../FA/ProgramContent.vue';
import VehicleLicense from './VehicleLicense.vue';
import Calculator from './Calculator.vue';
import Text from './components/Text.vue';
import Attachement from './components/Attachement.vue';
export default defineComponent({
  components: {
    IncentiveUnit,
    PreCondition,
    ProgramContent,
    VehicleLicense,
    Calculator,
    Ranking,
    Text,
    Attachement
  }
});
