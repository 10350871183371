
import { defineComponent, ref, PropType, watch } from 'vue'
import SelectGroup from './SelectGroup.vue'
import { ColumnsProps } from '@/types'
import { BuyFLWithProps } from './utils'

const columns: ColumnsProps[] = [
    {
        title: 'Type Class',
        dataIndex: 'typeClass',
        key: 'typeClass'
    },
    {
        title: 'Model Year',
        dataIndex: 'modelYear',
        key: 'modelYear',
    },
    {
        title: 'Model Name',
        dataIndex: 'model',
        key: 'model'
    },
    {
        title: 'Max. total rental deduction amount',
        dataIndex: 'rentalAmount',
        key: 'rentalAmount',
    }
]
export default defineComponent({
    components: { 
        SelectGroup, 
    },
    props: {
        maxWidth: {
            type: String,
            default: () => '300'
        },
        dataSource: {
            type: Object as PropType<BuyFLWithProps>,
            default: () => {
                return {
                    groupTitle: '',
                    tables: [],
                    groupCommon: [{groupTitle: '',isEdit: false,comments:'',}]
                }
            }
        }
    },
    setup (props) {

        const ratioDescirbe = ref<any>([])
        // 是否可以编辑
        // 表格列的定义
        // const columns = ref<ColumnsProps[]>([])

        const selectVisible = ref(false)
        let indexGroup = 0
        // 新增一组:1.首先需要拿到vehicle，有多少组。
        const handleAddGroup = (index: number): void => {
            selectVisible.value = true
            indexGroup = index
        }

        const formatGroup = (groupTitle: string) => {
            const { dataSource } = props
            const newGroup = {
                groupTitle,
                isEdit: false,
                comments: ''
            }
            dataSource[indexGroup].groupCommon.push(newGroup)
        }

        // const { selectVisible, handleInsetCol } = useInsetCol()
        const groupTitle = ref('Prepayment ratio decrease')
        const submitAdd = (radilValue: string) => {
            if (radilValue) {
                formatGroup(radilValue)
            }
        }

        // 是否可以编辑incentiveTitle标题编辑标题
        const isEdit = ref(false)
        const valateGroupTitle = (index: number) => {
            const { dataSource } = props
            dataSource[index].isEdit = !dataSource[index].isEdit
            // dataSource[index].groupTitle = groupTitle.value
       }
        
        return {
            selectVisible,
            ratioDescirbe,
            columns,
            groupTitle,
            isEdit,
            handleAddGroup,
            submitAdd,
            valateGroupTitle,
        }
    }
})
