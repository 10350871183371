
import { defineComponent, ref, onMounted, PropType } from 'vue'
import { message } from "ant-design-vue";
import { useStore } from 'vuex'
import { refreshFormula, FormulaProps, FormulaGroupProps } from "@/API/program"
export default defineComponent({
    name: '',
    props: {
      offer: {
        default: '',
        type: String
      },
      formulas: {
        default: () => {
          return {}
        },
        type: Object as PropType<FormulaGroupProps>
      }
    },
    setup(props) {
        const store = useStore()
        // const { precondition, incentiveUnit, vehicleLicense, formula} = toRefs(formulaData)
        const vehicleLicense = ref<FormulaProps[]>([])
        const incentive = ref<FormulaProps[]>([])
        const precondition = ref<FormulaProps[]>([])
        const formula = ref<FormulaProps[]>([])
        // 编辑区的数据
        const editData: any = ref([])
        // 控制选中的边框样式
        const activeIndex = ref()
        // 编辑确定后，显示的数据
        const equationList: any = ref([])

        // 记录删除的类型 是符号还是文字
        let shortType: string

        const isShow = ref(true)

        const isFlag = ref(false)
        
        // 添加code的数据
        const addCode = (res: any) => {
          let isPush = true
          // 遍历数据
          for (let i = 0; i < editData.value.length; i++) {
            // 如果有过删除得操作
            if (!editData.value[i]) {
              isPush = false
              if (shortType === 'code') {
                editData.value.splice(i, 1)
                editData.value.splice(i, 0 , res)
              } else {
                message.error('请选择运算符号')
              }
            } 
          }
          if (isPush) {
            if (editData.value.length !==0 && (editData.value[editData.value.length-1].code || (editData.value[editData.value.length-1].operator && editData.value[editData.value.length-1].operator === ')')) ) {
                // 如果上一次输入的是文字，则不允许输入文字, 上一次输入的是 ) 也不能输入文字
                message.error('请选择运算符号')
            } else {
                editData.value.push(res)
            }
          }
          
        }

        // 判断是否存在着 ) ,
        const queryChar = () => {
          let isExist = false 
          let ltNumber = 0
          let gtNumber = 0
          if (editData.value.length > 0) {
            for (const item of editData.value) {
              if (item.operator) {
                if (item.operator === '(') ltNumber += 1;
                if (item.operator === ')') gtNumber += 1;
              }
            }
          }
          // 判断括号的数量是否相等
          if (ltNumber === gtNumber) {
            isExist = true
          } else if (ltNumber > gtNumber) {
            isExist = false
          }
          console.log(isExist, 'isExist');
          return isExist
        }

        // 添加运算符
        const addOperator = (value: string|undefined) => {
          let isPush = true
          for (let i = 0; i < editData.value.length; i++) {
            // 如果有过删除得操作
            if (!editData.value[i]) {
              isPush = false
              if (shortType === 'operator' && value && value !== 'OK') {
                editData.value.splice(i, 1)
                editData.value.splice(i, 0 , {operator: value})
              } else {
                message.error('请选择运算变量')
              }
            } 
          }
          if (isPush) {
              if (value !== 'OK') {
                if (!value) {
                  // 删除一项
                  editData.value.pop()
                } else if (value === 'C') {
                  // 删除全部
                  editData.value = []
                } else if (value === '(') {
                  if (editData.value.length === 0 || (queryChar() && editData.value[editData.value.length - 1].operator)) {
                    editData.value.push({operator: value})
                  }
                //   } else {
                //     message.error('请选择其他的运算符号0')
                //   }
                // } else if (value === ')') {
                //   if (!queryChar() && editData.value[editData.value.length - 1].code) {
                //     editData.value.push({operator: value})
                //   } else {
                //     message.error('请选择其他的运算符号1')
                //   }
                // } else if (editData.value.length !== 0 && editData.value[editData.value.length - 1].operator && editData.value[editData.value.length - 1].operator !== ')') {
                //     message.error('请选择其它的运算符号2')
                // } else if (editData.value.length === 0) {
                //     if (value !== '(') message.error('请选择其他')
                } else {
                  editData.value.push({operator: value})
                }
              }
              if (value === 'OK') {
                // 确认公式
                // if ((editData.value[editData.value.length - 1].operator && editData.value[editData.value.length - 1].operator !== ')') || !queryChar()) {
                //   message.error('请输入完整的公式')
                // } else {
                  formula.value.push(editData.value)
                  editData.value = []
                  store.state.pgmData.offer[props.offer].formula = {
                      formula: formula.value,
                      incentive: incentive.value,
                      precondition: precondition.value,
                      vehicleLicense: vehicleLicense.value
                  }
                // }
              }
                 
          }
         
        }

        // 点击带有序号的数据
        const handleSerial = (res: any) => {
          // res['code'] = res.code
          activeIndex.value = res.id 
          addCode(res)
        } 

        // 点击带有group的数据
        const handleGroup = (res: any) => {
          // res['code'] = 'G' + (index +1)
          activeIndex.value = res.id 
          addCode(res)
        }
        
        // 点击操作运算符
        const handleOperator = (even: any) => {
          addOperator(even.target.innerText)
        }

        // 删除tag 
        const delTag = (index: number) => {
          // 记录下删除的是符号，还是code变量
          if (editData.value[index].code) {
            shortType = 'code'
          } else {
            shortType = 'operator'
          }
          editData.value.splice(index, 1, '')

        }
        // take公式
        const handleRef = () => {
          refreshFormula(store.state.pgmData).then(res => {
            if (res.offer && res.offer[props.offer]) {
              precondition.value = res.offer[props.offer].formula.precondition
              incentive.value = res.offer[props.offer].formula.incentive
              vehicleLicense.value = res.offer[props.offer].formula.vehicleLicense
              // if (res.offer[props.offer].formula.formula) {
              //   formula.value = res.offer[props.offer].formula.formula
              // }
              formula.value = []
              store.state.pgmData.offer[props.offer].formula = res.offer[props.offer].formula
              store.state.pgmData.offer[props.offer].formula.formula = []
            }
          })
        }
        // 删除确定的公式
        const delEquatio = (index: number) => {
          formula.value.splice(index, 1)
        }
        
        onMounted(() => {
          if (props.formulas.formula) {
            formula.value = props.formulas.formula
          }
          incentive.value = props.formulas.incentive
          precondition.value = props.formulas.precondition
          vehicleLicense.value = props.formulas.vehicleLicense
        })
         return {
            handleRef,
            vehicleLicense,
            formula,
            // 数据
            precondition,
            incentive,

            isFlag,

            delTag,
            activeIndex,
            handleSerial,
            handleGroup,
            handleOperator,
            editData,

            equationList,
            delEquatio,
            isShow
         }

    }
 });
