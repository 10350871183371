
import { computed, defineComponent, h, ref, onMounted } from "vue";
import { AutoTableCellProps, AutoTableColProps, APIDataProps } from "@/types";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import { searchDealer } from "@/API/program";
import useEventbus from "@/hooks/claim/useEventbus";
import { clearCellData } from "@/utils/pgm";
import { useTableAddRow } from "@/hooks/Program/useTableAddRow";
import { useTableOperation } from "@/hooks/Program/useTableOperation";
import { useAllCheck } from "@/hooks/Program/useAllCheck";
// import { handleDelete } from "./util";
import { OptionProps } from "@/types";
import { guid } from "@/utils/index";
import { expandProps } from "@/views/Budget/types";
import deepClone from "@/utils/deepClone";
import { useTableAddRowChildren } from "@/hooks/Program/useTableAddRowChildren";

const groups = {
  tableId: "",
  tableCol: [],
  tableCell: [],
  tableCellSelect: [],
};
const IESelect: OptionProps[] = [
  {
    display: "Include",
    id: "11",
  },
  {
    display: "Exclude",
    id: "12",
  },
];

export default defineComponent({
  components: {
    VNodes: (_, { attrs }) => {
      return attrs.vnodes;
    },
  },
  setup() {
    const store = useStore();
    // 组数据
    const offerGrops = ref<any>(groups);
    const cols = ref<AutoTableColProps[]>([]);
    const fetching = ref<boolean>(false);

    // 折叠
    const isFlag = ref(false);

    const { autoInsetRow } = useTableAddRow();

    const { checked, currentChecked } = useAllCheck();
    // select获得焦点时,先清空之前下拉框的值，再赋相关的值。col:当前列，cellSelect：当前组
    // 需要判断Dealer先选择
    const handleFocus = (
      index: number,
      colCur: string,
      row: AutoTableCellProps,
      vals: any
    ): void => {
      //TODO 这里需要修改
      autoInsetRow(offerGrops.value.tableCell, offerGrops.value.tableCol, row);
      // 1。加载loading
      fetching.value = true;
      // 3。先清空，下拉框的值，再赋值
      offerGrops.value.tableCellSelect = [];
      // 4.如果第一列的dealer没有选的时候，提示用户先选择
      if (row[cols.value[0].id].val.length < 1 && colCur !== cols.value[0].id) {
        message.error("Please choose the Dealer type first!");
        return;
      }
      const params = {
        bu: store.state.pgmData.businessUnit.selected,
        id: row.id,
        row: row, // 当前行数据
        target: colCur, // 给哪个id请求下拉数据
      };

      // 5.请求后端给筛下拉数据
      searchDealer(params).then((res) => {
        // 用一个延时给下拉赋值，提示用户正常loading数据
        setTimeout(() => {
          // loading消失
          fetching.value = false;
          // 下拉赋值
          offerGrops.value.tableCellSelect = res;
          currentChecked(offerGrops.value.tableCellSelect, vals.val);
        }, 100);
      });
    };

    // 选择下拉后的值的改变:1.需要清空后面的值，2.如果是最后一行，里面有一个值改变了都需加一行
    const handleChangeSelect = (
      index: number,
      col: string,
      row: AutoTableCellProps
    ) => {
      if (row[col].val.length > 0) row[col].isAuto = false;
      // 1.需要清空后面的值
      clearCellData(index, cols.value, row);
    };

    // 点击选择全选,直接赋上了所有的值，取消全选，就表空所有的数据
    const handleAllCheck = (
      index: number,
      col: string,
      row: AutoTableCellProps
    ) => {
      checked.value = !checked.value;
      if (checked.value) {
        row[col].val = offerGrops.value.tableCellSelect;
        clearCellData(index, offerGrops.value.tableCol, row);
      } else {
        row[col].val = [];
      }
    };

    // 得到colums, 和头部
    const { getColums, columns } = useTableOperation();
    // 加入是否是被自动清空的
    const formatData = (): void => {
      for (const key of offerGrops.value.tableCell) {
        for (const i in key) {
          if (key[i].constructor === Object) {
            key[i].isAuto = false;
          }
        }
      }
    };

    const { customOn } = useEventbus();
    // 父级监听子级更新数据到vuex
    customOn("updatedealer", () => {
      store.state.pgmData.eligibility.dealer.tableCell =
        offerGrops.value.tableCell;
    });

    // 展开清空
    const dropdownVisibleChange = (): void => {
      offerGrops.value.tableCellSelect = [];
    };

    // 自定义展开icon图标-----------------------展开折叠部分
    const clickExpand = (props: expandProps, e: Element) => {
      const id = props.record.id;
      const keys = expandedRowKeys.value;
      if (keys.includes(id)) {
        const index = keys.findIndex((item: any) => item === id);
        keys.splice(index, 1);
      } else {
        keys.push(props.record.id);
      }
      props.onExpand(props.record, e);
    };
    // 得到exclude行
    const getExcludeRow = (excludeRow: AutoTableCellProps) => {
      for (const i in excludeRow) {
        if (excludeRow[i].constructor === Object && i !== "ACT_0") {
          excludeRow[i].val = [];
        }
      }
      return excludeRow;
    };
    const expandedRowKeys = ref<any>([]);
    const genExpandedRowKeys = () => {
      const keyMaps = offerGrops.value.tableCell.map((item: any) => {
        return item.id;
      });
      return store.state.pgmData.readOnly ? keyMaps : [];
    };

    // 点击添加Exclude, 增加新的一行，Exclude
    const clickAddExclude = (props: any) => {
      const param = deepClone(props.record);
      param.id = guid();
      if (Object.keys(offerGrops.value).length > 0) {
        for (const val of offerGrops.value.tableCell) {
          if (val.id === props.record.parent) {
            // parent
            param.parent = props.record.parent;
            if (val.children) val.children.push(getExcludeRow(param));

            break;
          }
        }
      }
    };

    // const expandedRowKeys = computed(() => {
    //   const keyMaps = offerGrops.value.tableCell.map((item: any) => {
    //     return item.id;
    //   })
    //   return store.state.pgmData.readOnly ? keyMaps : [];
    // });

    // 自定义展开icon
    const expandIcon = (props: expandProps) => {
      if (props.record.children && props.record.children.length > 0) {
        if (props.expanded) {
          //有数据-展开时候图标
          return h("i", {
            class: "font_family icon-xiangxia cursor-p m-r-20",
            onClick: (e: Element) => {
              clickExpand(props, e);
            },
          });
        } else {
          //有数据-未展开时候图标
          return h("i", {
            class: "font_family icon-xiangyou cursor-p m-r-20",
            onClick: (e: Element) => {
              clickExpand(props, e);
            },
          });
        }
      } else {
        //无数据-图标
        return h("i", {
          class: "font_family icon-tianjia11 cursor-p m-r-20",
          style: { width: "16px", height: "1px", display: "inline-block" },
          onClick: () => {
            clickAddExclude(props);
          },
        });
      }
    };

    const { addNewInclude, addNullExclude } = useTableAddRowChildren();

    // 得到要渲染的数据树形结构
    const formatTree = (): void => {
      // 1.组成树形结构
      // 2.需要一进入就要自动加一行空白行,因为数据结构给的是平铺的，我们显示要用children
      if (offerGrops.value.tableCell) {
        offerGrops.value.tableCell.push(
          addNullExclude(addNewInclude(cols.value), offerGrops.value)
        );
      }
    };

    // 删除一行
    const handleDelete = (item: APIDataProps, record: AutoTableCellProps) => {
      if (item.tableCell && item.tableCell.length > 0) {
        // 当删除的是父级时
        if (record.parent === "0") {
          if (item.tableCell.length > 1) {
            item.tableCell = item.tableCell.filter(
              (val: AutoTableCellProps) => record.id !== val.id
            );
          } else {
            message.warning("This is the last one!");
          }
        } else {
          // 当删除的是子级时
          let targetObj: AutoTableCellProps = record;
          for (const val of item.tableCell) {
            if (record.parent === val.id) {
              targetObj = val;
              break;
            }
          }
          if (
            targetObj &&
            targetObj.children &&
            targetObj.children.length > 1
          ) {
            item.tableCell = item.tableCell.map((val: AutoTableCellProps) => {
              if (val.children && val.children.length > 1) {
                val.children = val.children.filter(
                  (vals: AutoTableCellProps) => record.id !== vals.id
                );
              }
              return val;
            });
          } else {
            message.warning("This is the last one!");
          }
        }
      }
    };

    // 初始化需要:1.
    const init = (): void => {
      // 表头
      cols.value = store.state.pgmData.eligibility.dealer.tableCol;
      // 赋值给页面显示用
      offerGrops.value = store.state.pgmData.eligibility.dealer;
      expandedRowKeys.value = genExpandedRowKeys();
      getColums(offerGrops.value, true);
      formatTree();
      formatData();
    };

    onMounted(() => {
      init();
      // autoInsetRow(offerGrops.value.tableCell, offerGrops.value.tableCol);
    });

    return {
      cols,
      columns,
      offerGrops,
      checked,
      handleAllCheck,
      handleFocus,
      handleChangeSelect,
      handleDelete,
      isFlag,
      dropdownVisibleChange,
      fetching,
      IESelect,
      expandIcon,
      expandedRowKeys,
    };
  },
});
