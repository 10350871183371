
import { defineComponent, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { refreshComments } from "@/API/program"
export default defineComponent({
    name: '',
    props: {
        text: {
            default: '',
            type: String
        },
        offer: {
            default: '',
            type: String
        },
        keys: {
            default: '',
            type: String
        }
    },
    setup(props, { emit }) {
        const store = useStore()
        const editText = ref<string>('')
        const isFlag = ref<boolean>(false)
        const handleRef = () => {
            const param = {
                bu: store.state.pgmData.businessUnit.selected,
                eligibility: store.state.pgmData.eligibility,
                lang: store.state.lang,
                offerTypePathKey: props.offer,
                precondition: store.state.pgmData.offer[props.offer].precondition,
                programId: store.state.pgmData.programId,
                target: props.keys
            }
            refreshComments(param).then(res => {
                editText.value = res
                emit('update:note', editText.value)
            })

        }
        const handleChange = () => {
            emit('update:note', editText.value)
        }
        onMounted(() => {
            editText.value = props.text
        })
        return {
            editText,
            handleRef,
            isFlag,
            handleChange
        }
    }
})
