
import { defineComponent, ref, watch, onMounted } from "vue";
import deepClone from "@/utils/deepClone";
import moment, { Moment } from "moment";
import { useStore } from "vuex";
import { formatRequestData } from "@/utils";
import { LocalRegProps, AreasProps } from "../types";
import { message } from "ant-design-vue";
import useEventbus from "@/hooks/claim/useEventbus";
import {
  getRegList,
  getAreasDate,
  getRestrictedData,
  saveLocalReg,
  getSpecialData,
  getSpecialParametersData,
  getSpecialValueData,
  delSpecialData,
  saveSpacialRule,
  getSpecialDataEdit,
} from "@/API/program";
interface DataSourceProps {
  key?: string;
  id?: string;
  Restriction: string;
  registrationTime: string;
}

interface GroupTables {
  id: string;
  display: string;
}

const columns = [
  {
    title: "Parameter",
    dataIndex: "parameter",
    key: "parameter",
    // slots: { customRender: "Restriction" },
    width: 260,
  },
  {
    title: "Value",
    dataIndex: "parameterValue",
    width: 260,
  },
  {
    title: "Province",
    dataIndex: "provinceCN",
    key: "provinceCN",
    width: 260,
  },
  {
    title: "City",
    dataIndex: "cityCN",
    width: 260,
  },
  {
    title: "Time",
    dataIndex: "registeredTime",
    slots: { customRender: "time" },
    // width: 200
  },
];

// Registration 抽屉表格的配置
const columnsReg = [
  {
    title: "Province",
    dataIndex: "province",
    key: "province",
    slots: { customRender: "province" },
  },
  {
    title: "City",
    dataIndex: "city",
    key: "city",
    slots: { customRender: "city" },
  },
  {
    title: "Operation",
    slots: { customRender: "operation" },
  },
];

//
const columnsSpe = [
  {
    title: "Parameter",
    dataIndex: "parameter",
    key: "parameter",
    slots: { customRender: "parameter" },
  },
  {
    title: "Value",
    dataIndex: "value",
    key: "value",
    slots: { customRender: "value" },
  },
  {
    title: "Province",
    dataIndex: "province",
    key: "province",
    slots: { customRender: "province" },
  },
  {
    title: "City",
    dataIndex: "city",
    key: "city",
    slots: { customRender: "city" },
  },
  {
    title: "Time",
    dataIndex: "time",
    key: "time",
    slots: { customRender: "time" },
  },
  {
    title: "Operation",
    slots: { customRender: "operation" },
  },
];

export default defineComponent({
  setup() {
    const store = useStore();
    const dataSource = ref<any[]>([]);

    // 在编辑时表格一行的数据

    const showLocal = ref(store.state.pgmData.localRegistrationEnabled);

    const registrationObj = ref();
    const tableSpeData = ref();

    const getLoaclRegData = async () => {
      const params = {
        bu: store.state.pgmData.businessUnit.selected,
        "program-id": store.state.pgmData.programId,
      };

      if (showLocal.value) {
        getRegList({ params }).then((res: any) => {
          registrationObj.value = res;
        });

        const specialRuleWithVehicle = {
          bu: store.state.pgmData.businessUnit.selected,
          programId: store.state.pgmData.programId,
          eligibility: store.state.pgmData.eligibility,
        };
        tableSpeData.value = await getSpecialData(specialRuleWithVehicle);
      }
    };
    const { customOn } = useEventbus();

    customOn("updatelocalreg", () => {
      getLoaclRegData();
    });

    watch(
      () => showLocal.value,
      (newShow) => {
        if (!newShow) {
          const params = {
            bu: store.state.pgmData.businessUnit.selected,
            "program-id": store.state.pgmData.programId,
          };
          store.state.pgmData.localRegistrationEnabled = false;
        } else {
          store.state.pgmData.localRegistrationEnabled = true;
        }
        getLoaclRegData();
      }
    );

    const isFlag = ref(true);

    // Registration的抽屉
    const visibleRegis = ref(false);
    const dataSourceReg = ref();
    const restrictedArr = ref();

    const localRegData = ref<LocalRegProps>({
      ruleAreas: [],
      noRestrictedCityRegisteredTime: 0,
      restrictedCityRegisteredTime: 0,
      id: "",
      bu: store.state.pgmData.businessUnit.selected,
      timeUnit: "month",
      programId: store.state.pgmData.programId,
    });

    // Special的抽屉
    const columnTem = ["parameter", "value", "province", "city", "time"];
    const visibleSpe = ref(false);
    const dataSourceSpe = ref();

    // 省级 市级城市
    const provinceValue = ref();
    const cityValue = ref();

    const areasArr = ref<AreasProps[]>([]);
    const cityArr = ref();

    // 点击edit (egistration rule)
    const editReg = () => {
      visibleRegis.value = true;
      const params = {
        bu: store.state.pgmData.businessUnit.selected,
      };
      getRestrictedData({ params }).then((res: any) => {
        if (Object.keys(res).length) {
          localRegData.value = res;
        } else {
          localRegData.value.ruleAreas = [];
          localRegData.value.restrictedCityRegisteredTime = 0;
          localRegData.value.noRestrictedCityRegisteredTime = 0;
          localRegData.value.bu = store.state.pgmData.businessUnit.selected;
        }
      });
    };

    // 删除reg
    const onDeleteReg = (index: number) => {
      localRegData.value.ruleAreas.splice(index, 1);
    };

    // 选择省份和城市后, 更改源数据的 中英文变量
    const changeRegData = (index: number, name: string, type = "") => {
      const { nameCN, nameEN } = areasArr.value.filter(
        (item: AreasProps) => item.nameCN === name
      )[0];
      if (type) {
        localRegData.value.ruleAreas[index].provinceCN = nameCN;
        localRegData.value.ruleAreas[index].provinceEN = nameEN;
      } else {
        dataSourceSpe.value[index].provinceEN = nameEN;
        dataSourceSpe.value[index].provinceCN = nameCN;
      }
    };

    const changeRegCityData = (index: number, name: string, type = "") => {
      const { nameCN, nameEN } = cityArr.value.filter(
        (item: AreasProps) => item.nameCN === name
      )[0];
      if (type) {
        localRegData.value.ruleAreas[index].cityCN = nameCN;
        localRegData.value.ruleAreas[index].cityEN = nameEN;
      } else {
        dataSourceSpe.value[index].cityCN = nameCN;
        dataSourceSpe.value[index].cityEN = nameEN;
      }
    };

    // 下拉选择省份，取值对应的城市
    const selectPro = (index = 0, value: string, res: any = "") => {
      changeRegData(index, value, "registration");
      // if (value) cityArr.value = (areasArr.value.filter((item: any) => item.id === value))[0].children
      res.cityCN = "";
      res.cityEN = "";
    };
    const onBlurCity = (value: string) => {
      if (value)
        cityArr.value = areasArr.value.filter(
          (item: any) => item.nameCN === value
        )[0].children;
    };

    const selectCity = (index: number, value: string) => {
      changeRegCityData(index, value, "registration");
    };

    // 验证是否存在必填项是空的
    const verifyNull = (): boolean => {
      const doms = document.querySelectorAll(".required-tab");
      let bool = true;
      for (let i = 0; i < doms.length; i++) {
        const text = doms[i].querySelector(
          ".ant-select-selection-item"
        )?.innerHTML;
        if (!text) {
          (doms[i].firstElementChild as HTMLElement).style.border =
            "1px solid #f5222d";
          bool = false;
        } else {
          (
            doms[i].querySelector(".ant-select-selector") as HTMLElement
          ).style.border = "1px solid #d9d9d9";
        }
      }
      const domTimes = document.querySelectorAll(".required-time");
      if (domTimes.length > 0) {
        for (let i = 0; i < domTimes.length; i++) {
          const value = domTimes[i].querySelector("input")?.value;
          if (!value) {
            (domTimes[i].firstElementChild as HTMLElement).style.border =
              "1px solid #f5222d";
            bool = false;
          } else {
            (domTimes[i].firstElementChild as HTMLElement).style.border =
              "1px solid #d9d9d9";
          }
        }
      }
      return bool;
    };

    const isSubmit = ref(true);
    const onSubmitReg = () => {
      if (!verifyNull() || !isSubmit.value) {
        message.error("请填写正确的内容");
        return;
      }
      localRegData.value.bu = store.state.pgmData.businessUnit.selected;
      localRegData.value.programId = store.state.pgmData.programId;
      saveLocalReg(localRegData.value).then(() => {
        const params = {
          "program-id": store.state.pgmData.programId,
          bu: store.state.pgmData.businessUnit.selected,
        };
        getRegList({ params }).then((res: any) => {
          registrationObj.value = res;
        });
        visibleRegis.value = false;
      });
    };

    // 点击edit (Special rule)
    const parameterData = ref();
    const editSpe = () => {
      visibleSpe.value = true;
      const params = {
        bu: store.state.pgmData.businessUnit.selected,
      };
      getSpecialDataEdit({ params }).then((res) => {
        dataSourceSpe.value = res;
      });
      getSpecialParametersData().then((res) => {
        parameterData.value = res;
      });
    };
    const valueArr = ref();
    // 下拉选择parameter 获取value的值
    const selectParameter = (value: string, record: any) => {
      const params = {
        parameter: value,
      };
      getSpecialValueData({ params }).then((res) => {
        valueArr.value = res;
        record.parameterValue = valueArr.value[0];
      });
    };

    const onFocus = (value: string) => {
      valueArr.value = [];
      const params = {
        parameter: value,
      };
      getSpecialValueData({ params }).then((res) => {
        valueArr.value = res;
      });
    };

    // 下拉选择省份城市
    const selectProSpec = (
      index = 0,
      value: string,
      res: any = "",
      e: Event
    ) => {
      changeRegData(index, value);
      // if (value) cityArr.value = (areasArr.value.filter((item: any) => item.id === value))[0].children
      res.cityCN = "";
      res.cityEN = "";
    };
    const selectCitySpec = (index: number, value: string) => {
      changeRegCityData(index, value);
    };

    const onBlur = () => {
      console.log(areasArr.value, "areasArr");
      verifyNull();
    };

    // 删除spec 这个表格
    const onDeleteSpec = (id: string, index: number) => {
      const params = {
        id,
      };
      if (!id) {
        dataSourceSpe.value.splice(index, 1);
      } else {
        delSpecialData({ params }).then(async () => {
          message.success("删除成功");
          // 删除成功后, 重新获取数据
          const specialRuleWithVehicle = {
            bu: store.state.pgmData.businessUnit.selected,
            programId: store.state.pgmData.programId,
            eligibility: store.state.pgmData.eligibility,
          };
          tableSpeData.value = await getSpecialData(specialRuleWithVehicle);
          const params = {
            bu: store.state.pgmData.businessUnit.selected,
          };
          getSpecialDataEdit({ params }).then((res) => {
            dataSourceSpe.value = res;
          });
        });
      }
    };

    // 保存special rule的时候
    const onSubmitSpec = async () => {
      if (!verifyNull() || !isSubmit.value) {
        message.error("请填写正确的内容");
        return;
      }

      const specialRuleWithVehicle = {
        bu: store.state.pgmData.businessUnit.selected,
        programId: store.state.pgmData.programId,
        eligibility: store.state.pgmData.eligibility,
        specialRules: [
          {
            id: "",
            buCode: "",
            cityCN: "",
            cityEN: "",
            parameter: "",
            parameterValue: "",
            programId: "",
            provinceCN: "",
            provinceEN: "",
            registeredTime: 0,
            timeUnit: "",
          },
        ],
      };

      specialRuleWithVehicle.specialRules = dataSourceSpe.value;
      saveSpacialRule(specialRuleWithVehicle).then((res) => {
        visibleSpe.value = false;
      });

      tableSpeData.value = await getSpecialData(specialRuleWithVehicle);
    };

    // 添加一行表格
    const addRow = (type: string) => {
      if (type === "Special") {
        dataSourceSpe.value.push({
          bu: store.state.pgmData.businessUnit.selected,
          cityCN: "",
          cityEN: "",
          id: "",
          parameter: "",
          parameterValue: "",
          provinceCN: "",
          provinceEN: "",
          registeredTime: "",
          timeUnit: "month",
        });
      } else {
        localRegData.value.ruleAreas.push({
          cityCN: "",
          cityEN: "",
          provinceEN: "",
          provinceCN: "",
        });
      }
    };

    // 接口赋值
    const init = () => {
      getLoaclRegData();
      getAreasDate().then((res: any) => {
        areasArr.value = res;
      });
    };

    const onChaneTime = (e: Event) => {
      const value = e && (e.target as HTMLInputElement).value;
      const re = /^([1-9][0-9]*)$/;
      if (!re.test(value)) {
        (e.target as HTMLInputElement).style.border = "1px solid #f5222d";

        isSubmit.value = false;
      } else {
        (e.target as HTMLInputElement).style.border = "1px solid #d9d9d9";
        isSubmit.value = true;
      }
    };
    onMounted(() => {
      init();
      // dataSource.value = dataSource1
    });

    return {
      moment,
      dataSource,
      columns,
      showLocal,

      tableSpeData,

      visibleRegis,
      editReg,
      onSubmitReg,
      restrictedArr,
      columnsReg,
      dataSourceReg,
      addRow,
      parameterData,

      localRegData,
      onDeleteReg,

      areasArr,
      provinceValue,
      cityArr,
      cityValue,
      selectPro,
      selectCity,
      selectProSpec,
      selectCitySpec,
      selectParameter,
      onBlur,
      onFocus,
      valueArr,
      onDeleteSpec,
      onSubmitSpec,

      visibleSpe,
      editSpe,
      columnsSpe,
      dataSourceSpe,
      columnTem,
      isFlag,
      init,
      registrationObj,
      onChaneTime,
      onBlurCity,
    };
  },
});
