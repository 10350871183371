import {
  AutoTableColProps,
  APIDataProps,
  AutoTableCellProps,
  ColumnsProps,
  OptionProps,
  RowCellProps,
} from "@/types";
import { guid } from "@/utils/index";
import deepClone from "@/utils/deepClone";
export const useTableAddRowChildren = () => {
  const addNullExclude = (
    param: AutoTableCellProps,
    offerGrops: APIDataProps | APIDataProps[]
  ) => {
    if (param.children) {
      // 需要和父级一样的
      const params = deepClone(param);
      params.id = guid();
      params.parent = param.id;
      if (Array.isArray(offerGrops)) {
        params[offerGrops[0].tableCol[0].id] = { val: "Exclude" };
      } else {
        params[offerGrops.tableCol[0].id] = { val: "Exclude" };
      }
      param.children.push(params);
    }
    return param;
  };

  // 添加新的Include空数据
  const addNewInclude = (cols: AutoTableColProps[]) => {
    const param: AutoTableCellProps = {
      key: guid(),
      children: [],
      parent: "0",
    };
    cols.forEach((item: AutoTableColProps) => {
      param.id = param.key;
      param[item.id] = { isEdit: true, isAuto: false };
      // 这里会根据用户给默认值
      param[item.id].val = [];
    });
    param[cols[0].id] = { val: "Include" };
    return param;
  };
  return {
    addNewInclude,
    addNullExclude,
  };
};
