
import { ref } from 'vue'
import { AutoTableColProps, APIDataProps, AutoTableCellProps, ColumnsProps } from '@/types'
import { useStore } from 'vuex'
import { removeColumn } from '@/API/program'
import { message } from "ant-design-vue"
export const useTableDelRowCol = () => {
    const store = useStore()
    // 选择了的行
    const selectedRowKeys = ref<string[]>([])
    const onSelectChange = (selected: string[]) => {
        selectedRowKeys.value = selected
    }
    // 多行删除
    const deleteRow = (precondition: APIDataProps) => {
        selectedRowKeys.value.forEach((item: string) => {
            if (precondition.tableCell.length < 2) {
                message.error('There is only one cannot be deleted！')
                return
            }
            precondition.tableCell.splice(precondition.tableCell.findIndex((val: AutoTableCellProps) => val.id === item), 1)
        })
        selectedRowKeys.value = []
    }
    // 删除列,这个删除的offer的precondtion
    const handleDelCol = (col: ColumnsProps, item: ColumnsProps, offer: string, indexPre: number, key: string, indexIncentive?: number) => {
        const params = {
            columnId: col.dataIndex,
            table: item
        }
        removeColumn(params).then(res => {
            switch (key) {
                case 'quota':
                    store.state.pgmData.quota[indexPre] = res
                    break;
                case 'customer':
                    store.state.pgmData.customer[indexPre] = res
                    break;
                case 'precondition':
                    store.state.pgmData.offer[offer].precondition[indexPre] = res
                    break;
                case 'incentiveAmount':
                    if (indexIncentive !== undefined) store.state.pgmData.offer[offer].incentive[indexPre].groupTable[indexIncentive] = res
                    break;
                case 'vehicleLicense':
                    store.state.pgmData.offer[offer].vehicleLicense[indexPre] = res
                    break;
                default:
                    break;
            }
            
            
        })
    }
    // 删除列
    // const handleDelCol = (col: ColumnsProps, precondition: APIDataProps) => {
    //     precondition.tableCol = precondition.tableCol.filter((item: AutoTableColProps) => {
    //         console.log(item, item.group)
    //         if (item.group === col.dataIndex) {
    //             precondition.tableCell.forEach((res: AutoTableCellProps) => {
    //                 delete res[item.id]
    //             })
    //         }
    //         return item.group !== col.dataIndex
    //     })
    // }
    return {
        selectedRowKeys,
        onSelectChange,
        deleteRow,
        handleDelCol
    }
}
