
import { defineComponent, ref, onMounted, onBeforeUnmount } from "vue";
import {
  ColumnsProps,
  AutoTableCellProps,
  AutoTableColProps,
  RowCellProps,
} from "@/types";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import useEventbus from "@/hooks/claim/useEventbus";
import { guid } from "@/utils";
import { useTableOperation } from "@/hooks/Program/useTableOperation";
interface DataSourceProps extends AutoTableCellProps {
  bgcolor?: boolean;
}
interface GroupTables {
  id: string;
  name: string;
}
const groups = {
  tableCell: [],
  tableCellSelect: [],
  tableCol: [],
  tableId: "",
};
export default defineComponent({
  setup() {
    const store = useStore();
    // 组数据
    const offerGrops = ref<any>(groups);
    // 表头
    const cols = ref<AutoTableColProps[]>([]);

    // period插入一行
    // 添加新的Include空数据
    const addNewRow = (textGroup?: string) => {
      const param: AutoTableCellProps = {
        id: guid(),
      };
      cols.value.forEach((item: AutoTableColProps, index: number) => {
        // 这里的id需要和key一致
        // 和表头一致的空数据行
        param[item.id] = { val: "" };
        if (
          item.type === "SINGLE_SELECT" &&
          store.state.pgmData.eligibility.period.tableCell.length < 1
        )
          param[item.id] = { val: offerGrops.value.tableCellSelect[0].id };
        if (item.type === "CHECK") param[item.id] = { val: "false" };
        if (index === 0) param[item.id].val = "1";
        if (textGroup && index === 0) param[item.id].val = textGroup;
      });
      offerGrops.value.tableCell.push(param);
    };

    // period行有问题的，的标识
    const rowBgcolor = (record: DataSourceProps, index: number) => {
      if (record.bgcolor && index % 2 === 1) {
        return "table-color-dust table-striped";
      }
      if (index % 2 === 1) {
        return "table-striped";
      }
      if (record.bgcolor) {
        return "table-color-dust";
      }
      return "";
    };
    // 判断是否在同一Group中有同样的Attribute
    const isSameAttribute = (
      record: DataSourceProps,
      colId: string,
      colIndex: number
    ) => {
      const arrSource = offerGrops.value.tableCell;
      const groupId = offerGrops.value.tableCol[colIndex].id;
      const arrTarget = arrSource.filter(
        (item: DataSourceProps, index: number) => {
          for (let i = 0; i < arrSource.length; i++) {
            if (
              item[colId].val === arrSource[i][colId].val &&
              item[groupId].val === arrSource[i][groupId].val &&
              index !== i
            ) {
              item.bgcolor = true;
              return item;
            } else {
              item.bgcolor = false;
            }
          }
        }
      );
    };

    // period改变看是不是最后一行
    const handleChangePeriod = (record: DataSourceProps, colId: string) => {
      isSameAttribute(record, colId, 0);
    };

    // period的Group改变时，1。验证输入数字-1, 0, 正整数 2。是否有相同的
    const handleChangeGroup = (record: DataSourceProps, colId: string) => {
      // 只能输入-1, 0, 1
      if (!/(^[0-9]\d*$)/.test(record[colId].val) && record[colId].val !== -1)
        record[colId].val = "";
      isSameAttribute(record, colId, 1);
    };

    // 得到表格列colums, 和头部
    const { getColums, columns } = useTableOperation();

    // Dummy, 后端要的是字符串的boolean
    const handleChangeDummy = (rowCol: RowCellProps) => {
      if (rowCol.val === "false") {
        rowCol.val = "true";
      } else {
        rowCol.val = "false";
      }
    };
    // 点击删除
    const handleDelete = (key: string) => {
      if (offerGrops.value.tableCell && offerGrops.value.tableCell.length > 1) {
        offerGrops.value.tableCell = offerGrops.value.tableCell.filter(
          (item: AutoTableCellProps) => key !== item.id
        );
      } else {
        message.warning("This is the last one!");
      }
    };

    // 折叠
    const isFlag = ref(false);
    const { customOn } = useEventbus();

    // 父级监听子级更新数据到vuex
    customOn("updateperiod", () => {
      const rows = offerGrops.value.tableCell.filter((item: any) => {
        for (let i = 0;i < cols.value.length; i ++) {
          const col = cols.value[i]
          if (col.type === "CHECK" || i === 0) {
            continue
          }
          if (item[col.id].val) return true
        }
        return false
      })
      store.state.pgmData.eligibility.period = {
        ...offerGrops.value,
        tableCell: rows
      };
    });

    const handleFocus = (
      colId: string,
      record: DataSourceProps,
      index: number
    ): void => {
      if (offerGrops.value.tableCell.length - 1 === index) {
        addNewRow();
      }
    };

    // 初始化需要：1.得到表头，2.页面渲染用的数据 3.格式化显示用的数据，4.得到表格列 5.自动插入一列
    const init = () => {
      // 表头
      cols.value = store.state.pgmData.eligibility.period.tableCol;
      // 赋值给页面显示用
      offerGrops.value = store.state.pgmData.eligibility.period;
      // 格式化表格数据
      // 得到列格化
      getColums(offerGrops.value, true, { index: 0, width: "80px" });
    };
    onMounted(() => {
      init();
      addNewRow();
    });

    return {
      columns,
      offerGrops,
      handleDelete,
      isFlag,
      handleChangePeriod,
      handleChangeGroup,
      rowBgcolor,
      handleChangeDummy,
      handleFocus,
    };
  },
});
