import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "pre-con" }
const _hoisted_2 = {
  key: 0,
  class: "table-box-scroll m-scroll b-c-gray p-r",
  style: {"margin":"10px"}
}
const _hoisted_3 = {
  key: 0,
  class: "no-edit-div"
}
const _hoisted_4 = { key: 8 }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  key: 13,
  class: "i-e-s"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = {
  key: 1,
  class: "other-wise"
}
const _hoisted_15 = {
  key: 0,
  class: "width-200",
  style: {"margin-left":"60px"}
}
const _hoisted_16 = { key: 1 }
const _hoisted_17 = {
  key: 2,
  class: "width-200"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_v_nodes = _resolveComponent("v-nodes")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("i", {
      class: "font_family icon-add-m m-l-10 f20 cursor-p delete-icon",
      style: {"right":"80px"},
      title: "Measure",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleInsetMeasure(_ctx.precondition)))
    }),
    _createElementVNode("i", {
      class: "font_family icon-Delete-icon-L m-l-10 delete-icon f20 cursor-p",
      title: "Delete",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteRow(_ctx.precondition)))
    }),
    (_ctx.precondition.tableCol.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.$store.state.pgmData.readOnly)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3))
            : _createCommentVNode("", true),
          _createVNode(_component_a_table, {
            rowClassName: (record, index) => (index % 2 === 1 ? 'table-striped' : null),
            pagination: false,
            rowKey: "id",
            class: "self-table-input",
            "row-selection": {
                        selectedRowKeys: _ctx.selectedRowKeys,
                        onChange: _ctx.onSelectChange
                    },
            columns: _ctx.columns,
            "data-source": _ctx.precondition.tableCell
          }, _createSlots({ _: 2 }, [
            _renderList(_ctx.precondition.tableCol, (col) => {
              return {
                name: col.id,
                fn: _withCtx(({ record }) => [
                  (col.type === 'DATE')
                    ? (_openBlock(), _createBlock(_component_a_date_picker, {
                        key: 0,
                        class: "width-200",
                        onFocus: ($event: any) => (_ctx.handleFocusT(record)),
                        value: record[col.id].val,
                        "onUpdate:value": ($event: any) => ((record[col.id].val) = $event),
                        valueFormat: "YYYY-MM-DD",
                        format: "YYYY/MM/DD",
                        placeholder: "DATE"
                      }, null, 8, ["onFocus", "value", "onUpdate:value"]))
                    : _createCommentVNode("", true),
                  (col.type === 'TEXT')
                    ? (_openBlock(), _createBlock(_component_a_input, {
                        key: 1,
                        class: "width-200",
                        value: record[col.id].val,
                        "onUpdate:value": ($event: any) => ((record[col.id].val) = $event),
                        onFocus: ($event: any) => (_ctx.handleFocusT(record)),
                        title: record[col.id].val,
                        disabled: record[col.id].isEdit ? false : true
                      }, null, 8, ["value", "onUpdate:value", "onFocus", "title", "disabled"]))
                    : _createCommentVNode("", true),
                  (col.type === 'PERCENT')
                    ? (_openBlock(), _createBlock(_component_a_input_number, {
                        key: 2,
                        class: "width-200",
                        value: record[col.id].val,
                        "onUpdate:value": ($event: any) => ((record[col.id].val) = $event),
                        formatter: value => `${value}%`,
                        parser: value => value.replace('%', ''),
                        precision: 2,
                        min: 0,
                        onFocus: ($event: any) => (_ctx.handleFocusT(record))
                      }, null, 8, ["value", "onUpdate:value", "formatter", "parser", "onFocus"]))
                    : _createCommentVNode("", true),
                  (col.type === 'INTEGER')
                    ? (_openBlock(), _createBlock(_component_a_input_number, {
                        key: 3,
                        class: "width-200",
                        value: record[col.id].val,
                        "onUpdate:value": ($event: any) => ((record[col.id].val) = $event),
                        precision: 0,
                        min: 0,
                        onFocus: ($event: any) => (_ctx.handleFocusT(record))
                      }, null, 8, ["value", "onUpdate:value", "onFocus"]))
                    : _createCommentVNode("", true),
                  (col.type === 'M_PERCENT' && col.group)
                    ? (_openBlock(), _createBlock(_component_a_input_number, {
                        key: 4,
                        class: "width-200",
                        value: record[col.id].val,
                        "onUpdate:value": ($event: any) => ((record[col.id].val) = $event),
                        formatter: value => `${value}%`,
                        parser: value => value.replace('%', ''),
                        precision: 2,
                        onChange: ($event: any) => (_ctx.handleChangeAutoCal(col, record, _ctx.precondition)),
                        min: 0
                      }, null, 8, ["value", "onUpdate:value", "formatter", "parser", "onChange"]))
                    : _createCommentVNode("", true),
                  (col.type === 'M_DECIMAL' && col.group)
                    ? (_openBlock(), _createBlock(_component_a_input_number, {
                        key: 5,
                        class: "width-200",
                        value: record[col.id].val,
                        "onUpdate:value": ($event: any) => ((record[col.id].val) = $event),
                        disabled: record[col.id].isEdit ? false : true,
                        onChange: ($event: any) => (_ctx.handleChangeAutoCal(col, record, _ctx.precondition)),
                        formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                        parser: value => value.replace(/\$\s?|(,*)/g, ''),
                        min: 0,
                        precision: 2
                      }, null, 8, ["value", "onUpdate:value", "disabled", "onChange", "formatter", "parser"]))
                    : _createCommentVNode("", true),
                  (col.type === 'M_INTEGER' && col.group)
                    ? (_openBlock(), _createBlock(_component_a_input_number, {
                        key: 6,
                        class: "width-200",
                        value: record[col.id].val,
                        "onUpdate:value": ($event: any) => ((record[col.id].val) = $event),
                        disabled: record[col.id].isEdit ? false : true,
                        onChange: ($event: any) => (_ctx.handleChangeAutoCal(col, record, _ctx.precondition)),
                        formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                        parser: value => value.replace(/\$\s?|(,*)/g, ''),
                        min: 0,
                        precision: 0
                      }, null, 8, ["value", "onUpdate:value", "disabled", "onChange", "formatter", "parser"]))
                    : _createCommentVNode("", true),
                  (col.type === 'M_TEXT' && col.group)
                    ? (_openBlock(), _createBlock(_component_a_input, {
                        key: 7,
                        class: "width-200",
                        value: record[col.id].val,
                        "onUpdate:value": ($event: any) => ((record[col.id].val) = $event),
                        disabled: record[col.id].isEdit ? false : true
                      }, null, 8, ["value", "onUpdate:value", "disabled"]))
                    : _createCommentVNode("", true),
                  (col.type === 'EMBED_SELECT')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        (_ctx.getFlag(col, 'M_PERCENT'))
                          ? (_openBlock(), _createBlock(_component_a_input_number, {
                              key: 0,
                              class: "width-200",
                              value: record[col.id].val,
                              "onUpdate:value": ($event: any) => ((record[col.id].val) = $event),
                              formatter: value => `${value}%`,
                              parser: value => value.replace('%', ''),
                              disabled: record[col.id].isEdit ? false : true,
                              min: 0,
                              precision: 2
                            }, null, 8, ["value", "onUpdate:value", "formatter", "parser", "disabled"]))
                          : (_ctx.getFlag(col, 'M_SINGLE_SELECT'))
                            ? (_openBlock(), _createBlock(_component_a_select, {
                                key: 1,
                                class: "width-200",
                                value: record[col.id].val,
                                "onUpdate:value": ($event: any) => ((record[col.id].val) = $event),
                                disabled: record[col.id].isEdit ? false : true
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.HLOptions, (ite) => {
                                    return (_openBlock(), _createBlock(_component_a_select_option, {
                                      key: ite.id
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(ite.display), 1)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  }), 128))
                                ]),
                                _: 2
                              }, 1032, ["value", "onUpdate:value", "disabled"]))
                            : (_ctx.getFlag(col, 'M_INTEGER'))
                              ? (_openBlock(), _createBlock(_component_a_input_number, {
                                  key: 2,
                                  class: "width-200",
                                  formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                                  parser: value => value.replace(/\$\s?|(,*)/g, ''),
                                  value: record[col.id].val,
                                  "onUpdate:value": ($event: any) => ((record[col.id].val) = $event),
                                  disabled: record[col.id].isEdit ? false : true,
                                  min: 0,
                                  precision: 0
                                }, null, 8, ["formatter", "parser", "value", "onUpdate:value", "disabled"]))
                              : (_ctx.getFlag(col, 'M_DECIMAL'))
                                ? (_openBlock(), _createBlock(_component_a_input_number, {
                                    key: 3,
                                    class: "width-200",
                                    formatter: value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                                    parser: value => value.replace(/\$\s?|(,*)/g, ''),
                                    value: record[col.id].val,
                                    "onUpdate:value": ($event: any) => ((record[col.id].val) = $event),
                                    disabled: record[col.id].isEdit ? false : true,
                                    min: 0,
                                    precision: 2
                                  }, null, 8, ["formatter", "parser", "value", "onUpdate:value", "disabled"]))
                                : (_ctx.getFlag(col, 'M_TEXT'))
                                  ? (_openBlock(), _createBlock(_component_a_input, {
                                      key: 4,
                                      class: "width-200",
                                      value: record[col.id].val,
                                      "onUpdate:value": ($event: any) => ((record[col.id].val) = $event),
                                      disabled: record[col.id].isEdit ? false : true
                                    }, null, 8, ["value", "onUpdate:value", "disabled"]))
                                  : (_openBlock(), _createBlock(_component_a_input_number, {
                                      key: 5,
                                      class: "width-200",
                                      value: record[col.id].val,
                                      "onUpdate:value": ($event: any) => ((record[col.id].val) = $event),
                                      min: 0,
                                      disabled: record[col.id].isEdit ? false : true
                                    }, null, 8, ["value", "onUpdate:value", "disabled"]))
                      ]))
                    : _createCommentVNode("", true),
                  (col.type === 'MULTI_SELECT_MYEX')
                    ? (_openBlock(), _createBlock(_component_a_select, {
                        key: 9,
                        value: record[col.id].val,
                        "onUpdate:value": ($event: any) => ((record[col.id].val) = $event),
                        onFocus: ($event: any) => (_ctx.handleFocus(col.id, record, _ctx.precondition, _ctx.autoInsetRow, 'precondition')),
                        onChange: ($event: any) => (_ctx.handleChangePre(col.id, record)),
                        class: "width-200",
                        mode: "multiple",
                        "not-found-content": _ctx.fetching ? undefined : 'No Data'
                      }, _createSlots({
                        dropdownRender: _withCtx(({ menuNode: menu }) => [
                          _createVNode(_component_v_nodes, { vnodes: menu }, null, 8, ["vnodes"]),
                          _createVNode(_component_a_divider, { style: {"margin":"4px 0"} }),
                          _createElementVNode("div", {
                            onMousedown: _cache[2] || (_cache[2] = e => e.preventDefault()),
                            class: "all-check",
                            onClick: ($event: any) => (_ctx.handleAllCheck(_ctx.index, col.id, record))
                          }, _toDisplayString(_ctx.checked ? '取消全选' : '全选'), 41, _hoisted_5)
                        ]),
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.precondition.tableCellSelect, (ite) => {
                            return (_openBlock(), _createBlock(_component_a_select_option, { key: ite }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(ite), 1)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 2
                      }, [
                        (_ctx.fetching)
                          ? {
                              name: "notFoundContent",
                              fn: _withCtx(() => [
                                _createVNode(_component_a_spin, { size: "small" })
                              ])
                            }
                          : undefined
                      ]), 1032, ["value", "onUpdate:value", "onFocus", "onChange", "not-found-content"]))
                    : _createCommentVNode("", true),
                  (col.type === 'MULTI_SELECT' && col.display !== 'Model Name')
                    ? (_openBlock(), _createBlock(_component_a_select, {
                        key: 10,
                        value: record[col.id].val,
                        "onUpdate:value": ($event: any) => ((record[col.id].val) = $event),
                        onFocus: ($event: any) => (_ctx.handleFocus(col.id, record, _ctx.precondition, _ctx.autoInsetRow, 'precondition')),
                        onChange: ($event: any) => (_ctx.handleFocusT(record)),
                        class: "width-200",
                        mode: "multiple",
                        "not-found-content": _ctx.fetching ? undefined : 'No Data'
                      }, _createSlots({
                        dropdownRender: _withCtx(({ menuNode: menu }) => [
                          _createVNode(_component_v_nodes, { vnodes: menu }, null, 8, ["vnodes"]),
                          _createVNode(_component_a_divider, { style: {"margin":"4px 0"} }),
                          _createElementVNode("div", {
                            onMousedown: _cache[3] || (_cache[3] = e => e.preventDefault()),
                            class: "all-check",
                            onClick: ($event: any) => (_ctx.handleAllCheck(_ctx.index, col.id, record))
                          }, _toDisplayString(_ctx.checked ? '取消全选' : '全选'), 41, _hoisted_6)
                        ]),
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.precondition.tableCellSelect, (ite) => {
                            return (_openBlock(), _createBlock(_component_a_select_option, { key: ite }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(ite), 1)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 2
                      }, [
                        (_ctx.fetching)
                          ? {
                              name: "notFoundContent",
                              fn: _withCtx(() => [
                                _createVNode(_component_a_spin, { size: "small" })
                              ])
                            }
                          : undefined
                      ]), 1032, ["value", "onUpdate:value", "onFocus", "onChange", "not-found-content"]))
                    : _createCommentVNode("", true),
                  (col.type === 'MULTI_SELECT' && col.display === 'Model Name')
                    ? (_openBlock(), _createBlock(_component_a_select, {
                        key: 11,
                        value: record[col.id].val,
                        "onUpdate:value": ($event: any) => ((record[col.id].val) = $event),
                        onFocus: ($event: any) => (_ctx.handleFocus(col.id, record, _ctx.precondition, _ctx.autoInsetRow, 'precondition')),
                        onChange: ($event: any) => (_ctx.handleFocusT(record)),
                        class: "model-name-width",
                        mode: "multiple",
                        "not-found-content": _ctx.fetching ? undefined : 'No Data'
                      }, _createSlots({
                        dropdownRender: _withCtx(({ menuNode: menu }) => [
                          _createVNode(_component_v_nodes, { vnodes: menu }, null, 8, ["vnodes"]),
                          _createVNode(_component_a_divider, { style: {"margin":"4px 0"} }),
                          _createElementVNode("div", {
                            onMousedown: _cache[4] || (_cache[4] = e => e.preventDefault()),
                            class: "all-check",
                            onClick: ($event: any) => (_ctx.handleAllCheck(_ctx.index, col.id, record))
                          }, _toDisplayString(_ctx.checked ? '取消全选' : '全选'), 41, _hoisted_7)
                        ]),
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.precondition.tableCellSelect, (ite) => {
                            return (_openBlock(), _createBlock(_component_a_select_option, { key: ite }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(ite), 1)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 2
                      }, [
                        (_ctx.fetching)
                          ? {
                              name: "notFoundContent",
                              fn: _withCtx(() => [
                                _createVNode(_component_a_spin, { size: "small" })
                              ])
                            }
                          : undefined
                      ]), 1032, ["value", "onUpdate:value", "onFocus", "onChange", "not-found-content"]))
                    : _createCommentVNode("", true),
                  (col.type === 'SINGLE_SELECT')
                    ? (_openBlock(), _createBlock(_component_a_select, {
                        key: 12,
                        value: record[col.id].val,
                        "onUpdate:value": ($event: any) => ((record[col.id].val) = $event),
                        onFocus: ($event: any) => (_ctx.handleFocus(col.id, record, _ctx.precondition, _ctx.autoInsetRow, 'precondition')),
                        class: "width-200",
                        "not-found-content": _ctx.fetching ? undefined : 'No Data'
                      }, _createSlots({
                        dropdownRender: _withCtx(({ menuNode: menu }) => [
                          _createVNode(_component_v_nodes, { vnodes: menu }, null, 8, ["vnodes"]),
                          _createVNode(_component_a_divider, { style: {"margin":"4px 0"} }),
                          _createElementVNode("div", {
                            onMousedown: _cache[5] || (_cache[5] = e => e.preventDefault()),
                            class: "all-check",
                            onClick: ($event: any) => (_ctx.handleAllCheck(_ctx.index, col.id, record))
                          }, _toDisplayString(_ctx.checked ? '取消全选' : '全选'), 41, _hoisted_8)
                        ]),
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.precondition.tableCellSelect, (ite) => {
                            return (_openBlock(), _createBlock(_component_a_select_option, { key: ite }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(ite), 1)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 2
                      }, [
                        (_ctx.fetching)
                          ? {
                              name: "notFoundContent",
                              fn: _withCtx(() => [
                                _createVNode(_component_a_spin, { size: "small" })
                              ])
                            }
                          : undefined
                      ]), 1032, ["value", "onUpdate:value", "onFocus", "not-found-content"]))
                    : _createCommentVNode("", true),
                  (col.type === 'CHECK')
                    ? (_openBlock(), _createElementBlock("p", _hoisted_9, [
                        _createElementVNode("i", {
                          class: _normalizeClass(["font_family", record[col.id].val === 'false' ? 'icon-X color-r' : 'icon-icon-test color-blue']),
                          onClick: ($event: any) => (_ctx.handleChangeDummy(record[col.id]))
                        }, null, 10, _hoisted_10)
                      ]))
                    : _createCommentVNode("", true)
                ])
              }
            }),
            _renderList(_ctx.showDelArr, (delItem) => {
              return {
                name: delItem.slots.title,
                fn: _withCtx(() => [
                  _createElementVNode("span", null, [
                    _createTextVNode(_toDisplayString(delItem.slots.display) + " ", 1),
                    _createElementVNode("i", {
                      class: "font_family icon-shanchu del-color m-l-10 cursor-p",
                      onClick: ($event: any) => (_ctx.handleDelCol(delItem, _ctx.precondition, _ctx.offer, _ctx.indexPre, _ctx.keyWord))
                    }, null, 8, _hoisted_11)
                  ])
                ])
              }
            }),
            _renderList(_ctx.showMeasureArr, (measureItem) => {
              return {
                name: measureItem.slots.title,
                fn: _withCtx(() => [
                  _createElementVNode("span", null, [
                    _createElementVNode("i", {
                      class: "font_family icon-shanchu del-color m-r-10 cursor-p",
                      onClick: ($event: any) => (_ctx.handleDelCol(measureItem, _ctx.precondition, _ctx.offer, _ctx.indexPre, _ctx.keyWord))
                    }, null, 8, _hoisted_12)
                  ]),
                  _createVNode(_component_a_select, {
                    value: measureItem.slots.measure,
                    "onUpdate:value": ($event: any) => ((measureItem.slots.measure) = $event),
                    style: {"width":"175px"},
                    placeholder: "(Select Measure)",
                    onChange: ($event: any) => (_ctx.handleSelectMeasure(measureItem.slots.col, measureItem.slots.measure, _ctx.precondition))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.measureSelectData, (ite) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: ite.id,
                          title: ite.id
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(ite.display), 1)
                          ]),
                          _: 2
                        }, 1032, ["title"]))
                      }), 128))
                    ]),
                    _: 2
                  }, 1032, ["value", "onUpdate:value", "onChange"])
                ])
              }
            }),
            _renderList(_ctx.showMeasureSecArr, (measureSec, secIndex) => {
              return {
                name: measureSec.slots.title,
                fn: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(measureSec.slots.measure), 1),
                  (_ctx.showMeasureSecArr.length - 1 === secIndex)
                    ? (_openBlock(), _createElementBlock("i", {
                        key: 0,
                        class: "font_family icon-tianjia11 del-color m-l-10 cursor-p",
                        onClick: ($event: any) => (_ctx.handleAddMeasureCol(measureSec.slots.col, secIndex, _ctx.precondition))
                      }, null, 8, _hoisted_13))
                    : _createCommentVNode("", true)
                ])
              }
            })
          ]), 1032, ["rowClassName", "row-selection", "columns", "data-source"]),
          (!!_ctx.precondition.otherwiseTableCell > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                (!!_ctx.precondition.otherwiseTableCell.length > 0)
                  ? (_openBlock(), _createBlock(_component_a_table, {
                      key: 0,
                      pagination: false,
                      bordered: "",
                      showHeader: false,
                      rowKey: "id",
                      columns: _ctx.columns,
                      "data-source": _ctx.precondition.otherwiseTableCell
                    }, _createSlots({ _: 2 }, [
                      _renderList(_ctx.precondition.tableCol, (col, index) => {
                        return {
                          name: col.id,
                          fn: _withCtx(({ record }) => [
                            (index === 0)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_15, "Otherwise"))
                              : _createCommentVNode("", true),
                            (index === _ctx.precondition.tableCol.length - 1)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                  (col.selected === 'PERCENT')
                                    ? (_openBlock(), _createBlock(_component_a_input_number, {
                                        key: 0,
                                        class: "width-200",
                                        value: record[col.id].val,
                                        "onUpdate:value": ($event: any) => ((record[col.id].val) = $event),
                                        formatter: value => `${value}%`,
                                        parser: value => value.replace('%', ''),
                                        precision: 2,
                                        onChange: _ctx.handleOtherwise
                                      }, null, 8, ["value", "onUpdate:value", "formatter", "parser", "onChange"]))
                                    : (_openBlock(), _createBlock(_component_a_input, {
                                        key: 1,
                                        class: "width-200",
                                        value: record[col.id].val,
                                        "onUpdate:value": ($event: any) => ((record[col.id].val) = $event)
                                      }, null, 8, ["value", "onUpdate:value"]))
                                ]))
                              : (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(record[col.id].val), 1))
                          ])
                        }
                      })
                    ]), 1032, ["columns", "data-source"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}