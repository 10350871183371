
import { defineComponent, ref, computed, watch, nextTick } from "vue";
import useEventbus from "@/hooks/claim/useEventbus";
import moment, { Moment } from "moment";
import { useStore } from "vuex";
export default defineComponent({
  setup() {
    const store = useStore();

    const columns = [
      {
        title: "Attribute",
        width: 200,
        slots: { customRender: "attribute" },
      },
      {
        title: "From",
        width: 200,
        slots: { customRender: "budgetPeriodFrom" },
      },
      {
        title: "To",
        width: 200,
        slots: { customRender: "budgetPeriodTo" },
      },
      {},
    ];

    const budgetPeriod = ref<any>([
      {
        id: 1,
        attribute: "Budget Period",
        budgetPeriodFrom: null,
        budgetPeriodTo: null,
      },
    ]);

    // period
    const period = computed(() => {
      return store.state.pgmData?.eligibility?.period;
    });

    //* Period栏选择的最小的from值的年+月份小于当前年+月，则不显示Budget Period
    const budgetShowFlag = ref(false);

    // 判断两个日期是否差1个月
    const diffOneMonth = (startTime: string) => {
      const endTime = moment().format("YYYY/MM");
      const diff = moment(endTime, "YYYY/MM").diff(
        moment(startTime, "YYYY/MM"),
        "months"
      );
      if (diff > 0) {
        return true;
      } else {
        return false;
      }
    };

    const setFlag = (fromPeriods: string[]) => {
      let flag = false;
      for (let i = 0; i < fromPeriods.length; i++) {
        flag = diffOneMonth(fromPeriods[i]);
        if (flag) break;
      }
      budgetShowFlag.value = flag;
    };

    const initBudgetData = () => {
      budgetPeriod.value[0].budgetPeriodFrom = null;
      budgetPeriod.value[0].budgetPeriodTo = null;
    };

    const budgetPriodFrom = computed(
      () => store.state.pgmData.budgetPeriodFrom
    );

    watch(
      budgetPriodFrom,
      (newFromVal) => {
        if (newFromVal !== null && newFromVal !== undefined) {
          budgetShowFlag.value = true;
          nextTick(() => {
            budgetPeriod.value[0].budgetPeriodFrom =
              store.state.pgmData.budgetPeriodFrom;
            budgetPeriod.value[0].budgetPeriodTo =
              store.state.pgmData.budgetPeriodTo;
          });
        } else {
          if (store.state.pgmData.programStatusDisplay === "Saved") {
            const fromPeriods = store.state.pgmData.eligibility.period.tableCell
              .map((per: any) => {
                return per.FROM.val;
              })
              .filter((item: any) => item !== "");
            setFlag(fromPeriods);
          }
        }
      },
      { immediate: true }
    );

    watch(
      period,
      (newPeriod: any) => {
        if (
          store.state.pgmData.programStatusDisplay === "Saved" ||
          store.state.pgmData.programStatusDisplay === null
        ) {
          const fromPeriods = newPeriod.tableCell.map((per: any) => {
            return per.FROM.val;
          });
          setFlag(fromPeriods);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    // 收起budget period时，重置其值
    watch(
      budgetShowFlag,
      (newVal: boolean) => {
        if (!newVal) {
          initBudgetData();
        }
      },
      { immediate: true }
    );
    // 折叠
    const isFlag = ref(false);

    const disabledBudgetPeriodFrom = (current: Moment) => {
      return current && current < moment().subtract(1, "months").endOf("month");
    };
    const disabledBudgetPeriodTo = (current: Moment, from: any) => {
      return current && current < moment(from).endOf("day");
    };
    // 父级监听子级更新数据到vuex
    const { customOn } = useEventbus();
    customOn("updateBudgetPeriod", () => {
      store.state.pgmData.budgetPeriodFrom =
        budgetPeriod.value[0].budgetPeriodFrom;
      store.state.pgmData.budgetPeriodTo = budgetPeriod.value[0].budgetPeriodTo;
      store.state.pgmData.budgetShowFlag = budgetShowFlag.value;
    });

    return {
      isFlag,
      budgetShowFlag,
      columns,
      budgetPeriod,
      disabledBudgetPeriodFrom,
      disabledBudgetPeriodTo,
    };
  },
});
