import { ref } from "vue"
const useYearMonthDay = () => {
    const yearList = ref<number[]>([])
    
    for (let i = 0; i < 79; i++) {
        yearList.value.push(i + 2021)
    }
    const quarterList = ['Q1', 'Q2', 'Q3', 'Q4']
    // 定义月份
    const monthList = ref({})
    monthList.value['Q1'] = ['01', '02', '03']
    monthList.value['Q2'] = ['04', '05', '06']
    monthList.value['Q3'] = ['07', '08', '09']
    monthList.value['Q4'] = ['10', '11', '12']
    return {
        yearList,
        quarterList,
        monthList,
    }
}


export default useYearMonthDay