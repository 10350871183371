
import { defineComponent, PropType } from 'vue'
import { ComSettingProps } from '../types'
const columns = [
    {
        title: 'Column Name',
        dataIndex: 'colDisplay',
    },
    {
        title: 'Column Sequence',
        dataIndex: 'colSequence',
        slots: { customRender: 'colSequence'}
    }
]
export default defineComponent({
    props: {
        comvisible: Boolean,
        comData: {
            default: () => [],
            type: Array as PropType<ComSettingProps[]>
        }
    },
    name: 'ComSetting',
    setup(props, context) {
        const handleCancel = () => {
          context.emit('update:comvisible', false)
        }
        const handleSubmit = () => {
            context.emit('update:comvisible', false)
        }
         return {
             columns,
             handleCancel,
             handleSubmit
         }

    }
 });
