import { ref } from 'vue'
import { message } from "ant-design-vue"
import { APIDataEditProps, APIDataProps } from '@/types'
export const useEditGroupTitle = () => {
    // 是否可以编辑incentiveTitle标题编辑标题
    const isEdit = ref(false)
    // 验证标题是否有重复, 请求接口
    const valateGroupTitle = (group: APIDataProps[] , val: APIDataEditProps) => {
        const arr = group.filter((item: APIDataEditProps) => item.tableName === val.tableName)
        if (arr.length > 1) {
            message.error('Name cannot be repeated！')
            val.tableName = ''
            return;
        }
        // 如果没有重复，才走下面的代码
        val.isEdit = !val.isEdit
    }
    return {
        isEdit,
        valateGroupTitle
    }
}