import { ref, reactive, nextTick, watch, Ref } from 'vue';
import { useStore } from 'vuex';
import {
  getAvaiDesData,
  getFinacDesData,
  getFlDesData,
  getEqFlDesData,
  getAgDesData,
  getChooseCalculator
} from '@/API/program';
import { MaintainChildren, MaintainCellProps, MaintainColProps } from './../types';
import { ColmunsProps, ColmunsChildrenProps } from '@/API/types';
import deepClone from '@/utils/deepClone';
import Sortable from 'sortablejs';
interface StatusParams {
  typeClassCode: string;
  modelYear: string;
  model: string;
  excelBrand: string;
}

const findColLength = (data: MaintainColProps[]): number => {
  const colArr: string[] = [];
  const childrenArr: any[] = [];
  data.forEach((col) => {
    for (const key in col) {
      if (key === 'children') {
        childrenArr.push(col[key]);
      }
    }
  });
  childrenArr.flat().forEach((item) => {
    colArr.push(item.dataIndex);
  });
  return childrenArr.flat().length;
};

const deWeight = (arr: any[]) => {
  // const obj = {}
  const obj = new Map();
  const result: any[] = [];
  arr.forEach((ele) => {
    const key = JSON.stringify(ele);
    if (!obj.get(key)) {
      result.push(ele);
      obj.set(key, true);
    }
  });
  return result;
};

// 去重
const handleSet = (ary: any[], sheetType = 'Finacing') => {
  ary.forEach((item) => {
    item.financingDesignList = deWeight(item.financingDesignList);
  });
  return ary;
};

// 获取brand
const findBrand = (data: any): string => {
  const brancdArr: string[] = [];
  data.forEach((vehicle: any) => {
    vehicle.tableCell.forEach((item: any) => {
      if ('Vehicle.brand' in item) {
        brancdArr.push(item['Vehicle.brand'].val);
      } else {
        brancdArr.push(item['1201'].val);
      }
    });
  });
  const arr = brancdArr.flat();
  return Array.from(new Set(arr))[0];
};

// 基础的设置
export const useVerilfy = () => {
  const store = useStore();

  // 获取选择的brand
  const checkBrandSole = (): string[] => {
    const vehicleArr = store.state.pgmData.eligibility.vehicle;
    const brancdArr: string[] = [];
    vehicleArr.forEach((vehicle: any) => {
      vehicle.tableCell.forEach((item: any) => {
        if ('Vehicle.brand' in item) {
          brancdArr.push(item['Vehicle.brand'].val);
        } else {
          brancdArr.push(item['1201'].val);
        }
      });
    });
    const arr = brancdArr.flat();
    return Array.from(new Set(arr));
  };

  // 选择FA后。 判断对应F/A application date是否存在
  const checkFaDate = () => {
    const periodArr = store.state.pgmData.eligibility.period.tableCell;
    const key = store.state.pgmData.eligibility.period.tableCellSelect.filter(
      (item: { display: string }) => item.display === 'F/A application date'
    )[0].id;
    let toDate = '';
    let fromDate = '';
    let isCheckFaDate = false;
    periodArr.forEach((item: any) => {
      if (item.ATTR.val === key && item.TO.val && item.FROM.val) {
        toDate = item.TO.val;
        fromDate = item.FROM.val;
        isCheckFaDate = true;
      }
    });
    return { toDate, fromDate, isCheckFaDate };
  };

  // 获取右侧宽度
  const getMaxWidth = () => {
    const mainWidth = ref<number>(0);
    try {
      const resizeHandler = (): void => {
        const clientWidth: number = document.body.clientWidth;
        const sideNavWidth: number = (document.querySelector('.side-nav-left') as HTMLDivElement).offsetWidth;
        const W = clientWidth - sideNavWidth;
        mainWidth.value = W;
      };
      window.addEventListener('resize', resizeHandler);
      resizeHandler();
    } catch (e) {
      console.log(e);
    }
    return mainWidth.value;
  };
  return {
    checkBrandSole,
    getMaxWidth,
    checkFaDate,
    handleSet
  };
};

// 获计算器数据
export const useDatasource = (
  calculatorTable: any,
  originData: Ref,
  expKeys: Ref<string[]>,
  selectedKeys: Ref<any>
) => {
  const store = useStore();
  // 查找parent对象 通过id
  const findParent = (id: string, data: MaintainCellProps[]) => {
    const arr = [];
    for (const obj of data) {
      if (obj.parentId === id && obj.parentId !== obj.id) {
        arr.push(obj);
      }
    }
    return arr;
  };

  // 添加计算器列表的children的字段
  const addChildren = (data: MaintainCellProps[]): MaintainCellProps[] => {
    let arr: MaintainCellProps[] = deepClone(data);
    arr.forEach((item: MaintainCellProps) => {
      if (item.parentId === item.id) {
        item['children'] = findParent(item.id, arr);
      }
    });
    arr = arr.filter((item: MaintainCellProps) => item.id === item.parentId);
    return arr;
  };

  // 判断fa setting的是否勾选了model并勾选typeclass或者model year
  const isCheckModel = (data: MaintainColProps[]): boolean => {
    let modelBool = false;
    let typeBool = false;
    // let yearBool = false
    data[0].children?.forEach((item: MaintainColProps) => {
      if (item.display === 'Model' && item.flag) {
        modelBool = true;
      }
      if (item.display === 'Type Class' && item.flag) {
        typeBool = true;
      }
      // if (item.display === 'Model Year' && item.flag) {
      //    yearBool = true
      // }
    });

    let isFold = false;
    if (modelBool && typeBool) {
      isFold = true;
    }
    // if (!modelBool) {
    //     isFold = false
    // }
    return isFold;
  };
  // 是否折叠
  let isFold = false;
  // 获取children中的数据，进行配置
  const deployChild = (arr: MaintainChildren[], type?: string): ColmunsChildrenProps[] => {
    const children: ColmunsChildrenProps[] = [];
    arr.forEach((item: MaintainChildren) => {
      if (type === 'left') {
        if (item.flag) {
          if (item.display === 'Model' && !isFold) {
            children.push({
              title: item.display,
              dataIndex: item.id,
              key: item.id,
              width: 260,
              fixed: 'left',
              align: 'center'
            });
          } else {
            children.push({
              title: item.display,
              dataIndex: item.id,
              key: item.id,
              width: 150,
              fixed: 'left',
              align: 'center'
            });
          }
        }
      } else if (type === 'right') {
        children.push({
          title: item.display,
          dataIndex: item.id,
          key: item.id,
          width: 150,
          fixed: 'right',
          align: 'center'
        });
      } else if (type === 'icon') {
        children.push({
          title: item.display,
          dataIndex: item.id,
          key: item.id,
          slots: { customRender: 'icon' },
          width: 160,
          align: 'center'
        });
      } else {
        children.push({
          title: item.display,
          dataIndex: item.id,
          key: item.id,
          align: 'center'
        });
      }
    });
    return children;
  };

  const configColumns = (data: MaintainColProps[]) => {
    let columns: any = [];
    const length = findColLength(data);
    data.forEach((item: MaintainColProps, index: number) => {
      //判断是否是第一个 最后一个，并是否有中间滚动的部分
      if (index === 0 && data.length > 2 && length > 6) {
        columns = columns.concat(deployChild(item.children as MaintainChildren[], 'left'));
      } else if (index === data.length - 1 && data.length > 2 && length > 6) {
        columns = columns.concat(deployChild(item.children as MaintainChildren[], 'right'));
      } else if ((index === 0 || index === data.length - 1) && length <= 6) {
        columns = columns.concat(deployChild(item.children as MaintainChildren[]));
      } else {
        columns.push({
          title: item.display,
          children: deployChild(item.children as MaintainChildren[], 'icon'),
          align: 'center'
        });
      }
      if (data.length === 2) {
        columns.splice(1, 0, {
          title: '',
          dataIndex: ''
        });
      }
    });
    return columns;
  };

  let dataContent: any;

  // 获取计算器列表
  const getTableData = async (query: any, hasChosenUpgrade: boolean, type = '') => {
    if (Object.keys(store.state.pgmData).length > 0) {
      const chooseParams = {
        programId: store.state.pgmData.programId
      };
      const { parents, master } = await getChooseCalculator({
        params: chooseParams,
        upGrade: hasChosenUpgrade
      });
      return new Promise((resolve, reject) => {
        const brand = findBrand(store.state.pgmData.eligibility.vehicle);
        const params = store.state.pgmData;
        const configParams = {
          programId: store.state.pgmData.programId,
          brand,
          ...query
        };
        getAvaiDesData(configParams, params)
          .then((res) => {
            dataContent = addChildren(res.maintain.tableCell);
            originData.value = res.maintain.tableCell;
            res.maintain.tableCol.sort((x: any, y: any) => {
              return x.id - y.id;
            });
            isFold = isCheckModel(res.maintain.tableCol);
            if (!type) {
              expKeys.value = parents;
              selectedKeys.value = master;
            }
            resolve(res);
          })
          .catch((e) => {
            reject(e);
          });
      });
    }
  };

  // 折叠时间处理
  const onExpand = (expanded: boolean, record: any) => {
    if (expanded) {
      // 设置展开窗Key，代表展开操作
      expKeys.value.push(record.id);
      expKeys.value = Array.from(new Set(expKeys.value));
    } else {
      // 代表折叠操作
      if (expKeys.value.length) {
        nextTick(() => {
          expKeys.value = expKeys.value.filter((v) => {
            return v !== record.id;
          });
        });
      }
    }
  };

  // 判断数组2在数组1中的不同的元素
  const findDiff = (arr1: string[], arr2: string[]): string => {
    const set1 = new Set(arr1);
    const set2 = new Set(arr2);
    const subset = [];
    if (set1.size > set2.size) {
      for (const item of set1) {
        if (!set2.has(item)) {
          subset.push(item);
        }
      }
    } else {
      for (const item of set2) {
        if (!set1.has(item)) {
          subset.push(item);
        }
      }
    }

    return subset[0];
  };

  let initKeysLength = 0;
  // 根据选择的是否为父级来设置选择子级
  const checkAllEvent = (selectedRowKeys: string[]) => {
    if (selectedKeys.value.length) {
      const newSelect = [];
      for (let i = 0; i < selectedKeys.value.length; i++) {
        if (newSelect.indexOf(selectedKeys.value[i]) === -1) {
          newSelect.push(selectedKeys.value[i]);
        }
      }
      selectedKeys.value = newSelect;
    }

    if (selectedRowKeys.length) {
      const newSelect = [];
      for (let i = 0; i < selectedRowKeys.length; i++) {
        if (newSelect.indexOf(selectedRowKeys[i]) === -1) {
          newSelect.push(selectedRowKeys[i]);
        }
      }
      selectedRowKeys = newSelect;
    }
    // selectedRows 如果是空数组的时候 代表他是取消操作
    if ((!initKeysLength && !selectedRowKeys.length) || initKeysLength <= selectedRowKeys.length) {
      let cancelId: string;
      if (!selectedKeys.value || (selectedKeys.value && selectedKeys.value.length === 0)) {
        cancelId = selectedRowKeys[0];
      } else {
        cancelId = findDiff(selectedRowKeys, selectedKeys.value);
      }
      selectedKeys.value = selectedRowKeys;
      const currentData = originData.value.find((item: any) => item.id === cancelId);
      // 勾选的是父级
      if (currentData.id === currentData.parentId) {
        // 手动添加子集的id
        dataContent
          .find((item: any) => item.parentId === currentData.id)
          ?.children.forEach((child: any) => {
            selectedKeys.value.push(child.id);
          });
      } else {
        // 如果勾选的是最后一个子集 默认将父级勾选
        const arr = dataContent.find((item: any) => item.id === currentData.parentId)?.children;
        const isShow = arr.every((item: any) => {
          return selectedRowKeys.includes(item.id);
        });
        // 代表勾选的是最后一个子元素默认选择父级元素
        if (isShow) {
          selectedKeys.value.push(currentData.parentId);
        }
      }
    } else {
      // 取消勾选父级 默认取消其下面的所有的子集
      if (selectedKeys.value.length) {
        // 获取取消勾选的id
        const cancelId = findDiff(selectedKeys.value, selectedRowKeys);
        const currentData = originData.value.find((item: any) => item.id === cancelId);
        if (currentData.id === currentData.parentId) {
          // 取消勾选的父级
          const arr = dataContent.find((item: any) => item.id === cancelId)?.children;
          const indexArr: number[] = [];
          arr.forEach((item: any) => {
            if (selectedKeys.value.includes(item.id)) {
              const index = selectedRowKeys.findIndex((value: any) => {
                return value === item.id;
              });
              if (index > -1) indexArr.push(index);
            }
          });
          indexArr.sort();
          for (let i = indexArr.length - 1; i >= 0; i--) {
            selectedRowKeys.splice(indexArr[i], 1);
          }
          selectedKeys.value = selectedRowKeys;
        } else {
          // 取消勾选的不是父级 判断是否勾选了父级 勾选了就取消勾选
          const cancelId = findDiff(selectedKeys.value, selectedRowKeys);
          selectedKeys.value = selectedRowKeys;

          const currentData = originData.value.find((item: any) => item.id === cancelId);
          if (selectedKeys.value.includes(currentData.parentId)) {
            const index = selectedRowKeys.findIndex((value: any) => {
              return value === currentData.parentId;
            });
            selectedKeys.value.splice(index, 1);
          }
        }
      }
    }
    initKeysLength = selectedKeys.value.length as number;
  };

  // 表格可选择的复选框 当选中项发生变化
  const onSelectChange = (selectedRowKeys: []) => {
    if (isFold) {
      checkAllEvent(selectedRowKeys);
    } else {
      selectedKeys.value = selectedRowKeys;
    }
  };

  return {
    getTableData,
    onExpand,
    onSelectChange,
    configColumns,
    isCheckModel,
    addChildren
  };
};

// 获取Finacing数据
export const useFinancing = (copyfinancData: Ref) => {
  const store = useStore();

  const configColumnsFin = (data: string[]) => {
    const columnsFin = [];
    data.forEach((item) => {
      columnsFin.push({
        title: item,
        dataIndex: item,
        key: item
      });
    });
    columnsFin.push({
      title: 'Row Set',
      slots: { customRender: 'rowSet' }
    });
    return columnsFin;
  };

  const dragTableSort = () => {
    nextTick(() => {
      const antTableTbodyEl = document.querySelectorAll('#sortTable .ant-table-tbody');
      antTableTbodyEl.forEach((el, index) => {
        new Sortable(el as HTMLElement, {
          handle: '.my-handle',
          chosenClass: 'sortable-chosen',
          dragClass: 'drag',
          animation: 500,
          onUpdate: (evt: any) => {
            const oldIndex = evt.oldIndex;
            const newIndex = evt.newIndex;
            const item = copyfinancData.value[index].financingDesignList[oldIndex];
            copyfinancData.value[index].financingDesignList.splice(oldIndex, 1);
            copyfinancData.value[index].financingDesignList.splice(newIndex, 0, item);
            if (oldIndex === newIndex) {
              return;
            }
          }
        });
      });
    });
  };

  const getTableFinanc = (params: any, query: any) => {
    return new Promise((resolve, reject) => {
      const brand = findBrand(store.state.pgmData.eligibility.vehicle);
      const configParams = {
        ...query,
        programId: store.state.pgmData.programId,
        brand
        // bu: store.state.pgmData.businessUnit.selected
      };
      getFinacDesData(configParams, params)
        .then((res) => {
          dragTableSort();
          copyfinancData.value = deepClone(handleSet(res));
          // if (res.length > 0) configColumnsFin(res[0].tableNameList)
          resolve(handleSet(res));
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  return {
    getTableFinanc,
    configColumnsFin,
    dragTableSort
  };
};

// 获取FL 数据
export const useFLwithBalloon = (flTextArr: Ref, agTextArr: Ref, flComments: Ref) => {
  const store = useStore();
  const deployChild = (arr: MaintainChildren[], type?: string): ColmunsChildrenProps[] => {
    const children: ColmunsChildrenProps[] = [];
    arr.forEach((item: MaintainChildren) => {
      if (type === 'checkout') {
        children.push({
          title: item.display,
          dataIndex: item.id,
          key: item.id,
          width: 120,
          slots: { customRender: item.id },
          align: 'center'
        });
      } else if (type === 'vehicle') {
        children.push({
          title: item.display,
          dataIndex: item.id + '.value',
          key: item.id,
          width: 140,
          align: 'center'
        });
      }
    });
    return children;
  };

  const configColumnsFl = (data: MaintainColProps[]): ColmunsProps[] => {
    const columns: ColmunsProps[] = [];
    data.forEach((item) => {
      columns.push({
        title: item.display,
        children:
          item.id === '10'
            ? deployChild(item.children as MaintainChildren[], 'vehicle')
            : deployChild(item.children as MaintainChildren[], 'checkout')
      });
    });
    return columns;
  };

  //根据key 查看对应columns中的title
  const findTitle = (key: string, columnsArr: ColmunsProps[]): { colTitle: string; itemTitle: string } => {
    // debugger
    let titleObj = {
      colTitle: '',
      itemTitle: ''
    };
    columnsArr.forEach((col: ColmunsProps) => {
      (col.children as ColmunsChildrenProps[]).forEach((item) => {
        if (item.dataIndex === key) {
          // return col
          titleObj = {
            colTitle: col.title,
            itemTitle: item.title
          };
        }
      });
    });
    return titleObj;
  };

  // 转换成千位符
  const toKbsymbol = (text: string) => {
    const re = /\d(?=(?:\d{3})+\b)/g;
    return text.replace(re, '$&,');
  };

  // 根据id 返回是不是AMG
  const findSelectBU = () => {
    const data = store.state.pgmData.businessUnit.option.find((item: any) => {
      return item.id === store.state.pgmData.businessUnit.selected;
    });
    return data.display;
  };

  // 给fl下面的文本域赋值
  const assignText = (textArr: any[]): string => {
    flComments.value = '';
    let textareaStr = '';
    textArr.forEach((item, index) => {
      let textShow = '';
      if (item.textArr.length > 1) {
        item.textArr.forEach((col: any) => {
          textShow += `       RMB  ${toKbsymbol(col.text)} for ${col.vehicleText} \n`;
        });
        if (findSelectBU() == 'AMG') {
          if (!item.colTitle.includes('DP')) {
            textareaStr += `Option${index + 1}:
 1) Prepayment amount = actual retail price of the vehicle – Balloon amount, and
 2) Prepayment ratio starts from ≥ 40% of actual retail price of the vehicle
 3) 0% security deposit based on actual retail price of the vehicle, and
 4) ${item.colTitle} - ${item.itemTitle}  starting from \n${textShow}`;
          } else {
            textareaStr += `Option${index + 1}:
 1) 0% security deposit based on actual retail price of the vehicle
 2) Prepayment ratio starts from 20% of actual retail price of the vehicle
 3) ${item.colTitle} - ${item.itemTitle}  starting from \n${textShow}`;
          }
        } else {
          textareaStr += `Option${index + 1}: ${item.colTitle} - ${item.itemTitle}  starting from \n${textShow}`;
        }
      } else {
        textShow = `RMB  ${toKbsymbol(item.textArr[0].text)}`;
        if (findSelectBU() == 'AMG') {
          textShow = `       RMB  ${toKbsymbol(item.textArr[0].text)} for ${item.textArr[0].vehicleText} \n`;
          // 36m  (可能需要改动态)
          if (!item.colTitle.includes('DP')) {
            textareaStr += `Option${index + 1}:
 1) Prepayment amount = actual retail price of the vehicle – Balloon amount, and
 2) Prepayment ratio should satisfy the condition: 36m contract: ≥ 40% of actual retail price of the vehicle
 3) 0% security deposit based on actual retail price of the vehicle, and
 4) ${item.colTitle} - ${item.itemTitle}  starting from \n ${textShow} `;
          } else {
            textareaStr += `Option${index + 1}:
 1) 0% security deposit based on actual retail price of the vehicle
 2) Prepayment ratio starts from 20% of actual retail price of the vehicle
 3) ${item.colTitle} - ${item.itemTitle}  starting from \n ${textShow} `;
          }
        } else {
          textareaStr += `Option${index + 1}: ${item.colTitle} - ${item.itemTitle}  starting from ${textShow} \n`;
        }
      }
    });
    textareaStr += '*Actual monthly payment subjects to MBLC system';
    return textareaStr;
  };

  const addComments = (data: any) => {
    data?.forEach((main: any, index: number) => {
      if (!flTextArr.value[index]) flTextArr.value[index] = [];
      if (!agTextArr.value[index]) agTextArr.value[index] = [];
      if (main.maintain.tableCell) {
        main.maintain.tableCell.forEach((item: any) => {
          for (const key in item) {
            if (Object.prototype.toString.call(item[key]) === '[object Object]') {
              if (item[key].bl) {
                const { colTitle, itemTitle } = findTitle(key, configColumnsFl(main.maintain.tableCol));
                const hasCol = flTextArr.value[index].find(
                  (item: any) => item.colTitle === colTitle && item.itemTitle === itemTitle
                );
                if (!hasCol) {
                  flTextArr.value[index].push({
                    colTitle,
                    itemTitle,
                    textArr: [
                      {
                        text: item[key].value,
                        vehicleText: item[100].value,
                        term: item[101]
                      }
                    ]
                  });
                } else {
                  hasCol.textArr.push({
                    text: item[key].value,
                    vehicleText: item[100].value,
                    term: item[101].value
                  });
                }
              }
            }
          }
        });
        main.comments = assignText(flTextArr.value[index]);
      }
    });
  };

  const getTableFl = (params: any, query: any) => {
    return new Promise((resolve, reject) => {
      const brand = findBrand(store.state.pgmData.eligibility.vehicle);
      const configParams = {
        ...query,
        programId: store.state.pgmData.programId,
        brand
        // bu: store.state.pgmData.businessUnit.selected
      };
      getFlDesData(configParams, params)
        .then((res: any) => {
          addComments(res.tables);
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  const getTableEqFl = (params: any, query: any) => {
    return new Promise((resolve, reject) => {
      const brand = findBrand(store.state.pgmData.eligibility.vehicle);
      const configParams = {
        ...query,
        programId: store.state.pgmData.programId,
        brand
        // bu: store.state.pgmData.businessUnit.selected
      };
      getEqFlDesData(configParams, params)
        .then((res: any) => {
          if (brand !== 'EQ') {
            addComments(res.tables);
          }
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  return {
    getTableFl,
    getTableEqFl,
    configColumnsFl,
    assignText,
    addComments
  };
};

// 获取EQ 数据
export const useAgility = () => {
  const store = useStore();
  // 获取数据
  const getTableAg = (params: any, query: any) => {
    return new Promise((resolve, reject) => {
      const brand = findBrand(store.state.pgmData.eligibility.vehicle);
      const configParams = {
        ...query,
        programId: store.state.pgmData.programId,
        brand,
        bu: store.state.pgmData.businessUnit.selected
      };
      getAgDesData(configParams, params)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  return {
    getTableAg
  };
};

export const columnsAgility = [
  {
    title: 'Eligible Vehicles',
    dataIndex: 'model',
    key: 'key',
    width: '10%'
  },
  {
    title: 'Minimum Down Payment Ratio',
    dataIndex: 'prePaymentPer',
    key: 'key',
    width: '14%'
  },

  {
    title: 'Down Payment',
    dataIndex: 'dpAmount',
    key: 'key',
    width: '10%'
  },
  {
    title: 'Monthly Payment',
    dataIndex: 'monthlyPaymentAfterDBD',
    key: 'key',
    width: '10%'
  },
  {
    title: 'RV%',
    dataIndex: 'rvPer',
    key: 'key',
    width: '6%'
  },
  {
    title: 'Customer Rate after DBD',
    dataIndex: 'customerRateAfterDBD',
    key: 'key',
    width: '14%'
  }
];

export interface BuyFLWithProps {
  groupTitle: string;
  tables: [];
  groupCommon: {
    groupTitle: string;
    isEdit: boolean;
    comments: string;
  }[];
}
