
import { defineComponent, ref, onMounted, PropType, watch } from 'vue'
import { APIDataProps, AutoTableCellProps, RowCellProps, ColumnsProps, AutoTableColProps } from '@/types'
import { handleChangePre } from '@/utils/index'

import { dataMeasure, MeasureProps, HLOptions } from '../PMT/measure'
import { useInsetMeasure } from '@/hooks/Program/useInsetMeasure'


import { useTableDelRowCol } from '@/hooks/Program/useTableDelRowCol'
import { useTableAddRow } from '@/hooks/Program/useTableAddRow'
import { useTableOperation } from '@/hooks/Program/useTableOperation'
import { useStore } from 'vuex'
import { useAllCheck } from '@/hooks/Program/useAllCheck'

export default defineComponent({
    components: {
        VNodes: (_, { attrs }) => {
            return attrs.vnodes;
        },
    },
    props: {
        precondition: {
            default: () => {
                return {
                    group: [],
                    groupId: '',
                    groupName: '',
                    tableId: '',
                    tableName: '',
                    tableCol: [],
                    tableCell: [],
                    tableCellSelect: [],
                    otherwise: ''
                }
            },
            type: Object as PropType<APIDataProps>,
        },
        offer: {
            type: String,
            default: ''
        },
        indexPre: {
            type: Number,
            default: 0
        },
        keyWord: {
            type: String,
            default: ''
        }
    },
    setup (props) {
        const store = useStore()
        const otherWise = ref<string>('')
        const measureSelectData = ref<MeasureProps[]>([])
        measureSelectData.value = dataMeasure

        const { autoInsetRow } = useTableAddRow()
        // 改变加入一行
        const handleFocusT = (row: AutoTableCellProps) => {
            autoInsetRow(props.precondition.tableCell, props.precondition.tableCol, row)
        }
        
        const handleOtherwise = () => {
            const { precondition } = props
            precondition.otherwise = otherWise.value
        }

        // 
        const handleChangeDummy = (rowCol: RowCellProps) => {
            if (rowCol.val === 'false') {
                rowCol.val = 'true'
            } else {
                rowCol.val = 'false'
            }
        }

        // 下拉里面的全选，取消全选
        const { checked, currentChecked } = useAllCheck()
        const handleAllCheck = (index: number, col: string, row: AutoTableCellProps) => {
            const { precondition } = props
            checked.value = !checked.value
            if (checked.value) {
                row[col].val = precondition.tableCellSelect
            } else {
                row[col].val = []
            }
        }
    

        // 删除表格的行列
        const { selectedRowKeys, onSelectChange, deleteRow, handleDelCol } = useTableDelRowCol()
        // 表格的操作，生成表头，格子等
        const { 
                getColums,
                fetching,
                handleFocus,
                columns,
                showDelArr,
                showMeasureArr,
                showMeasureSecArr
                } = useTableOperation()
        // Measure相关
        const { handleChangeAutoCal, handleSelectMeasure, handleInsetMeasure, handleAddMeasureCol, getFlag } = useInsetMeasure(getColums, measureSelectData.value)
        watch(() => props.precondition.tableCol, () => {
            getColums(props.precondition)
            if (props.precondition.tableCell.length < 1) {
                 autoInsetRow(props.precondition.tableCell, props.precondition.tableCol)
            }
            otherWise.value = !props.precondition.otherwise ? '' : props.precondition.otherwise;
        })

        onMounted(() => {
            getColums(props.precondition)
            if (props.precondition.tableName !== 'PDI List') {
                 autoInsetRow(props.precondition.tableCell, props.precondition.tableCol)
            }
           
            otherWise.value = !props.precondition.otherwise ? '' : props.precondition.otherwise

        })

        return {
            columns,
            measureSelectData,
            selectedRowKeys,
            onSelectChange,

            handleSelectMeasure,
            showDelArr,
            handleDelCol,

            deleteRow,
            handleFocusT,
            handleFocus,
            fetching,
            otherWise,
            handleOtherwise,
            handleChangeDummy,
            handleChangePre,
            handleInsetMeasure,
            showMeasureArr,
            showMeasureSecArr,
            handleAddMeasureCol,
            handleChangeAutoCal,
            HLOptions,
            autoInsetRow,
            getFlag,
            checked,
            handleAllCheck
        }
    }
})
