
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { formatPgmCategory, getCategory } from "@/utils";
import { CategoryProps, APIDataProps, OptionProps } from "@/types";
import { Modal } from "ant-design-vue";
import useEventbus from "@/hooks/claim/useEventbus";
import { guid } from "@/utils";
import { useTableAddRow } from "@/hooks/Program/useTableAddRow";
import { useTableAddRowChildren } from "@/hooks/Program/useTableAddRowChildren";
import { searchHeaderProgram } from "@/API/program";
export interface BUOptonProps {
  category: GroupCategoryProps[];
  display: string;
  id: string;
}
export interface GroupCategoryProps {
  groupName: string;
  offerType: CategoryProps[];
}
export default defineComponent({
  setup() {
    const store = useStore();
    const headerCodeSelect = ref<string[]>([]);
    const fetching = ref<boolean>(false);
    const showHeaderProgramCode = ref<boolean>(false);
    const oldBu = ref<string>("");

    const { customEmit } = useEventbus();
    const { autoInsetRow } = useTableAddRow();
    const handleFocusBu = (value: any) => {
      oldBu.value = store.state.pgmData.businessUnit.selected;
    };
    const handleChangeUUit = (value: string, option: any) => {
      Modal.confirm({
        title: "提示",
        content: `切换BU将清掉所有配置数据，你确定切换吗？`,
        onOk() {
          // offer清掉
          store.state.pgmData.offer = {};
          // categroy清掉
          store.state.pgmData.businessUnit.option.forEach(
            (item: BUOptonProps) => {
              if (item.id !== value) {
                item.category.forEach((ele: GroupCategoryProps) => {
                  ele.offerType.forEach((eles: CategoryProps) => {
                    eles.selected = false;
                  });
                });
              }
            }
          );
          // 清掉vehicle和dealer
          // autoInsetRow(store.state.pgmData.eligibility.dealer.tableCell, store.state.pgmData.eligibility.dealer.tableCol)
          const { addNewInclude, addNullExclude } = useTableAddRowChildren();
          console.log(store.state.pgmData.eligibility.dealer);

          store.state.pgmData.eligibility.dealer.tableCell = [];
          store.state.pgmData.eligibility.dealer.tableCell.push(
            addNullExclude(
              addNewInclude(store.state.pgmData.eligibility.dealer.tableCol),
              store.state.pgmData.eligibility.dealer
            )
          );
          store.state.pgmData.eligibility.vehicle.forEach(
            (item: APIDataProps) => (item.tableCell = [])
          );
          store.state.pgmData.eligibility.vehicle.forEach(
            (item: APIDataProps) => {
              if (item.tableCell) {
                item.tableCell.push(
                  addNullExclude(
                    addNewInclude(
                      store.state.pgmData.eligibility.vehicle[0].tableCol
                    ),
                    store.state.pgmData.eligibility.vehicle
                  )
                );
              }
            }
          );
          getCategory();
        },
        onCancel() {
          store.state.pgmData.businessUnit.selected = oldBu.value;
        },
        class: "test",
      });

      // 更新本地上牌的数据
      customEmit("updatelocalreg");
    };

    // 得到offer的固定结构
    const getOffer = (offerTypeName: string) => {
      const offerType = {
        formula: {
          precondition: null,
          incentive: null,
          vehicleLicense: null,
          formula: null,
        },
        incentive: [],
        offerTypeId: guid(),
        offerTypeName: offerTypeName,
        precondition: [],
        vehicleLicense: [],
        ranking: [],
      };
      return offerType;
    };
    // 得到是否显示HeaderProgramCode的函数
    const getShowHeaderProgramCode = (): boolean => {
      let flag = false;
      if (
        store.state.pgmCategory.find(
          (item: OptionProps) =>
            item.id === "bmbs_initiated_fleet_special" && item.selected
        ) &&
        !store.state.pgmCategory.find(
          (item: OptionProps) =>
            item.id === "dealer_initiated_fleet_special" && item.selected
        )
      ) {
        flag = true;
      }
      if (
        !store.state.pgmCategory.find(
          (item: OptionProps) =>
            item.id === "bmbs_initiated_fleet_special" && item.selected
        ) &&
        store.state.pgmCategory.find(
          (item: OptionProps) =>
            item.id === "dealer_initiated_fleet_special" && item.selected
        )
      ) {
        flag = true;
      }
      return flag;
    };

    // 切换选择category
    const handleChange = (item: CategoryProps) => {
      if (!item.selected) {
        Modal.confirm({
          title: "提示",
          content: `你确定取消${item.display}的选择吗？`,
          onOk() {
            formatPgmCategory();
            showHeaderProgramCode.value = !getShowHeaderProgramCode()
              ? false
              : true;
            // 新结构
            // store.state.pgmData.offer[item.id] = null
            delete store.state.pgmData.offer[item.id];
          },
          onCancel() {
            item.selected = true;
          },
          class: "test",
        });
      } else {
        formatPgmCategory();
        if (
          !(item.id in store.state.pgmData.offer) ||
          !store.state.pgmData.offer[item.id]
        )
          store.state.pgmData.offer[item.id] = getOffer(item.display);
        showHeaderProgramCode.value = !getShowHeaderProgramCode()
          ? false
          : true;
      }
    };
    const handleFocus = () => {
      fetching.value = true;
      searchHeaderProgram({ program: store.state.pgmData }).then((res) => {
        headerCodeSelect.value = res;
        fetching.value = false;
      });
    };
    return {
      handleChange,
      handleChangeUUit,
      headerCodeSelect,
      handleFocus,
      handleFocusBu,
      fetching,
      showHeaderProgramCode,
    };
  },
});
