
import { defineComponent, onMounted } from 'vue'
import Designer from '@/components/Designer.vue'
import * as DesignerGC from "@grapecity/spread-sheets-designer";
import GC from '@grapecity/spread-sheets'
import "@grapecity/spread-sheets-print";
import * as ExcelIO from '@grapecity/spread-excelio'
import useEventbus from "@/hooks/claim/useEventbus"
import { initSpread, ImageCellType } from './imgCellType'
GC.Spread.Sheets.LicenseKey = (ExcelIO as any).LicenseKey = 'oasis.mercedes-benz.com.cn|oasis-training.mercedes-benz.com.cn|oasis-uat.mercedes-benz.com.cn|oasis-dev.mercedes-benz.com.cn,858593977834555#B0j3vnNr3SYhRHVj5kbqtkWXRDMW3SNqFFON9GaMJ7YaV7QjVkZENjR7kXZ6RHeBpEN58WT6QUY42WeJ36ZEt4azlnZ9kXR624VtZzcrAlRTJ4LudWSMlDVul5KG3CeNNXOEZVVwInV6I6LUVTO486cwRldIdDepBHNYJ6cDJWbT36L0RXT6BFT7cTWHBHeQNncygXT59UTmh7bLdmNRtiY6RmTHRlciNnZ9ljQGtmSvUXS5J4KyUHMYRUNRRnU5lkYjN5ThJnMYZ5ayN4dMlFVtR6TmJXY5UUUrsyc9M4N9ZjNMplcxllMtRTTxxkeSZTbw3mMn5WW054LRdFelNFZvcHelNkTHFWUTV7VyUjR7hjMOJVaJdXO5plZyI6T5ZDcIVnc6NnZ55kbwFzV9cERiojITJCLiYUMGFTQDZUNiojIIJCLxIDN8ETMyETN0IicfJye&Qf35VfiUURJZlI0IyQiwiI4EjL6BCITpEIkFWZyB7UiojIOJyebpjIkJHUiwiIzATNwkDMgYDM4ATMyAjMiojI4J7QiwiIuNmLt36YuonblJWLzVGZlNmcl5mL6VGZtMXazF6bs86Yu46bj9ieuVmYtMXZkV6YyVWbuQXY55ycpNXYvxibj9SbvNmL09WZi5yclRWZjJXZt9yZulmbpFmc45ycpNXYvxibj9SbvNmL09WZi5yclRWZjJXZt9ycpNXYvJiOiMXbEJCLig1jlzahlDZmpnInmTYtoXpimrLjl1Yjl7KukfKplL9ilb8plTLimLiOiEmTDJCLiUTN5QzM8czN9MTO5gTN8IiOiQWSiwSflNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRR5QoFHTzgDdslEZiB5dDJGarFkR5QmNNFFcvBnYLRkWXBlfzCX';
export default defineComponent({
    components: {
        Designer
    },
    props: {
        tablespread: {
            default: () => {
                return {
                    jsonText: '',
                    excel: ''
                }
            },
            type: Object
        },
        indexPre: {
            default: 0,
            type: Number
        }
    },
    setup (props) {
        const { customOn } = useEventbus()
        
        let designer: DesignerGC.Spread.Sheets.Designer.Designer;
        let workbook: GC.Spread.Sheets.Workbook
        
        const uploadAudio = ( blob: Blob ) => {
            const { tablespread } = props
            const reader = new FileReader();
            reader.onload = (event: any) => {
                tablespread.excel = event.target.result
            };
            reader.readAsDataURL(blob);
        }
        const exportExcel = () => {
                const { tablespread } = props
                const excelIo = new ExcelIO.IO();
                // 导出到excel中，包括绑定的数据
                const json = workbook.toJSON({
                    includeBindingSource: true,
                    ignoreFormula: false,
                });
                tablespread.jsonText = JSON.stringify(json)
                excelIo.save(
                    json,
                    (data: Blob) => {
                        uploadAudio(data)
                    }
                );
            }
        const designerinitialized = (wb: DesignerGC.Spread.Sheets.Designer.Designer) => {
            const { tablespread } = props
            designer = wb;
            workbook = designer.getWorkbook() as GC.Spread.Sheets.Workbook;
            if (tablespread.jsonText) {
                workbook.fromJSON(JSON.parse(tablespread.jsonText))
            }
                // const sheet = workbook.getActiveSheet()
                // const c = new GC.Spread.Sheets.CellTypes.CheckBoxList();
                // c.items([{text:"a",value:1},{text:"b",value:2},{text:"c",value:3}]);
                // sheet.setCellType(3, 2, c, GC.Spread.Sheets.SheetArea.viewport);
        }
        customOn('updatequota', exportExcel)
        // onMounted(() => {
        //     initSpread()
        // })
        return {
            designerinitialized,
        }
    }
})
