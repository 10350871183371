
import { defineComponent, ref, onMounted, h, PropType, watch } from 'vue'
import { OptionProps } from '@/types'
import deepClone from '@/utils/deepClone'
import { useStore } from 'vuex'
import { ColumnsProps, APIDataProps, AutoTableCellProps, AutoTableColProps } from '@/types'
import { expandProps } from "@/views/Budget/types"
import { getGroup, guid, handleChangePre } from '@/utils/index'
import { message } from "ant-design-vue"

import { searchRepurchaseVehicle } from '@/API/program'
import { clearCellData } from '@/utils/pgm'
import { useTableOperation } from '@/hooks/Program/useTableOperation'
// I/E的下拉，写死的，后端没有给
const IESelect: OptionProps[] = [
    {
        display: 'Include',
        id: '11'
    },
    {
        display: 'Exclude',
        id: '12'
    }
]

export default defineComponent({
    components: {
        // 下拉框里的全选
        VNodes: (_, { attrs }) => {
            return attrs.vnodes
        },
    },
    props: {
        vehicle: {
            default: () => {
                return  {
                    group: [],
                    groupId: '',
                    groupName: '',
                    tableId: '',
                    tableName: '',
                    tableCol: [],
                    tableCell: [],
                    tableCellSelect: [],
                    otherwise: ''
                }
            },
            type: Object as PropType<APIDataProps>,
        },
        expandedIndex: {
            default: 0,
            type: Number
        }
    },
    setup (props, context) {
        const store = useStore()
        const spinning = ref(false)
        // 表头
        const cols = ref<AutoTableColProps[]>([])
        // 列的数据
        // const columns = ref<ColumnsProps[]>([])
        const fetching = ref<boolean>(false)
        // 去tableCell去找，只要有一个有值，就需要更新父级Customer.standardContent的值
        const hasVehicle = () => {
             const { vehicle } = props
             return vehicle.tableCell.some((item: AutoTableCellProps) => {
                 for (const key of vehicle.tableCol) {
                     if (key.id !== 'ACT_0') {
                         return item[key.id].val
                     }
                 }
             })
        }

        // 得到colums, 和头部
        const { getColums, columns } = useTableOperation()
        // const getColums = () => {
        //     // 操作列
        //     const paramsOP = {
        //         title: 'Operation',
        //         dataIndex: 'operation',
        //         slots: { customRender: 'operation' },
        //     }
        //     cols.value.forEach(item => {
        //          // 动态表头列
        //         const params = {
        //             title: item.display,
        //             dataIndex: item.id,
        //             slots: { customRender: item.id },
        //         }
        //         columns.value.push(params)
        //     })
        //     columns.value.push(paramsOP)
            
        // }

        // 添加新的Exclude空数据
        const addNullExclude = (param: AutoTableCellProps) => {
            const { vehicle } = props
            if (param.children) {
                // 需要和父级一样的
                const params = deepClone(param)
                params.key = guid()
                params.id = params.key
                params.parent = param.id
                params[vehicle.tableCol[0].id] = { val: 'Exclude' }
                param.children.push(params)
            }
            return param
        }

        // 添加新的Include空数据
        const addNewInclude = () => {
            const param: AutoTableCellProps = {
                key: guid(),
                children: [],
                parent: '0'
            }
            cols.value.forEach((item: AutoTableColProps) => {
                param.id = param.key
                param[item.id] = { isEdit: true, isAuto: false }
                // 这里会根据用户给默认值
                param[item.id].val = []
                
            })
            param[cols.value[0].id] = { val: 'Include'}
            return param
        }
        


        // 自定义展开icon图标-----------------------展开折叠部分
        const clickExpand = (props: expandProps, e: Element) => {
            props.onExpand(props.record, e)
        }
        // 得到exclude行
        const getExcludeRow = (includeRow: AutoTableCellProps, excludeRow: AutoTableCellProps) => {
            for (const i in excludeRow) {
                if (excludeRow[i].constructor === Object && i !== 'ACT_0') {
                    excludeRow[i].val = includeRow[i].val
                }
            }
            return excludeRow
        }

        // 点击添加Exclude, 增加新的一行，Exclude
        const clickAddExclude = (propss: any) => {
            const { vehicle } = props
            const param = deepClone(propss.record)
            param.key = guid()
            if (Object.keys(vehicle).length > 0 && vehicle.tableCell) {
                for (const val of vehicle.tableCell) {
                    if (val.id === propss.record.parent && val.children) {
                        // parent
                        param.parent = propss.record.parent
                        val.children.push(getExcludeRow(val, param))
                        break
                    }
                }
            }
            
        }

        // 自定义展开icon
        const expandIcon = (props: expandProps) => {
            if (props.record.children && props.record.children.length > 0 ) {
                if (props.expanded) {
                    //有数据-展开时候图标
                    return h("i", {
                        class: "font_family icon-xiangxia cursor-p m-r-20",
                        onClick: (e: Element) => {
                            clickExpand(props, e)
                        },
                    });
                } else {
                    //有数据-未展开时候图标
                    return h("i", {
                        class: "font_family icon-xiangyou cursor-p m-r-20",
                        onClick: (e: Element) => {
                            clickExpand(props, e)
                        },
                    });
                }
            } else {
                //无数据-图标
                return h("i", {
                    class: "font_family icon-tianjia11 cursor-p m-r-20",
                    style: { width: '16px', height: '1px', display: 'inline-block'},
                    onClick: () => {
                        clickAddExclude(props)
                    },
                });
            }
        }

        // 删除一行
        const handleDelete = (item: APIDataProps, record: AutoTableCellProps) => {
            if (item.tableCell && item.tableCell.length > 0) {
                // 当删除的是父级时
                if (record.parent ==='0') {
                    if (item.tableCell.length > 1) {
                        item.tableCell = item.tableCell.filter((val: AutoTableCellProps) => record.id !== val.id)
                    } else {
                        message.warning('This is the last one!')
                    }
                    
                } else {
                        // 当删除的是子级时
                        let targetObj: AutoTableCellProps = record
                        for (const val of item.tableCell) {
                            if (record.parent === val.id) {
                                targetObj = val
                                break
                            }
                        }
                        if (targetObj && targetObj.children && targetObj.children.length > 1) {
                            item.tableCell = item.tableCell.map((val: AutoTableCellProps) => {
                                if (val.children && val.children.length > 1) {
                                    val.children = val.children.filter((vals: AutoTableCellProps) => record.key !== vals.key)
                                }
                                return val
                            })
                        } else {
                            message.warning('This is the last one!')
                        }
                        
                }
            }
        }

      
        // 变成不可编辑
        // 2.take父级的值到子级
        const takeChildrenCol = (index: number, col: string, row: AutoTableCellProps) => {
            // 如果当前行是父级，1.下面的.children里面的当前列都需要改成不可编辑,2.并且子级全部和父级一样
            if (row.parent === '0' && row.children) {
                row.children.forEach((item: AutoTableCellProps) => {
                    for (const i in item) {
                        // 如果是对象的才能更新
                        if (item[i].constructor === Object && i !== 'ACT_0') {
                            item[i].val = row[i].val
                            // exclude有值不能编辑，否值能编辑
                            if (row[i].val.length > 0) {
                                item[i].isEdit = false
                            } else {
                                item[i].isEdit = true
                            }
                        }
                    }
                })
            }
        }

        // 自动加一行
        const autoAddInclude = (row: AutoTableCellProps, group: APIDataProps) => {
            const getIndex = group.tableCell.findIndex((item: AutoTableCellProps) => item.id === row.id)
            if (group.tableCell.length - 1 === getIndex) {
                group.tableCell.push(addNullExclude(addNewInclude()))
            }
        }
        const getVehicleGroup = () => {
            return store.state.pgmData.eligibility.vehicle.map((item: APIDataProps) => item.groupId)
        }
        // select获得焦点时,先清空之前下拉框的值，再赋相关的值。col:当前列，cellSelect：当前组
        // 需要判断brand先选择
        const handleFocus = (index: number, colCur: string, row: AutoTableCellProps, group: APIDataProps) => {
            autoAddInclude(row, group)
            // 先清空，下拉框的值，再赋值
            group.tableCellSelect = []
            // if ((row[group.tableCol[1].id].val as string[]).length < 1 && colCur !== group.tableCol[1].id) {
            //      message.error('Please choose brand first!')
            //      return
            // }
            // 1。加载loading
            fetching.value = true;
            // "target": "<Column-ID>",
            // "bu": "<BU>",
            // "id": "Row ID",
            // "row": "行数据"

            // const params = {
            //     // bu: store.state.pgmData.businessUnit.selected,
            //     id: row.id,
            //     row: row,
            //     target: colCur
            // }

            // const params = {
            //     eligibility: store.state.pgmData.eligibility,
            //     groupId: getVehicleGroup(),
            //     row: row,
            //     target: colCur,
            //     customer: store.state.pgmData.customer
            // }
            // searchOptions(params).then(res => {
            //     fetching.value = false;
            //     group.tableCellSelect = res
            // })
            const params = {
                bu: store.state.pgmData.businessUnit.selected,
                id: row.id,
                row: row,
                target: colCur
            }
            searchRepurchaseVehicle(params).then(res => {
                fetching.value = false;
                group.tableCellSelect = res
            })
        }
        // 选择下拉后的值的改变:1.需要清空后面的值，2.需要take上面的值,3.如果是最后一行，里面有一个值改变了都需加一行
        const handleChangeSelect = (index: number, col: string,  row: AutoTableCellProps, group: APIDataProps) => {
            if (row[col].val.length > 0) row[col].isAuto = false
            if (row.parent === '0') clearCellData(index, group.tableCol, row)
            takeChildrenCol(index, col, row)
            //3.
            autoAddInclude(row, group)
            context.emit('update-standard-content', hasVehicle())
            // addNewInclude()
            // 4.验重
            
        }

        // 下拉里面的全选，取消全选
        const checked = ref(false)
        const handleAllCheck = (index: number, col: string, row: AutoTableCellProps, group: APIDataProps) => {
            checked.value = !checked.value
            if (checked.value) {
                row[col].val = group.tableCellSelect
            } else {
                row[col].val = []
            }
            autoAddInclude(row, group)
        }


        // 失去焦点后，取消全选恢复
        const handleBlur = (): void => {
            checked.value = false
        }
        
        // 展开清空
        const dropdownVisibleChange = () => {
            const { vehicle } = props
            vehicle.tableCellSelect = []
            
        }
        
        // 得到要渲染的数据树形结构
        const formatTree = (): void => {
            const { vehicle } = props
            if (vehicle.tableCell && vehicle.tableCell.length < 2) {
                    vehicle.tableCell.push(addNullExclude(addNewInclude()))
            }
        }

        // 初始化需要：1.得到表头，2.页面渲染用的数据 3.得到colums, 和头部，4.// 得到要渲染的数据树形结构 5.格式化数据,加入isEdit,和isAuto
        const init = () => {
            const { vehicle } = props
            // 1.得到表头
            cols.value = vehicle.tableCol
            // 2.页面渲染用的数据
            // 3.得到colums, 和头部
            getColums(props.vehicle, true)
            
            // 4.得到要渲染的数据树形结构
            formatTree()
            // 5.格式化数据,加入isEdit,和isAuto
            // formatData(vehicle)
            store.commit('updatePgmGroup', getGroup())
            columns.value.unshift({ title: 'Number', dataIndex: '', key: '', width: '100px',  slots: { customRender: 'numberrow' } })
        }
        watch(() => props.vehicle, () => {
            formatTree()
        })
        onMounted(() => {
            init()
        })

        return {
            columns,
            cols,
            handleAllCheck,
            checked,
            IESelect,
            handleDelete,
            expandIcon,
            handleFocus,
            handleChangeSelect,
            spinning,
            dropdownVisibleChange,
            handleBlur,
            fetching,
            handleChangePre,
            takeChildrenCol
        }
    }
})
