
import deepClone from '@/utils/deepClone'
import { defineComponent, ref, reactive, onMounted, onBeforeUnmount } from 'vue'
import { OptionProps } from '@/types'
import { MaintainColProps } from '../types'
import { useStore } from 'vuex'
import { getOfferGroup } from "@/API/program"
export default defineComponent({
    props: {
      selectvisible:  Boolean,
      scope: {
          type: String,
          default: ''
      }
    },
    setup (props, context) {
        const store = useStore()
        const fieldData = ref<OptionProps[]>([])
        const fakerFieldData = ref<OptionProps[]>([])
        const groupData = ref<MaintainColProps>()
        const selectData = ref()
        
        const handleCancel = () => {
          context.emit('update:selectvisible', false)
        }
        const handleSubmit = () => {
            // 参数：插入哪个表格的数据
            store.state.pgmInsetCol.columnId = selectData.value // columnId是选择的值
            context.emit('update:selectvisible', false)
            context.emit('submitadd')
        }
        const handleGroup = (item: MaintainColProps) => {
            if (item.children && item.children.length > 0) fieldData.value = item.children
            fakerFieldData.value = deepClone(fieldData.value)
        }
        const dbhandleSelect = (e: Event, item: MaintainColProps) => {
            selectData.value = item.id
            handleSubmit()
        }

        // 搜索的value
        const valueSearch = ref('')
        const onSearch = () => {
            fieldData.value = fakerFieldData.value.filter(item => item.display.toUpperCase().indexOf(valueSearch.value.toUpperCase()) > -1)
        }

        
        // 单选的样式
        const radioStyle = reactive({
            display: 'block',
            height: '25px',
            lineHeight: '25px',
        });
        const handleSelect = (e: Event, list: MaintainColProps) => {
            const target = e && (e.target as HTMLElement)
            // 这个下面所有的LI
            const query = document.querySelectorAll<HTMLElement>('.field-data li')
            for (let i = 0; i < query.length; i++) {
                query[i].style.color = ''
            }
            // 当前的li
            if (target) {
                target.style.color = '#00ADEF';
            }
            selectData.value = list.id
        }
        const init = () => {
            // scope
            // vehicle 
            // dealer
            // kpi
            // vehicle_license
            // customized
            // quota
            let params = {}
            if (sessionStorage.getItem('scope') !== 'undefined') {
                params = {
                    scope: sessionStorage.getItem('scope')
                }
            }
            
            getOfferGroup({params: params}).then(res => {
                groupData.value = res.offerGroup
            })
        }
        onMounted(() => {
            init()
        })
        return {
            radioStyle,
            handleCancel,
            handleSubmit,
            fieldData,
            fakerFieldData,
            handleGroup,
            groupData,
            dbhandleSelect,
            handleSelect,
            valueSearch,
            onSearch,
        }
    }
})
