
    import { defineComponent, ref, onBeforeUnmount }  from 'vue'
    import { APIDataProps, APIDataEditProps } from '@/types'
    import QuotaPeriod from './QuotaPeriod.vue'
    // import GC from '@grapecity/spread-sheets'
    import SelectTable from '@/views/Program/components/SelectTable.vue'
    import { useStore } from 'vuex'
    import { guid } from '@/utils'
    import { pgmaddColumn, sjsExcel, settingSjsExcel } from "@/API/program"
    import { useInsetCol } from '@/hooks/Program/useInsetCol'
    import { message } from "ant-design-vue"
    import { initSpread, ImageCellType } from './imgCellType'
    // import GC from '@grapecity/spread-sheets'
    // import "@grapecity/spread-sheets/styles/gc.spread.sheets.excel2013white.css";
	// import GC from "@grapecity/spread-sheets";
    // import '@grapecity/spread-sheets-vue';
    // import * as ExcelIO from "@grapecity/spread-excelio";
    // import "@grapecity/spread-sheets-resources-zh";
    // import "@grapecity/spread-sheets-print";
    // import SpreadSheets from '@/components/SpreadSheets.vue'
    // import Designer from '@/components/Designer.vue'
    // import * as DesignerGC from "@grapecity/spread-sheets-designer";
    // import GC from '@grapecity/spread-sheets'
    // import "@grapecity/spread-sheets-print";
    // import * as ExcelIO from '@grapecity/spread-excelio'
    // import { saveAs } from "file-saver"
    
    // import ExcelIO from '@grapecity/spread-excelio'
    import SpreadjsDesign from './SpreadjsDesign.vue'
    import useEventBus from '@/utils/mitt'
    import { useEditGroupTitle } from '@/hooks/Program/useEditGroupTitle'
    const data = [
        [
            {
                contentsType: 'IMG', value: 'MB', icon: true
            },
            {
                contentsType: 'IMG', value: 'AMG', icon: false
            }
        ],
        [
            {
                contentsType: 'IMG', value: 'MB', icon: null
            },
            {
                contentsType: 'IMG', value: 'AMG', icon: false
            }
        ]
    ]
    export default defineComponent({
        components: {
            QuotaPeriod,
            SelectTable,
            SpreadjsDesign
        },
        setup () {
            const store = useStore()
            const quotaData = ref()
            quotaData.value = store.state.pgmData.quota
            
            // 新增一组:1.首先需要拿到vehicle，有多少组。
            const handleAddGroup = (): void => {
                const param: APIDataProps = {
                    // 把已有的vehicle拿下来
                    group: [],
                    groupId: guid(),
                    groupName: `QT${quotaData.value.length + 1}`,
                    tableId: guid(),
                    tableName: '',
                    tableCol: [],
                    tableCell: [],
                    tableCellSelect: []
                }
                quotaData.value.push(param)
            }
           
            const { selectVisible, handleInsetCol } = useInsetCol()
            const submitAdd = () => {
                const index = Number(sessionStorage.getItem('indexTable'))
                if (store.state.pgmInsetCol.columnId === 'Quota.free') {
                    store.state.pgmData.quota[index].tableSpreadJS = {}
                    store.state.pgmData.quota[index].tableSpreadJS = {
                        // 前端渲染用
                        jsonText: '',
                        // 后端用的
                        excel: ''
                    }
                } else {
                    pgmaddColumn(store.state.pgmInsetCol).then(res => {
                        store.state.pgmData.quota[index] = res
                    })
                }
            }
            
            // 文字
            // const spreadNS = GC.Spread.Sheets
            // let worksheet: GC.Spread.Sheets.Worksheet
            // let workbook: GC.Spread.Sheets.Workbook
            // const init = () => {
            //     const spread = new GC.Spread.Sheets.Workbook(document.getElementById('ssHost') as HTMLElement);
            //     const sheet = spread.getActiveSheet();
            //     worksheet = sheet
            //     // workbook = spread;
            // }
            // const handleInset = () => {
            //     const sheet = workbook.getActiveSheet();
            //     sheet.setCellType(sheet.getActiveRowIndex(),sheet.getActiveColumnIndex(),new ImageCellType(true));
            // }
            // const getActualCellRange = (cellRange: any, rowCount: any, columnCount: any) => {
            //     console.log(cellRange, rowCount, columnCount, 'cellRange, rowCount, columnCount')
            //     if (cellRange.row == -1 && cellRange.col == -1) {
            //         return new spreadNS.Range(0, 0, rowCount, columnCount);
            //     } else if (cellRange.row == -1) {
            //         return new spreadNS.Range(0, cellRange.col, rowCount, cellRange.colCount);
            //     } else if (cellRange.col == -1) {
            //         return new spreadNS.Range(cellRange.row, 0, cellRange.rowCount, columnCount);
            //     }
            //     return cellRange;
            // }
            // const btnMerge = (e: MouseEvent) => {
            //     const sheet = workbook.getActiveSheet();
            //     let sel: any = sheet.getSelections();
            //     if (sel.length > 0) {
            //         sel = getActualCellRange(sel[sel.length - 1], sheet.getRowCount(), sheet.getColumnCount());
            //         sheet.addSpan(sel.row, sel.col, sel.rowCount, sel.colCount);
            //     }
            // }
            const { valateGroupTitle } = useEditGroupTitle()
            const uploadAudio = ( blob: any ) => {
                const reader = new FileReader();
                reader.onload = function(event: any){
                    const fd = {};
                    fd["fname"] = "test.xlsx";
                    fd["data"] = event.target.result;
                    console.log(fd, 'fd,,,,,')
                    const param = {
                        file: fd
                    }
                    sjsExcel(param).then(res => {
                        console.log(res, '...')
                    })
                };
                reader.readAsDataURL(blob);
            }
            // const isPrinting = ref<boolean>(false)
            // const saveImg = (e: any) => {
            //     if (isPrinting.value) {
            //         return;
            //     }
            //     const sheet = workbook.getSheet(0);
            //     console.log(sheet, 'sheet')
            //     const printInfo = sheet.printInfo() || new GC.Spread.Sheets.Print.PrintInfo();
            //     printInfo.qualityFactor(5);
            //     sheet.printInfo(printInfo);
            //     workbook.bind(GC.Spread.Sheets.Events.BeforePrint, function (s: any, e: any) {
            //         // console.log(s, e)
            //         const sheet = workbook.getSheet(1);
            //         const iframe = e.iframe;
                   
            //         const images = iframe.contentWindow.document.getElementsByTagName("img");
            //         for (let i = 0; i < images.length; i++) {
            //             const img = images[i];
            //             console.log(img)
            //             const canvas = document.createElement("canvas");
            //             canvas.height = img.naturalHeight;
            //             canvas.width = img.naturalWidth;
            //             const ctx = canvas.getContext('2d');
            //             if (ctx) {
            //                 ctx.fillStyle = "#FFF";
            //                 ctx.fillRect(0, 0, canvas.width, canvas.height);
            //                 ctx.drawImage(img, 0, 0) //, img.width, img.height);
            //                 canvas.toBlob(function (blob: any) {
            //                     // saveAs(blob, "print.jpeg");
            //                     uploadAudio(blob)
            //                     console.log(blob, 'blob')
            //                 }, "image/jpeg", 1);
            //             }
                        
            //         }
            //         e.cancel = true;
            //         setTimeout(function () {
            //             isPrinting.value = false;
            //             workbook.unbind(GC.Spread.Sheets.Events.BeforePrint)
            //         }, 10)
            //     });
            //     isPrinting.value = true;
            //     workbook.print();
            // }
            // const workbookInitialized = (wb: any) => {
            //     workbook = wb
            //     const rowCount = 20, columnCount = 20;
            //     const sheet = workbook.getSheet(0);
            //     sheet.suspendPaint();
            //     sheet.setRowCount(rowCount);
            //     sheet.setColumnCount(columnCount);
            //     sheet.resumePaint();
            // }
            // const print = (e: any) => {
            //     const sheet = workbook.getActiveSheet();
            //     const printInfo = sheet.printInfo();
            //     printInfo.showBorder(false);
            //     printInfo.showGridLine(false);
            //     sheet.printInfo(printInfo);
            //     workbook.print();
                
            // }
            // const exportExcel = (e: any) => {
            //     const excelIo = new ExcelIO.IO();
            //     const fileName = "test";
            //     // if (fileName.substr(-5, 5) !== ".xlsx") {
            //     //     fileName += ".xlsx";
            //     // }
            //     // 导出到excel中，包括绑定的数据
            //     const json = workbook.toJSON({
            //         includeBindingSource: true,
            //         ignoreFormula: false,
            //     });
            //     excelIo.save(
            //         json,
            //         function (data: any) {
            //             console.log(data, '0000')
            //             uploadAudio(data)
            //             // saveAs(data, fileName);
            //             // const param = {
            //             //     file: data
            //             // }
            //             // sjsExcel(param).then(res => {
            //             //     console.log(res, '...')
            //             // })
            //             // const formData = new FormData()
            //             // formData.append('sjs_blob', data)
            //             // settingSjsExcel(formData).then(res => {
            //             //     console.log(res, 'res....')
            //             // })
            //         }
            //     );
            // }
            // onMounted(() => {
            //     const spread = new GC.Spread.Sheets.Workbook(document.getElementById('ssHost') as HTMLElement);
            //     workbook = spread
            //     initSpread(spread, data);
            // })
            // onBeforeUnmount(() => {
            //     workbook.destroy();
            // })
        //     let designer: GC.Spread.Sheets.Designer;
        //     let templateJSON: {}
        //     const designerInitialized=(wb: any)=>{
        //         designer = wb;
        //         const spread = designer.getWorkbook();
        //     }
        //     const load = (e: any)=>{
        //     const spread = designer.getWorkbook();
        //     // const formData = new FormData();
        //     // formData.append("fileName", "path");
        //     // axios.post('spread/loadTemplate', formData, {
        //     //     responseType: "json",
        //     // }).then((response) => {
        //     //     if(response) {
        //     //         alert("加载成功");
        //     //         templateJSON = response.data;
        //     //         spread.fromJSON(templateJSON);
        //     //     }
        //     // }).catch((response) => {
        //     //     alert("错误");
        //     // })
        // }

        // const update = (e: any)=>{
        //     const spread = designer.getWorkbook();
        //     const spreadJSON = JSON.stringify(spread.toJSON());
        //     // let formData = new FormData();
        //     // formData.append("jsonString", spreadJSON);
        //     // formData.append("fileName", "fileName");
        //     // axios.post('spread/updateTemplate', formData).then((response) => {
        //     //     if(response) {
        //     //         alert("更新成功");
        //     //     }
        //     // }).catch((response) => {
        //     //     alert("错误");
        //     // })
        // }
            // const DefaultConfig = GC.Spread.Sheets.Designer.DefaultConfig;
            // const customerRibbon = {
            //     id: "operate",
            //     text: "操作",
            //     buttonGroups: [
            //         {
            //         label: "文件操作",
            //         thumbnailClass: "ribbon-thumbnail-spreadsettings",
            //         commandGroup: {
            //             children: [
            //             {
            //                 direction: "vertical",
            //                 commands: ["loadTemplateCommand", "updateTemplateCommand"],
            //             }
            //             ],
            //         },
            //         },
            //     ],
            //     };

            //     const ribbonFileCommands = {
            //         "loadTemplateCommand": {
            //             iconClass: "ribbon-button-download",
            //             text: "加载",
            //             //bigButton: true,
            //             commandName: "loadTemplate",
            //             execute: load
            //         },
            //         "updateTemplateCommand": {
            //             iconClass: "ribbon-button-upload",
            //             text: "更新",
            //             //bigButton: true,
            //             commandName: "updateTemplate",
            //             execute: update
            //         }
            //     }

                // DefaultConfig.ribbon.push(customerRibbon);
                // DefaultConfig.commandMap = {};
                // Object.assign(DefaultConfig.commandMap, ribbonFileCommands);
           
            return {
                submitAdd,
                handleAddGroup,
                quotaData,
                handleInsetCol,
                selectVisible,
                valateGroupTitle,
                // handleInset,
                // btnMerge,
                // saveImg,
                // data,
                // workbookInitialized,
                // print,
                // exportExcel,
                // designerinitialized,
                // load,
                // update
            }
        }
    
    })
